import { useNavigate } from 'react-router-dom'

import useStore from 'store'

type UsePermission = {
  moduleName: PermissionModule
  action: PermissionAction
}

export const usePermission = ({
  moduleName,
  action
}: UsePermission): PermissionAction[] => {
  const navigate = useNavigate()

  const { selectors } = useStore()
  const permissions =
    selectors.permissions.getPermissionsByModuleName(moduleName)

  if (!Object.keys(permissions).length) return []
  if (!permissions.includes(action)) history.goBack()

  return permissions
}
