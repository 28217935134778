import React, { useState, useEffect, useRef } from 'react'

import { useTranslation } from 'react-i18next'

import { ReactComponent as GridIcon } from 'assets/images/icons/grid-icon.svg'
import { ReactComponent as ListIcon } from 'assets/images/icons/list-icon.svg'
import { Select } from 'components/Select/Select'
import ReactGA from 'utils/analytics'

import './CandidateDBHeader.scss'

export type CandidateDBHeaderRef = {
  orderByRef: React.RefObject<HTMLDivElement>
}

type Props = {
  onChangeViewType: (viewType: 'list' | 'grid') => void
  onOrderBy: (order: string) => void
  getRefs?: (value: CandidateDBHeaderRef) => void
  handleOpenFilter?: (value: boolean) => void
}

export const CandidateDBHeader: React.FC<Props> = ({
  onOrderBy,
  getRefs,
  onChangeViewType,
  handleOpenFilter
}) => {
  const [orderBy, setOrderBy] = useState('')
  const { t } = useTranslation('candidateHeader')
  const { t: tFilter } = useTranslation('candidateFilter')
  const orderByRef = useRef(null)
  const [viewType, setViewType] = useState<'list' | 'grid'>('grid')
  const viewRef = useRef(null)

  const orderByOptions = ['created_at', 'name']
  const changeViewType = (viewType: 'list' | 'grid') => {
    setViewType(viewType)
    onChangeViewType(viewType)
  }
  useEffect(() => {
    if (getRefs !== undefined) getRefs({ orderByRef })
  }, [])

  const handleOrderBy = e => {
    const { value } = e.target
    setOrderBy(value)
    onOrderBy(value)

    ReactGA.event(`order_candidate_by_${value}`, {
      event_category: 'candidate_base',
      event_label: `order_candidate_by_${value}`
    })
  }

  return (
    <>
      <div className='section-header-info'>
        <h2 className='section-title'>{t('databaseTitle')}</h2>
        <div className='view-actions' ref={orderByRef}>
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              gap: '20px',
              justifyContent: 'right'
            }}
          >
            <div style={{ width: '250px' }}>
              <Select
                label={t('orderBy')}
                value={orderBy}
                onChange={handleOrderBy}
              >
                <option value=''></option>

                {orderByOptions.map((el, index) => {
                  const optionValue = el === 'created_at' ? '-' + el : el
                  return (
                    <option value={optionValue} key={index}>
                      {t(`orderByOptions.${el}`)}
                    </option>
                  )
                })}
              </Select>
            </div>
            <button
              className='btn-filter'
              onClick={() => handleOpenFilter && handleOpenFilter(true)}
            >
              {tFilter('buttons.filterCandidate')}
            </button>
          </div>
        </div>
      </div>
      <div className='view-type mt-3' ref={viewRef}>
        <GridIcon
          className={viewType === 'grid' ? 'active' : ''}
          onClick={() => {
            changeViewType('grid')
          }}
        />
        <ListIcon
          className={viewType === 'list' ? 'active' : ''}
          onClick={() => {
            changeViewType('list')
          }}
        />
      </div>
    </>
  )
}
