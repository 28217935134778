/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'

import { Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

import { DatePickerInput } from 'components/DatePickerInput/DatePickerInput'
import { FormItem } from 'components/FormItem/FormItem'
import { FormRow } from 'components/FormRow/FormRow'
import { Input } from 'components/Input/Input'
import { InputCity } from 'components/InputCity/InputCity'
import { InputTag } from 'components/InputTag/InputTag'
import { RichTextEditor } from 'components/RichTextEditor/RichTextEditor'
import { SearchBox, ValeuOption } from 'components/SearchBox/SearchBox'
import { Select } from 'components/Select/Select'
import { Switch } from 'components/Switch/Switch'
import { job_group_search } from 'services/api/jobs'
import { sanitizeHtml } from 'utils/sanitizers.util'

import styles from './JobsFormContainer.module.scss'

const initialFormValues = {
  name: '',
  city: '',
  deadline: '',
  budget: 0,
  active: true,
  description: '',
  job_group_uuid: '',
  job_group: '',
  vacancy_allocation: '',
  tags: [],
  quantity: 0
}

export type FormDefaultValue = {
  name: string
  city?: string
  deadline: string
  active: boolean
  budget: number | string
  description: string
  job_group_uuid: string
  job_group: string
  vacancy_allocation: string
  tags: string[]
  quantity: number
}

type JobFormContainerProps = {
  onChange: (value: FormDefaultValue) => void
  onValidationChange: (isValid: boolean) => void
  value?: FormDefaultValue
  triedToSend?: boolean
}

export const JobFormContainer: React.FC<JobFormContainerProps> = ({
  onChange,
  value,
  triedToSend,
  onValidationChange
}) => {
  const { t } = useTranslation('')
  const [isFormValid, setIsFormValid] = useState(false)

  useEffect(() => {
    onValidationChange(isFormValid)
  }, [isFormValid, onValidationChange])

  const vacancyAllocationOption = [
    { value: '', label: t('select:vacancyAllocation.0') },
    { value: 'hybrid', label: t('select:vacancyAllocation.1') },
    { value: 'presential', label: t('select:vacancyAllocation.2') },
    { value: 'remote', label: t('select:vacancyAllocation.3') },
    { value: 'unspecified', label: t('select:vacancyAllocation.4') }
  ]

  const createJobSchema = Yup.object().shape({
    name: Yup.string()
      .trim()
      .matches(/\S/, t('errors:onlySpaces'))
      .required(t('errors:requiredField')),
    deadline: Yup.string().trim().matches(/\S/, t('errors:onlySpaces')),
    budget: Yup.number()
      .min(650, t('errors:minBudget'))
      .required(t('errors:requiredField')),
    vacancy_allocation: Yup.string()
      .trim()
      .matches(/\S/, t('errors:onlySpaces'))
      .required(t('errors:requiredField')),
    city: Yup.string()
      .trim()
      .min(1, t('errors:requiredField'))
      .matches(/\S/, t('errors:onlySpaces'))
      .when('vacancy_allocation', (vacancy_allocation, schema) =>
        ['remote', 'unspecified'].includes(vacancy_allocation[0])
          ? schema.notRequired()
          : schema.required(t('errors:requiredField'))
      ),

    description: Yup.string()
      .trim()
      .matches(/\S/, t('errors:onlySpaces'))
      .required(t('errors:requiredField')),
    job_group_uuid: Yup.string()
      .trim()
      .matches(/\S/, t('errors:onlySpaces'))
      .required(t('errors:requiredField')),
    job_group: Yup.string()
      .trim()
      .matches(/\S/, t('errors:onlySpaces'))
      .required(t('errors:requiredField')),
    quantity: Yup.number()
      .min(1, t('errors:requiredField'))
      .required(t('errors:requiredField'))
  })

  return (
    <Formik
      initialValues={value || initialFormValues}
      validationSchema={createJobSchema}
      onSubmit={() => {
        //
      }}
    >
      {({
        values,
        errors,
        setFieldValue,
        setFieldTouched,
        setValues,
        handleBlur,
        touched,
        isValid
      }) => {
        const [valueSetted, setValueSetted] = useState(false)
        const [errorInputTag, setErrorInputTag] = useState('')

        useEffect(() => {
          if (isValid !== isFormValid) {
            setIsFormValid(isValid)
          }
        }, [isValid, isFormValid])

        useEffect(() => {
          if (value && value.name && !valueSetted) {
            setValues({
              budget: value.budget as number,
              city: value.city === undefined ? '' : value.city,
              deadline: value.deadline,
              active: value.active,
              description: value.description,
              job_group_uuid: value.job_group_uuid,
              job_group: value.job_group,
              vacancy_allocation:
                value.vacancy_allocation === null
                  ? ''
                  : value.vacancy_allocation,
              name: value.name,
              quantity: value.quantity,
              tags: value.tags as any
            })
            setValueSetted(true)
          }
        }, [value])

        return (
          <>
            <FormRow>
              <FormItem formItemClasses='col-md-5'>
                <Input
                  type='text'
                  label={t('job:form.name')}
                  name='name'
                  required={true}
                  value={values.name}
                  onChange={value => {
                    setFieldValue('name', value)
                    onChange({ ...values, name: value })
                  }}
                  error={touched.name || triedToSend ? errors.name : undefined}
                  onBlur={handleBlur}
                  small
                />
              </FormItem>

              <FormItem formItemClasses='spaceUp col-md-7'>
                <SearchBox
                  type='text'
                  label={t('job:form.roleFamilyPlaceholder')}
                  required={true}
                  name='job_group'
                  value={{
                    name: values.job_group,
                    uuid: values.job_group_uuid
                  }}
                  indexKey='uuid'
                  valueKey='name'
                  onChange={({ name, uuid }: ValeuOption) => {
                    setFieldValue('job_group', name)
                    setFieldValue('job_group_uuid', uuid)
                    onChange({
                      ...values,
                      job_group: name,
                      job_group_uuid: uuid
                    })
                  }}
                  // onFocus={event => {
                  //   if (!event.target.value)
                  //     setFieldValue('job_group', values.name)
                  // }}
                  dataSource={search => job_group_search(search)}
                  error={
                    touched.job_group || touched.job_group_uuid || triedToSend
                      ? errors.job_group || errors.job_group_uuid
                      : undefined
                  }
                  onBlur={handleBlur}
                  small
                />
              </FormItem>
            </FormRow>

            <FormRow>
              <FormItem
                formItemClasses={
                  values.vacancy_allocation !== 'remote' &&
                  values.vacancy_allocation !== 'unspecified'
                    ? 'spaceUp col-md-4'
                    : 'spaceUp col-md-6'
                }
              >
                <Select
                  label={t('job:form.vacancy_allocation')}
                  required={true}
                  value={values.vacancy_allocation}
                  onBlur={handleBlur}
                  name='vacancy_allocation'
                  onChange={selectedOptions => {
                    setFieldValue(
                      'vacancy_allocation',
                      selectedOptions.currentTarget.selectedOptions[0].value
                    )
                    onChange({
                      ...values,
                      vacancy_allocation:
                        selectedOptions.currentTarget.selectedOptions[0].value
                    })
                  }}
                  error={
                    touched.vacancy_allocation || triedToSend
                      ? errors.vacancy_allocation
                      : undefined
                  }
                >
                  {vacancyAllocationOption.map((option, index) => {
                    return (
                      <option
                        value={option.value}
                        key={`${index}-${option.value}`}
                      >
                        {option.label}
                      </option>
                    )
                  })}
                </Select>
              </FormItem>

              {values.vacancy_allocation !== 'remote' &&
              values.vacancy_allocation !== 'unspecified' ? (
                <FormItem formItemClasses='col-md-4'>
                  <InputCity
                    type='text'
                    label={t('job:form.location')}
                    required={true}
                    name='city'
                    value={values.city}
                    onChange={value => {
                      setFieldValue('city', value[0])
                      onChange({ ...values, city: value[0] })
                    }}
                    error={
                      touched.city || triedToSend ? errors.city : undefined
                    }
                    onBlur={handleBlur}
                    small
                  />
                </FormItem>
              ) : (
                ''
              )}

              <FormItem
                formItemClasses={
                  values.vacancy_allocation !== 'remote' &&
                  values.vacancy_allocation !== 'unspecified'
                    ? 'spaceUp col-md-4'
                    : 'spaceUp col-md-6'
                }
              >
                <Input
                  type='text'
                  label={t('job:form.amount')}
                  required={true}
                  name='quantity'
                  value={values.quantity === 0 ? '' : values.quantity}
                  onBlur={handleBlur}
                  onChange={eventValue => {
                    const re = /^[0-9\b]+$/
                    if (
                      (eventValue === '' || re.test(eventValue)) &&
                      eventValue.length < 16
                    ) {
                      setFieldValue('quantity', +eventValue)
                      onChange({ ...values, quantity: +eventValue })
                    } else {
                      setFieldValue('quantity', values.quantity)
                      onChange({ ...values, quantity: values.quantity })
                    }
                  }}
                  error={
                    touched.quantity || triedToSend
                      ? errors.quantity
                      : undefined
                  }
                  small
                />
              </FormItem>
            </FormRow>

            <FormRow>
              <FormItem formItemClasses='col-md-6'>
                <DatePickerInput
                  label={t('job:form.deadline')}
                  name='deadline'
                  value={values?.deadline || ''}
                  required={false}
                  onBlur={handleBlur}
                  onChange={value => {
                    setFieldValue('deadline', value)
                    onChange({ ...values, deadline: value })
                  }}
                  error={
                    (touched.deadline || triedToSend) &&
                    (values.deadline === '' || values.deadline === null)
                      ? errors.deadline
                      : undefined
                  }
                  small
                />
              </FormItem>

              <FormItem formItemClasses='spaceUp col-md-6'>
                <Input
                  type='money'
                  label={t('job:form.budget')}
                  required={true}
                  name='budget'
                  value={values.budget === 0 ? '' : values.budget}
                  onBlur={handleBlur}
                  onChange={value => {
                    setFieldValue('budget', value)
                    onChange({ ...values, budget: value })
                  }}
                  error={
                    touched.budget || triedToSend ? errors.budget : undefined
                  }
                  small
                />
              </FormItem>
            </FormRow>

            <FormRow>
              <FormItem formItemClasses='spaceUp col-md-6'>
                <InputTag
                  label={t('job:form.tags')}
                  name='tags'
                  initialTags={values.tags}
                  onBlur={handleBlur}
                  onChange={value => {
                    setErrorInputTag('')
                    if (value.length <= 34) {
                      setFieldValue('tags', value)
                      onChange({ ...values, tags: value })
                    } else {
                      setErrorInputTag(t('job:tagError'))
                    }
                  }}
                  error={
                    errorInputTag ||
                    (touched.tags || triedToSend
                      ? errors.tags?.toString()
                      : undefined)
                  }
                  small
                />
              </FormItem>

              <FormItem
                formItemClasses={`spaceUp col-md-5 ${styles.switchContainer}`}
              >
                <Switch
                  label={t('job:form.status')}
                  initialValue={values.active}
                  onChange={value => {
                    setFieldValue('active', value)
                    onChange({ ...values, active: value })
                  }}
                />
              </FormItem>
            </FormRow>
            <FormRow>
              <FormItem>
                <RichTextEditor
                  placeholder={t('job:form:description')}
                  required={true}
                  value={values.description}
                  onBlur={() => setFieldTouched('description', true)}
                  error={
                    touched.description || triedToSend
                      ? errors.description
                      : undefined
                  }
                  onChange={value => {
                    const isOnlySpaces = sanitizeHtml(value).trim().length === 0
                    const realValue = isOnlySpaces ? '' : value
                    setFieldValue('description', realValue)
                    onChange({ ...values, description: realValue })
                  }}
                />
              </FormItem>
            </FormRow>
          </>
        )
      }}
    </Formik>
  )
}
