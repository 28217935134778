import React from 'react'

export interface FormItemProps {
  formItemClasses?: string
}

export const FormItem: React.FC<FormItemProps> = ({
  formItemClasses,
  children
}) => {
  return (
    <div className={`form-item col-xs-12 ${formItemClasses || ''}`}>
      {children}
    </div>
  )
}
