import React, { useCallback } from 'react'

import { WorkspaceSchema } from 'services/api/workspaces/workspaces.d'

interface UseWorkspacePermission {
  showOnGeralWorkspace: boolean
  dontShowOnGeralWorkspace: boolean
  onlyAllowOnDirectJobParent: (jobWorkspaceId: string) => boolean
}

/**
 * Usado para controlar a exibição de um componente filho. Veirifica se o workspace atual é o Geral.
 * Como o workspace Geral é apenas de leitura, esse componente é usado para desabilitar ações.
 *
 * @returns An object with two functions: `showOnGeralWorkspace` and `dontShowOnGeralWorkspace` and `onlyAllowOnDirectJobParent`
 */
export const useWorkspacePermission = (): UseWorkspacePermission => {
  const localAuth = localStorage.getItem('auth')
  const localAuthObj = localAuth && JSON.parse(localAuth)
  const currentWorkspace = localAuthObj?.user.currentWorkspace.uuid

  const isCurrentWorkspaceGeral = useCallback((): boolean => {
    const workspaceDetails = localAuthObj?.user.workspaces?.find(
      (workspaces: WorkspaceSchema) => workspaces.uuid === currentWorkspace
    )
    const workspaceIdMatriz = workspaceDetails?.id_matriz
    const companyId = localAuthObj?.user.company.uuid

    if (workspaceIdMatriz === companyId) return true
    return false
  }, [])

  const isCurrentWorkspaceDirectJobParent = useCallback(
    (jobWorkspaceId: string): boolean => {
      // Compare current workspace id with the job workspace id
      if (currentWorkspace === jobWorkspaceId) return true
      return false
    },
    []
  )

  return {
    showOnGeralWorkspace: isCurrentWorkspaceGeral(),
    dontShowOnGeralWorkspace: !isCurrentWorkspaceGeral(),
    onlyAllowOnDirectJobParent: isCurrentWorkspaceDirectJobParent
  }
}
