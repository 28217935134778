const es: AppLocale = {
  languages: {
    'pt-BR': 'Português',
    'en-US': 'English'
  },
  notifications: {
    lugarh: {
      error: {
        'An error occurred while processing your request':
          'Ocurrió un error al procesar su solicitud',
        'Not authorized, please check the workspace':
          'No autorizado. Verifique el workspace.',
        permission: 'No tiene permiso para acceder a esta función.'
      },
      success: {
        'Your request was successfully completed':
          'Su solicitud se completó con éxito'
      }
    }
  },
  goHomeButton: {
    lugarh: {
      goHome: 'Ir a la página de inicio'
    }
  },
  errors: {
    onlySpaces: 'No se permiten solo espacios',
    minBudget: 'El presupuesto mínimo es de 650',
    errorTitle: 'Error',
    forbbidenError: 'No puede acceder a esta función.',
    requiredField: 'Campo obligatorio',
    specialCharacters: 'Solo se permiten letras y números',
    dateInvalidFormat:
      'Por favor, ingrese su fecha de nacimiento en el formato correcto.',
    deadlineInvalidFormat: 'Por favor ingrese una fecha de cierre válida.',
    futureDate:
      'La fecha de nacimiento no puede ser en el futuro. Por favor, ingrese una fecha válida.',
    invalidBirthdate:
      'La fecha de nacimiento proporcionada está fuera del rango aceptable. Asegúrese de ingresar una fecha válida.',
    invalidStartDateFormat:
      'Por favor, ingrese la fecha de inicio en el formato correcto.',
    futureStartDate: 'La fecha de inicio no puede ser en el futuro.',
    invalidStartDate:
      'La fecha de inicio proporcionada está fuera del rango aceptable. Asegúrese de ingresar una fecha válida.',
    invalidEndDateFormat:
      'Por favor, ingrese la fecha de término en el formato correcto.',
    invalidEndDateOrder:
      'La fecha de término del curso debe ser posterior a la fecha de inicio.',
    invalidEndDate:
      'La fecha de término proporcionada está fuera del rango aceptable. Asegúrese de ingresar una fecha válida.',
    futureEndDate: 'La fecha de término del curso no puede ser en el futuro.',
    expiredtoken: 'Tu conexión ha expirado',
    emailInvalid: '¡E-mail inválido!',
    fieldInvalid: 'iCampo inválido!',
    fieldMinLength: 'Debe tener un mínimo de 3 caracteres.',
    onRequestFail:
      'Ocurrió un error en la obtención de datos, por favor intente nuevamente',
    addNoteError: 'Error al agregar nota, por favor intente nuevamente',
    addReview: 'Error al agregar nota',
    cnpj: 'El formato del CNPJ es incorreto',
    cnpjMaxLength: 'Ha alcanzado el límite máximo de 18 caracteres.',
    cpfCnpjInvalid: 'El número introducido no es válido.',
    cpfCnpjMinLength: 'Debe tener un mínimo de 14 caracteres.',
    cpfCnpjMaxLength: 'Ha alcanzado el límite máximo de 18 caracteres.',
    passwordMin: 'La contraseña necesita tener al menos 6 caracteres',
    passwordConfirm: '¡Las contraseñas deben ser iguales!',
    finalizeCompanyRegistration:
      'Para acceder a este recurso es necesario finalizar el registro de la empresa.',
    invalidSlug: '¡Enlace de la página de carreras inválido!',
    linkAvailable: 'Enlace Disponible!',
    linkUnavailable: 'Enlace Indisponible',
    invalidComapanyName:
      'El nombre de la empresa no puede tener caracteres especiales.',
    applicationError: 'Este registro ya existe en nuestras bases de datos.',
    timeoutError: 'El servidor tardó demasiado en responder. Intentar otra vez',
    emptyCity: 'Se debe informar el campo de ubicación del trabajo.',
    limitReached: 'Esta función ya alcanzó el límite del plan.',
    creditCardNumberInvalid: 'Número de tarjeta inválida',
    creditCardCvvInvalid: 'CVV inválida',
    creditCardExpirationMonthInvalid: 'Mes inválido',
    creditCardExpirationYearInvalid: 'Año inválido',
    hiringWorkflowNameMaxLength:
      'Ha alcanzado el límite máximo de 80 caracteres.',
    hiringWorkflowDescriptionMaxLength:
      'Ha alcanzado el límite máximo de 300 caracteres.',
    downloadAttachedFile: 'El archivo no existe o ya no está disponible',
    serverError: 'El servidor no pudo completar su solicitud.'
  },
  success: {
    candidateMoved: 'Candidato movido exitosamente',
    noteAdded: 'Nota agregada exitosamente',
    addReview: 'Nota agregada exitosamente',
    applicationSuccess: 'Éxito',
    jobMoved: 'Trabajo movido exitosamente',
    jobDuplicated: 'Trabajo duplicado exitosamente'
  },
  cookie: {
    message:
      'Usamos cookies para ayudar a personalizar el contenido y brindar una experiencia más segura, ' +
      'no almacenamos datos personales. Al navegar por las páginas, declara que está de acuerdo con ' +
      'nuestra Política de privacidad.',
    buttonAccept: 'Aceptar',
    buttonRefuse: 'Rehusar'
  },
  common: {
    current: 'Actual',
    gender: {
      male: 'Masculino',
      female: 'Femenino',
      other: 'Otro'
    },
    civilStatus: {
      single: 'Soltero',
      married: 'Casado',
      divorced: 'Divorciado',
      widowed: 'Viudo',
      other: 'Otros'
    },
    applyToJob: 'Postularse a la vacante'
  },
  createWorkflow: {
    create: 'Crear Flujo de Contratación',
    update: 'Actualizar Flujo de Contratación'
  },
  home: {
    metatags: {
      title: 'Lugarh | R&S',
      url: 'https://recrud.bne.com.br',
      description:
        'La próxima generación de gestión de relaciones con los candidatos'
    },
    content: 'Edite el archivo src/Home.tsx y guarde para actualizar'
  },
  uploadBox: {
    minSizeRecommended: 'tamaño sugerido 400 x 50 px',
    logo: 'Logotipo',
    suggestedSize: 'Tamaño sugerido'
  },
  searchBar: {
    allCandidates: 'Ver todos los candidatos',
    allJobs: 'Ver todas las vacantes',
    placeholder: 'Búsqueda de candidatos, vacantes y etiquetas',
    results: {
      candidates: 'Candidatos encontrados',
      jobs: 'Vacantes encontradas'
    },
    searching: 'Buscando...',
    notFound: 'Ningún resultado encontrado',
    appliedFor: 'Se postuló para {{number}} vacantes',
    candidatesNumber: '{{number}} candidatos',
    createdAt: 'Creado en {{date}}',
    candidates: 'candidatos'
  },
  header: {
    about: 'Sobre Nosotros',
    terms: 'Términos',
    signup: 'Crear Cuenta'
  },
  headerSettings: {
    workspaces: 'Espacios de Trabajo',
    myProfile: 'Mi perfil',
    profileEdit: 'Editar perfil',
    company: 'Empresa',
    subscriptionSettings: 'Configuración de la suscripción',
    companySettings: 'Configuraciones de la Empresa',
    jobPostings: 'Gestión de vacantes',
    admission: 'Admissão',
    changeCompany: 'Cambia la Empresa',
    changeWorkspace: 'Cambia el Workspace',
    manageWorkspace: 'Administrar Workspace',
    hiringWorkflows: 'Flujos de Contratación',
    manageTeam: 'Administrar equipo',
    language: 'Idioma',
    english: 'Inglés',
    portuguese: 'Portugués',
    spanish: 'Español',
    session: 'Sesión',
    leave: 'Salir',
    hello: 'Hola',
    sections: {
      dashboard: 'Dashboard',
      jobs: 'Vacantes',
      candidates: 'Base de Candidatos'
    },
    helpButtonDescription: 'Ayudar',
    helpButtonSubject: 'Ayuda con la Gestión de Candidatos'
  },
  user: {
    role: {
      admin: 'Administrador',
      admin_rocketmat: 'Administrador',
      manager: 'Gerente',
      recruiter: 'Reclutador',
      recruiter_webtactics: 'Reclutador Webtactics'
    },
    indicationButton: 'Indicar Recrud',
    copyLinkSuccessMessage: 'Enlace copiado con éxito'
  },
  router: {
    pageNotFound: 'Página no encontrada. Por favor, contacte el suporte.'
  },
  login: {
    lugarh: {
      title:
        '[[span]]Revolucione su proceso de recursos humanos[[/span]] [[span.highlight]]de principio a fin[[/span]] [[span]]en un solo Lugarh[[/span]]',
      subtitle:
        'Además de una poderosa herramienta de Reclutamiento y Selección, ofrecemos un ecosistema completo para',
      itemOne: 'Admiciones',
      itemTwo: 'Gestión de Talento',
      itemThree: 'BPO de RH',
      specialist: 'Hable con un especialista',
      form: {
        title: 'Iniciar sesión',
        email: {
          placeholder: 'E-mail'
        },
        password: {
          placeholder: 'Contraseña',
          show: 'Mostrar',
          hide: 'Ocultar'
        },
        keepConnected: {
          label: 'Mantener conectado'
        },
        submitButton: 'Iniciar',
        submitButtonLoading: 'Iniciando...',
        errors: {
          emailRequired: 'E-mail es obligatorio',
          emailInvalid: 'E-mail es inválido',
          passwordMin: 'Al menos 6 caracteres',
          passwordRequired: 'Contraseña es obligatoria'
        }
      },
      forgotPassword: '¿Olvidaste la contraseña?',
      noAccount: '¿No tienes una cuenta?',
      createAccount: 'Crear cuenta',
      chooseLanguage: 'Elija su idioma',
      errors: {
        blockedUser:
          'Usuario sin permiso de acceso. Póngase en contacto con su administrador para obtener más información.',
        expiredSubscription:
          'Subscripcion vencida. Póngase en contacto con su administrador para obtener más información.',
        expiredSession: 'Sesión expirada. Ingresar de nuevo.',
        loginError:
          'El nombre de usuario o la contraseña no son válidos. Inténtalo de nuevo.'
      }
    },
    metatags: {
      title: 'Lugarh | R&S',
      url: 'https://recrud.bne.com.br',
      description:
        'La próxima generación de gestión de relaciones con los candidatos'
    },
    welcome: 'Sea bienvenido a',
    firstParagraph:
      'Una plataforma de contratación simple, fácil e inteligente.',
    secondParagraph: '¡Disfrute de su experiencia!',
    loginTitle: 'Entrar en su cuenta',
    firstname: 'Nombre',
    lastname: 'Apellido',
    email: 'E-mail',
    password: 'contraseña',
    loginCheckBox: 'Mantener conectado',
    forgotPassword: '¿Olvidaste la contraseña?',
    loginAccount: '¡Iniciar sesión en tu Cuenta!',
    registerTitle: '¡Crea tu Cuenta!',
    repeatPassword: 'Repetir Contraseña',
    registerCheckBox: '¡Estoy de acuerdo con los términos de uso!',
    registerNow: '¡Registrar Ahora!',
    aboutUs: 'Sobre Nosotros',
    privacy: 'Políticas de Privacidad',
    terms: 'Términos',
    whatsRecrud: '¿Qué es Recrud?',
    loginError: 'Usuario o contraseña incorrectos. Inténtalo de nuevo',
    expiredSession: 'Sesión expirada. Iniciar sesión de nuevo',
    blockedUser:
      'Usuario sin permiso de acceso. Comuníquese con el administrador para obtener más informaciones.',
    expiredSubscription:
      'Subscripcion vencida. Comuníquese con el administrador para obtener más información.',
    signupSuccess: '¡Registro exitoso!',
    registerError:
      'Ocurrió un error al registrarse, por favor intente nuevamente',
    registerErrorDataExists: 'El e-mail ya está en uso, inténtelo de nuevo.',
    register: 'Registrar',
    login: 'Iniciar Sesión',
    passwordConfirm: '¡Las contraseñas no combinan!',
    language: 'Idioma',
    acquisitionChannel: '¿Cómo nos ha encontrado?'
  },

  card: {
    lugarh: {
      title:
        'Habla con un experto y descubre los mejores planes para tu empresa',
      subtitle:
        'Para descubrir los planes que mejor se adaptan a tus necesidades, te invitamos a hablar con nuestros expertos, quienes están listos para ayudarte de manera personalizada.',
      ctaButton: 'Conoce los planes',
      backButton: 'Volver'
    }
  },
  selectableList: {
    lugarh: {
      searchInputPlaceholder: 'Buscar p',
      selectAllLabel: 'Seleccionar todos',
      notFound: 'No se encontraron resultados'
    }
  },
  workspaceForm: {
    lugarh: {
      fetchUsersError: 'Error al buscar usuarios',
      placeholder: {
        name: 'Nombre del Workspace',
        slug: 'Apodo del Workspace',
        cnpj: 'CNPJ',
        postcode: 'Código Postal',
        address_line1: 'Dirección',
        address_line2: 'Complemento',
        city: 'Ciudad',
        region: 'Región',
        country: 'País'
      },
      addAddressLabel: 'Agregar dirección',
      submitButton: {
        stepOne: 'Siguiente',
        stepTwo: 'Enviar',
        loading: 'Procesando...'
      },
      errors: {
        'Nome do workspace deve ter no máximo 80 caracteres':
          'Nombre del workspace debe tener un máximo de 80 caracteres',
        'Nome do workspace é obrigatório':
          'Nombre del workspace es obligatorio',
        'CNPJ deve conter apenas números': 'CNPJ debe contener solo números',
        'CNPJ deve ter 14 dígitos': 'CNPJ debe tener 14 dígitos',
        'CEP é obrigatório': 'CEP es obligatorio',
        'Endereço é obrigatório': 'Dirección es obligatoria',
        'Cidade é obrigatória': 'Ciudad es obligatoria',
        'País é obrigatório': 'País es obligatorio',
        'Adicione pelo menos um usuário': 'Agregue al menos un usuario',
        'É obrigatório adicionar usuários': 'Es obligatorio agregar usuarios'
      }
    }
  },
  editUserForm: {
    lugarh: {
      fetchWorkspacesError: 'Error al buscar workspaces',
      placeholder: {
        firstname: 'Nombre',
        lastname: 'Apellido',
        password: 'Contraseña',
        email: 'E-mail',
        role: {
          select: 'Seleccione un rol',
          admin: 'Administrador',
          manager: 'Gerente',
          recruiter: 'Reclutador'
        }
      },
      submitButton: {
        stepOne: 'Siguiente',
        stepTwo: 'Enviar',
        loading: 'Procesando...'
      },
      errors: {
        'Required field': 'Campo obligatorio',
        'Password must have at least 6 characters': 'Al menos 6 caracteres',
        'Select at least one workspace': 'Seleccione al menos un workspace',
        'Workspace is a required field': 'Workspace es un campo obligatorio'
      }
    }
  },
  createWorkspaceModal: {
    lugarh: {
      title: 'Agregar espacio de trabajo',
      subtitle: 'Ingrese la información a continuación para continuar',
      stepOne: '1. Datos básicos',
      stepTwo: '2. Usuarios'
    }
  },
  createUserModal: {
    lugarh: {
      title: 'Agregar usuario',
      subtitle: 'Ingrese la información a continuación para continuar',
      stepOne: '1. Datos del usuario',
      stepTwo: '2. Seleccione el workspace'
    }
  },
  editUserModal: {
    lugarh: {
      title: 'Alterar usuario',
      subtitle: 'Ingrese la información a continuación para continuar',
      stepOne: '1. Datos del usuario',
      stepTwo: '2. Seleccione el workspace'
    }
  },
  changeEnvironmentModal: {
    lugarh: {
      title: 'Workspaces',
      subtitle: 'Elige un workspace para trabajar',
      actions: {
        change: 'Cambiar Workspace',
        manage: 'Administrar Workspaces'
      }
    }
  },
  singleSelectableList: {
    lugarh: {
      placeholder: {
        search: 'Buscar por nombre'
      }
    }
  },
  moveWorkspaceModal: {
    lugarh: {
      modalTitle: 'Mover Trabajo de Workspace',
      modalSubtitle: 'Seleccione el workspace al que desea mover el trabajo',
      submitButton: 'Mover Trabajo'
    }
  },
  resetPassword: {
    lugarh: {
      form: {
        title: 'Redefinir Contraseña',
        password: {
          show: 'Mostrar',
          hide: 'Ocultar'
        },
        newPassword: {
          placeholder: 'Nueva Contraseña'
        },
        confirmNewPassword: {
          placeholder: 'Confirmar Nueva Contraseña'
        },
        submitButton: 'Redefinir',
        errors: {
          newPasswordMin: 'Al menos 6 caracteres',
          newPasswordRequired: 'Contraseña es obligatoria',
          confirmNewPasswordRequired: 'Confirmar contraseña es obligatoria',
          confirmNewPasswordMatch: '¡Las contraseñas deben ser iguales!'
        },
        goToLogin: 'Volver a inicio de sesión'
      },
      resetPasswordFail: 'Error al redefinir la contraseña.',
      resetPasswordSuccess: 'Contraseña redefinida con éxito.'
    }
  },
  forgotPassword: {
    lugarh: {
      form: {
        title: 'Recuperar contraseña',
        email: {
          placeholder: 'E-mail'
        },
        submitButton: 'Recuperar',
        errors: {
          emailRequired: 'E-mail es obligatorio',
          emailInvalid: 'E-mail es inválido'
        },
        goToLogin: 'Volver a inicio de sesión'
      }
    },
    metatags: {
      title: 'Lugarh | R&S',
      url: 'https://recrud.bne.com.br',
      description:
        'La próxima generación de gestión de relaciones con los candidatos'
    },
    welcome: 'Sea bienvenido a',
    firstParagraph: '¡Gestiona los procesos de selección de tu empresa',
    secondParagraph: 'de forma sencilla e inteligente!!',
    forgotPassword: 'Olvidé mi contraseña',
    cardParagraph:
      'Escriba su e-mail para recibir instrucciones de cambio de contraseña',
    recoveryPassword: 'Recuperar contraseña',
    emailValidationError: 'Por favor introduzca um e-mail válido',
    emailNotFound: 'E-mail no registrado, intente nuevamente',
    sendEmailFail: 'Falla al enviar el e-mail de recuperación',
    sendEmailSuccess: 'E-mail de recuperación enviado exitosamente'
  },
  plans: {
    metatags: {
      title: 'string',
      description: 'string',
      url: 'string'
    },
    selectPlanTitle: 'Seleccione un plan',
    plansCardBasic: {
      title: 'Basic',
      subTitle: 'Para empresas con un volumen medio de vacantes',
      planValue: 'R$29,90',
      planPeriod: '/mes',
      feat1: 'Hasta 2 vacantes al mes',
      feat2: 'Usuarios ilimitados',
      feat3: 'Sin límite de candidatos'
    },
    plansCardSmart: {
      title: 'Smart',
      subTitle: 'Para empresas con un volumen medio de vacantes',
      planValue: 'R$49,90',
      planPeriod: '/mes',
      feat1: 'Hasta 5 vacantes al mes',
      feat2: 'Usuarios ilimitados',
      feat3: 'Sin límite de candidatos'
    },
    plansCardAdvanced: {
      title: 'Advanced',
      subTitle: 'Para un mayor volumen de vacantes',
      planValue: 'R$69,90',
      planPeriod: '/mes',
      feat1: 'Hasta 10 vacantes al mes',
      feat2: 'Usuarios ilimitados',
      feat3: 'Sin límite de candidatos'
    },
    plansCardEnterprise: {
      title: 'Enterprise',
      subTitle: 'Para un gran volumen de vacantes',
      planValue: 'Presupuesto personalizado',
      planPeriod: '',
      feat1: 'Más de 10 vacantes al mes',
      feat2: 'Usuarios ilimitados',
      feat3: 'Sin límite de candidatos',
      selectButton: 'Hable con un especialista'
    },
    selectButton: 'Empieza ahora'
  },
  checkoutPlan: {
    metatags: {
      title: 'string',
      description: 'string',
      url: 'string'
    },
    companyFormTitle: 'Datos de la empresa',
    paymentFormTitle: 'Método de pago',
    creditCardLabel: 'Tarjeta de crédito',
    cardNumber: 'Numero de tarjeta',
    cvv: 'CVV',
    expirationMonth: 'Meses de vencimiento',
    expirationYear: 'Año de vencimiento',
    cardHolderName: 'Titular de la tarjeta',
    changePlanButton: 'Cambiar planes',
    paymentButton: 'Contratar',
    cardTokenizationError: 'Verifique los detalles de su tarjeta de crédito',
    iugoCardTokenizationFailure:
      'Se produjo un error interno al procesar la tarjeta.',
    billingAddressFromTitle: 'Dirección de Facturación',
    phone: 'Teléfono',
    cpfCnpj: 'CPF/CNPJ',
    zipcode: 'Código postal',
    district: 'Barrio',
    street: 'Dirección',
    number: 'Número',
    complement: 'Complemento',
    city: 'Ciudad',
    state: 'Estado'
  },
  myProfile: {
    banner: {
      title: 'Editar Perfil',
      text: '¡Edite sus informaciones personales, contraseñas y mucho más!'
    },
    form: {
      firstname: 'Nombre',
      lastname: 'Apellido',
      email: 'E-mail',
      password: 'Contraseña',
      confirmPassword: 'Confirmar contraseña',
      save: '¡Guardar cambios!',
      successEditMessage: '¡Los cambios se guardaron exitosamente!'
    },
    confirmationModal: {
      wrongPassword: 'Contraseña incorreta',
      failToSend: 'Falla al enviar el e-mail',
      passwordConfirmationSucess:
        'Contraseña confirmada. Se ha enviado un código a su nuevo e-mail',
      unavailable: 'No es posible realizar la operación en este momento',
      wrongCode: 'Error al validar el código'
    }
  },
  addCandidate: {
    lugarh: {
      title: 'Agregar candidato',
      subtitle:
        'Este es tu panel de datos generales del proceso de selección de tu empresa',
      submitFormButton: 'GUARDAR INFORMACIÓN',
      personalDataBox: {
        title: 'Información personal',
        firstname: 'Primer Nombre *',
        lastname: 'Ultimo Nombre *',
        birthdate: 'Fecha de Nacimiento',
        maritalStatus: 'Estado Civil',
        dateFormat: 'DD/MM/YYYY',
        maritalStatusOptions: {
          select: 'Seleccione',
          divorced: 'Divorciado',
          married: 'Casado',
          single: 'Soltero',
          widowed: 'Viudo',
          other: 'Otro'
        },
        sex: 'Género',
        sexOptions: {
          female: 'Feminine',
          male: 'Masculine',
          other: 'Otro'
        },
        phone: 'Teléfono',
        email: 'E-mail',
        geoLocation: 'Localización *',
        cityRelocation: '¿Tiene disponibilidad para cambio?',
        cityRelocationOptions: {
          yes: 'Si',
          no: 'No'
        },
        salary_expectation: 'Expectativa Salarial',
        linkedin_profile: 'LinkedIn',
        facebook_profile: 'Facebook',
        twitter_profile: 'Twitter',
        origin: 'origen del candidato',
        about: 'Sobre *'
      },
      languageBox: {
        title: 'Idioma',
        language: 'Idioma *',
        level: 'Nivel *',
        levelOptions: {
          basic: 'Básico',
          intermediate: 'Intermediario',
          fluent: 'Avanzado',
          native: 'Nativo'
        },
        button: 'Agregar nuevo idioma',
        deleteButton: 'Borrar'
      },
      educationBox: {
        title: 'Educación',
        course: 'Curso *',
        institution: 'Instituição',
        startDate: 'Fecha de inicio *',
        endDate: 'Fecha final *',
        level: 'Nivel *',
        dateFormat: 'DD/MM/YYYY',
        levelOptions: {
          incomplete_middleschool: 'Secundária incompleta',
          middleschool: 'Secundária Completa',
          incomplete_highschool: 'Preparatoria Incompleta',
          highschool: 'Preparatoria completa',
          undergraduate: 'Licenciatura',
          postgraduate: 'Postgrado',
          masters: 'Maestría',
          doctorate: 'Doctorado'
        },
        description: 'Sobre',
        button: 'Agregar nuevo curso',
        deleteButton: 'Borrar'
      },
      certificateBox: {
        title: 'Certificados y logros',
        name: 'Nombre *',
        hours: 'Carga horaria *',
        button: 'Agregar nuevo logro',
        deleteButton: 'Borrar'
      },
      experienceBox: {
        title: 'Experiencia',
        role: 'Posición *',
        company: 'Empresa',
        startDate: 'Fecha de inicio *',
        endDate: 'Fecha final',
        currentJob: 'Trabajo actual *',
        description: 'Descripción de la actividad',
        button: 'Agregar nueva experiencia',
        deleteButton: 'Borrar',
        dateFormat: 'DD/MM/YYYY'
      },
      otherInformationBox: {
        title: 'Otras informaciones',
        other_info: 'Otras informaciones'
      },
      attachmentsBox: {
        title: 'Anejos',
        button: 'Agregar archivo',
        attachmentError:
          'Solo se permiten archivos PDF, PNG, JPEG y JPG con un máximo de 3mb.',
        underlineText: 'Haga clic para cargar',
        description: 'o arrastre y suelte el archivo aquí',
        fileTypes: 'PDF, PNG o JPG (max. 3MB)'
      },
      messages: {
        success: '¡Candidato agregado exitosamente!'
      },
      submitButton: 'Continuar'
    },
    button: {
      addAttachments: 'Agregar archivo'
    },
    banner: {
      title: 'Agregar Candidato ',
      text: 'Agregar candidato a la base de datos'
    },
    form: {
      email: 'E-mail',
      firstname: 'Primer Nombre',
      lastname: 'Ultimo Nombre',
      about: 'Sobre',
      geoLocation: 'Localización',
      phone: 'Teléfono',
      maritalStatus: 'Estado Civil',
      sex: 'Género',
      birthdate: 'Fecha de Nacimiento',
      salaryExpectation: 'Expectativa Salarial',
      cityRelocation: '¿Tiene disponibilidad para cambio?',
      linkedinProfile: 'Linkedin',
      facebookProfile: 'Facebook',
      twitterProfile: 'Twitter',
      save: 'Guardar!',
      successMessage: '¡Registro insertado correctamente!'
    },
    titleBox: {
      personalData: 'Información personal',
      language: 'Idioma',
      education: 'Educación',
      certificatesAndAchievements: 'Certificados y logros',
      experience: 'Experiencia',
      otherInformation: 'Otra información',
      attachments: 'Subir archivos'
    },
    languageBox: {
      language: 'Idioma',
      level: 'Nivel'
    },
    educationBox: {
      course: 'Curso',
      institution: 'Institución',
      startDate: 'Fecha de inicio',
      endDate: 'Fecha final',
      level: 'Nivel',
      about: 'Acerca de'
    },
    certificateBox: {
      name: 'Nombre',
      hours: 'Carga horaria'
    },
    experienceBox: {
      role: 'Posición',
      company: 'Empresa',
      startDate: 'Fecha de inicio',
      endDate: 'Fecha final',
      currentJob: 'Trabajo actual',
      description: 'Descripción de la actividad'
    },
    otherInformation: 'Otras informaciones',
    attachmentSubTitleDescription:
      'Adjunte los documentos de respaldo. [Ej.: currículum, CV y/o carta de presentación]',
    attachmentSubTitleFileDescription: 'Anejos',
    attachmentError:
      'Solo se permiten archivos PDF, PNG, JPEG y JPG con un máximo de 5mb.',
    sexOptions: {
      female: 'Feminine',
      male: 'Masculine',
      other: 'Otro'
    },
    maritalStatusOptions: {
      select: 'Seleccione',
      divorced: 'Divorciado',
      married: 'Casado',
      single: 'Soltero',
      widowed: 'Viudo',
      other: 'Otro'
    },
    cityRelocationOptions: {
      yes: 'Sí',
      no: 'No'
    }
  },
  candidateDetail: {
    movedTo: 'movido a la fase {{stageName}} por {{authorName}}.',
    appliedTo: 'se postuló a la vacante.',
    current: 'ACTUALMENTE'
  },
  companySettings: {
    save: 'Guardar cambios!',
    banner: {
      title: 'Configuraciones de la Empresa',
      text: 'Edite la información de la empresa, la página de talentos, los datos confidenciales y mucho más!'
    },
    color: {
      companyPageHeaderColor: 'Color del encabezado de la página de carreras',
      companyPageFontColor: 'Color de fuente de la página de carreras',
      companyPageButtonColor: 'Color de los botones de la página de carreras',
      companyPageButtonFontColor:
        'Color de fuente de los botones de la página de carreras'
    },
    careerPageSetting: {
      title: 'Página de la careera Configuración',
      showHomeButtonTitle: 'Mostrar el botón de inicio',
      showHomeButtonText: 'Decida si el candidato verá el botón de inicio.',
      showMainPageTitle: 'Mostrar la página principal',
      showMainPageText:
        'Decida si el candidato podrá acceder a la página principal.',
      careerPageLabel: 'Activo?'
    },
    lgpd: {
      title: 'Datos personales / Datos confidenciales / LGPD',
      label: {
        question: 'Activo?',
        required: 'Requerido?'
      },
      askPhoneTitle: 'Preguntar número de teléfono',
      askPhoneText:
        'Decida si el candidato informará el número de teléfono y si usted tratará esa información.',
      civilStatusTitle: 'Estado civil',
      civilStatusText:
        'Decida si el candidato informará su estado civil y si usted tratará esa información.',
      askGenderTitle: 'Preguntar género',
      askGenderText:
        'Decida si el candidato informará su género y si usted tratará esta información',
      askBirthdateTitle: 'Preguntar Fecha de Nacimiento',
      askBirthdateText:
        'Decida si el candidato informará la fecha de nacimiento y si usted tratará esa información.',
      askWagePretensionTitle: 'Preguntar Pretensión Salarial',
      askWagePretensionText:
        'Decida si el candidato le informará sobre su pretensión salarial y si usted tratará esa información.',
      askMovingAvailabilityTitle: 'Pregunte disponibilidad para mudanza',
      askMovingAvailabilityText:
        'Decida si el candidato le informará o no sobre su disponibilidad para mudarse y si usted tratará esa información.',
      askAboutLinkedinProfileTitle: 'Preguntar sobre el perfil de LinkedIn',
      askAboutLinkedinProfileText:
        'Decida si el candidato informará su perfil de LinkedIn y si usted tratará esa información.',
      askAboutFacebookProfileTitle: 'Preguntar sobre el perfil de Facebook',
      askAboutFacebookProfileText:
        'Decida si el candidato informará su perfil de Facebook y si usted tratará esa información.',
      askAboutTwitterProfileTitle: 'Preguntar sobre el perfil de Twitter',
      askAboutTwitterProfileText:
        'Decida si el candidato informará su perfil de Twitter y si usted tratará esa información.',
      termsOfUseTitle: 'Condiciones de uso',
      termsOfUseText: 'Inserte un enlace a las condiciones de uso.'
    },
    companyForm: {
      name: 'Razón Social',
      fancyName: 'Nombre de fantasía',
      cnpj: 'CNPJ',
      description: 'Descripción de la empresa',
      location: 'Localización',
      site: 'Enlace al sitio web de carreras'
    },
    changesSaved: '¡Cambios guardados!',
    messageChangesSaved:
      'El sitio web de su empresa ha sido modificado. Puedes verificarlo accediendo al siguiente enlace:'
  },
  onboarding: {
    buttons: {
      next: 'Próximo',
      previous: 'Anterior',
      skip: 'Saltar'
    },
    dashboard: {
      finishOnboardingButton: 'Finalizar Recorrido Inicial',
      content: [
        {
          title: 'Bienvenido a Recrud',
          text: 'Como es tu primera vez aquí, te ayudaremos con este sencillo tutorial para que conozcas mejor el Recrud y lo explores de la mejor manera.'
        },
        {
          title: 'Barra de navegación',
          text: 'Esta es la barra de navegación del sistema. Aquí puedes acceder a tus vacantes, banco de candidatos y volver al dashboard.'
        },
        {
          title: 'Barra de búsqueda',
          text: 'Si deseas encontrar vacantes o candidatos específicos, puedes buscarlos aquí y ahorrar tiempo.'
        },
        {
          title: 'Panel de administración',
          text: 'Si eres gerente, aquí puedes configurar la página de tu empresa, administrar tu flujo de contratación y agregar o eliminar usuarios de tu equipo.<br /><br />Si eres un reclutador, aquí puedes editar tu perfil y cambiar tu contraseña.'
        },
        {
          title: 'El Dashboard',
          text: 'Acompañe los datos de su proceso de contratación a través del dashboard, como el tiempo medio de contratación, los números de su base de candidatos y mucho más.'
        },
        {
          title: 'Recrud',
          text: 'Ahora conoces bien la parte inicial del sistema. Navegue a las otras páginas para continuar con el tutorial.'
        }
      ]
    },
    jobs: {
      finishOnboardingButton:
        'Finalizar recorrido a través de la pantalla de vacantes',
      content: [
        {
          title: 'Vacantes',
          text: 'Este es el panel de vacantes. Todas sus vacantes activas se mostrarán en la página de su empresa para que los candidatos puedan postularse. Veamos un poco más sobre esta página.'
        },
        {
          title: 'Crea una nueva vacante',
          text: '¿Necesitas abrir una nueva vacante? Para registrarla, haga click en el botón indicado y complete el formulario de registro!'
        },
        {
          title: 'Estatus de la vacante',
          text: 'Una vacante puede encontrarse activa o inactiva. Si esta barra se encuentra verde, la vacante está activa. Si se encuentra roja, está inactiva. Los candidatos solo podrán postularse para vacantes activas.'
        },
        {
          title: 'Reclutadores',
          text: 'Puede agregar o eliminar los reclutadores asociados con esta vacante haciendo click en editar.'
        },
        {
          title: 'Candidatos',
          text: 'Aquí podrás administrar y ver los candidatos que se postularon a la vacante.'
        },
        {
          title: 'Compartir vacantes',
          text: 'Aquí en el botón "compartir" encontrarás los enlaces disponibles para compartir tus vacantes en cada red social. Utilice los enlaces correctos para cada finalidad, de esta forma conseguirá rastrear el origen de los candidatos.'
        },
        {
          title: 'Editar Vacante',
          text: 'En el botón "Editar" puede cambiar la información relativa a la vacante y los reclutadores asociados a ella.'
        },
        {
          title: 'Filtros',
          text: 'Si tienes muchas vacantes y deseas encontrarlas rápidamente, cuenta con el panel de filtros.'
        },
        {
          title: 'Visualización en Listas o Tarjetas',
          text: '¿Quieres ver tus vacantes en otro formato? Elija aquí si desea ver las vacantes en tarjetas o en formato de lista.'
        }
      ]
    },
    cadidatesDB: {
      finishOnboardingButton:
        'Finalizar el recorrido por el banco de candidatos',
      content: [
        {
          title: 'Candidatos',
          text: 'Este es tu banco de talentos. ¡Todos los candidatos que se postularon a sus vacantes estarán aquí!'
        },
        {
          title: 'Ordenar candidatos',
          text: 'Si desea ordenar a sus candidatos para facilitar su búsqueda, consulte las posibilidades de organización aquí.'
        },
        {
          title: 'Filtrar candidatos',
          text: '¿Demasiados candidatos? Puede filtrar en este panel lateral por campos específicos relacionados con la educación, la experiencia, etc.'
        },
        {
          title: 'Vista Previa del currículum',
          text: 'No es necesario acceder a la página del candidato uno por uno. Haga click aquí para ver un resumen de la información para facilitar su evaluación.'
        },
        {
          title: 'Detalles del candidato',
          text: 'Si la vista previa no fue suficiente, puede acceder a toda la información de este candidato haciendo click aquí para ver su currículum completo y su historial.'
        },
        {
          title: 'Postularse a una vacante',
          text: 'Si desea agregar este candidato a una vacante, puede hacerlo por aquí.'
        }
      ]
    },
    candidateDetail: {
      finishOnboardingButton:
        'Finalizar recorrido por los detalles del candidato',
      content: [
        {
          title: 'Detalles del candidato',
          text: 'Aquí encontrará toda la información detallada sobre un candidato, incluyendo el historial de postulaciones, las observaciones realizadas por los reclutadores y mucho más.'
        },
        {
          title: 'Historial',
          text: 'En el historial del candidato puede consultar todas las etapas por las cuales el candidato pasó en un proceso de contratación.'
        },
        {
          title: 'Mover al candidato de etapa',
          text: 'Si tiene permiso para administrar este proceso, puede mover al candidato a otras etapas del proceso de contratación.'
        },
        {
          title: 'Evaluación del reclutador',
          text: 'Puede ver la calificación otorgada por un reclutador a un candidato. Las notas son información importante durante el proceso del candidato.'
        },
        {
          title: 'Evaluar al candidato',
          text: 'Para calificar al candidato, basta utilizar las estrellas.'
        },
        {
          title: 'Anotaciones',
          text: 'Si desea registrar informaciones sobre el candidato, use el cuadro de texto para dejarlas registradas.'
        }
      ]
    },
    candidates: {
      finishOnboardingButton: 'Finalizar el recorrido por los candidatos',
      content: [
        {
          title: 'Candidatos',
          text: 'Estos son los candidatos que se postularon a una vacante disponible. Aquí puedes administrarlos para cumplir con las tareas de tu flujo de contratación.'
        },
        {
          title: 'Etapas del Flujo de Contratación',
          text: 'Esta barra muestra todas las etapas del flujo de contratación asignadas a esta vacante. Aquí puedes filtrar a tus candidatos por etapas. Haga click en una etapa para ver a los candidatos presentes en ella.'
        },
        {
          title: 'Ordenar candidatos',
          text: 'Si desea ordenar a sus candidatos para facilitar su búsqueda, consulte las posibilidades de organización aquí.'
        },
        {
          title: 'Filtrar candidatos',
          text: '¿Tienes muchos candidatos? Puede filtrar en este panel lateral por campos específicos relacionados con la educación, la experiencia, etc.'
        },
        {
          title: 'Vista Previa del currículum',
          text: 'No es necesario acceder a la página del candidato uno por uno. Haga click aquí para ver un resumen de la información para facilitar su evaluación.'
        },
        {
          title: 'Detalles del candidato',
          text: 'Si la vista previa no fue suficiente, puede acceder a toda la información de este candidato haciendo click aquí para ver su currículum completo y su historial.'
        },
        {
          title: 'Mover al Candidato',
          text: 'Si has decidido mover un candidato de etapa, puedes hacerlo aquí.'
        }
      ]
    },
    companySettings: {
      finishOnboardingButton:
        'Finalice el recorrido por el registro de vacantes.',
      content: [
        {
          title: 'Configuración de la empresa',
          text: 'Configura tu página de carreras aquí. Esta página se utilizará para mostrar tus vacantes activas.'
        },
        {
          title: 'Datos principales',
          text: 'Empiece agregando los datos principales. No se preocupe, sólo se mostrarán en la página de carreras el nombre de la empresa, la ubicación y la descripción.'
        },
        {
          title: 'Logotipo de la empresa',
          text: 'Agregue el logotipo de la empresa para componer el encabezado de la página. Recomendamos que tenga un tamaño mínimo de 300px de ancho y que el fondo sea transparente.'
        },
        {
          title: 'Configurar con los colores de la empresa',
          text: 'Configure el color del encabezado, el texto y los botones de la página de carreras.'
        },
        {
          title: 'Datos confidenciales',
          text: 'Elija qué información adicional desea recopilar de los candidatos al momento de postularse en sus vacantes.'
        }
      ]
    },
    companyTeam: {
      finishOnboardingButton:
        'Finalizar recorrido por la pantalla de administración del equipo',
      content: [
        {
          title: 'El equipo de tu empresa',
          text: 'Aquí podrás administrar a los usuarios de tu empresa, establecer permisos'
        },
        {
          title: 'Usuarios',
          text: 'Los usuarios pueden tener acceso de administradores, gerentes y reclutadores.<br />Solo los administradores pueden editar las configuraciones de la empresa, editar los flujos de contratación y administrar a los usuarios.'
        },
        {
          title: 'Status del usuario',
          text: 'Un usuario puede estar bloqueado o activado y esta barra indicará el estado del mismo. Si se encuentra verde, el usuario está activo, si se encuentra rojo, el usuario está bloqueado y no podrá acceder a la plataforma.'
        },
        {
          title: 'Bloquear o activar usuarios',
          text: 'Para bloquear o reactivar usuarios, simplemente haga click aquí.'
        },
        {
          title: 'Editar usuario',
          text: 'Para editar un usuario, incluyendo el cambio de contraseña y permisos, haga click en editar.'
        },
        {
          title: 'Eliminar usuario',
          text: 'Para eliminar un usuario, haga click en la papelera.'
        },
        {
          title: 'Registrar un nuevo usuario',
          text: 'Para registrar un nuevo usuario, haga clic en este botón y complete el formulario.'
        }
      ]
    },
    jobsEdit: {
      finishOnboardingButton:
        'Finalice el recorrido por el registro de vacantes.',
      content: [
        {
          title: 'Registro/Edición de Vacantes',
          text: 'Configure la información de la vacante por aquí. Agregue los datos principales para empezar.'
        },
        {
          title: 'Vincular a un Flujo de Contratación',
          text: 'Para que tu vacante tenga un proceso definido por etapas, crea un Flujo de Contratación en "Flujos de Contratación" que puedes encontrar en el engranaje en la esquina superior derecha de la pantalla y, posteriormente, vincúlalo a esta vacante aquí.'
        },
        {
          title: 'Preguntas personalizadas',
          text: 'Podrá crear preguntas personalizadas que aparecerán para el candidato en el momento de la inscripción. Podrás crear preguntas de selección simple, opción múltiple y texto abierto. Las preguntas de selección simple se pueden marcar como de eliminación, moviendo al candidato a la etapa de “Propuesta rechazada” automáticamente si responde incorrectamente.'
        },
        {
          title: 'Gerentes y Reclutadores',
          text: 'Arrastra a los reclutadores o gerentes disponibles para que hagan parte del equipo del proceso de contratación de esta vacante y puedan tomar decisiones y acciones durante el proceso.'
        },
        {
          title: 'Guardar',
          text: 'Guarde los cambios realizados para materializar su vacante.'
        }
      ]
    },
    workflow: {
      finishOnboardingButton:
        'Finalizar recorrido por la pantalla Flujo de Contratación',
      content: [
        {
          title: 'Flujos de Contratación',
          text: 'Aquí puedes crear y administrar los flujos de contratación para cada vacante que tengan. Esto significa que sabrá exactamente por cuáles etapas sus candidatos pasarán hasta que sean contratados.'
        },
        {
          title: 'Editar/Ver el flujo de contratación',
          text: 'Para editar o ver un flujo de contratación, simplemente haga click aquí.'
        },
        {
          title: 'Eliminar Flujo de Contratación',
          text: 'Para eliminar un flujo de contratación, haga click en la papelera. Un flujo de contratación solo se puede eliminar si no está vinculado a ninguna vacante.'
        },
        {
          title: 'Registrar un nuevo flujo de contratación',
          text: 'Para registrar un nuevo flujo de contratación, simplemente haga click en este botón!'
        }
      ],
      add: 'Agregar nuevo Flujo de Contratación',
      update: 'Actualizar el Flujo de Contratación'
    },
    workflowEdit: {
      finishOnboardingButton:
        'Finalizar recorrido por la pantalla de Flujo de Contratación',
      content: [
        {
          title: 'Flujo de Contratación',
          text: 'Aquí puedes administrar las etapas del flujo de contratación. Las etapas que sean registradas aquí estarán disponibles para que pueda mover a los candidatos durante el proceso de selección.'
        },
        {
          title: 'Etapas estándar',
          text: 'Recrud ya tiene algunas etapas estándar que no se pueden eliminar. La primera etapa es "Disponible". Cada candidato que se postula a una vacante comienza en esta etapa. Las otras etapas estándar son: Rechazado en el triaje, Aprobado en el triaje, Propuesta rechazada y Contratado.'
        },
        {
          title: 'Crear etapa',
          text: 'En Recrud puedes estructurar el proceso de selección de la forma que mejor te convenga. Para agregar una nueva etapa, solo tiene que hacer click en "Crear nueva etapa aquí".'
        },
        {
          title: 'Etapas personalizadas',
          text: 'Las etapas personalizadas se identifican con el color naranja.'
        },
        {
          title: 'Eliminar etapa personalizada',
          text: 'Las etapas personalizadas podrán eliminarse si el flujo de contratación no está vinculado a una vacante.'
        },
        {
          title: 'Enviar e-mail automático',
          text: 'Habilite esta función si desea que el candidato reciba el e-mail configurado tan pronto se mueva de etapa.'
        },
        {
          title: 'Contenido del e-mail automático',
          text: 'El contenido del e-mail automático se puede cambiar haciendo clic en este botón. Puedes editarlo como quieras.'
        }
      ]
    }
  },
  jobCard: {
    candidates: 'Candidatos',
    createNewVacancy: 'Crear nueva vacante',
    edit: 'Editar',
    move: 'Mover',
    duplicate: 'Duplicar',
    share: 'Compartir',
    team: 'Equipo',
    noteam: 'Ningún equipo seleccionado',
    managers: 'Gerente',
    recruiter: 'Reclutador',
    toggleJob: 'Pausar/Reanudar vacante',
    overdueJob: 'Vacante atrasada',
    closedJob: 'Vacante cerrada job',
    stopJob: 'Pausa',
    startJob: 'Empezar'
  },
  teamManagement: {
    title: 'Administrar Equipo',
    text: 'Crea y edita usuarios para tu empresa',
    blockModal: {
      title: 'Confirmación',
      description: 'Realmente desea <strong>bloquear</strong> al usuario ',
      undo: 'Cancelar',
      confirm: 'Si, ¡bloquear!',
      success: '¡Usuario bloqueado exitosamente!'
    },
    unblockModal: {
      title: 'Confirmación',
      description: 'Realmente desea <strong>desbloquear</strong> al usuario ',
      undo: 'Cancelar',
      confirm: 'Si, ¡desbloquear!',
      success: '¡Usuario desbloqueado exitosamente!'
    },
    deleteModal: {
      title: 'Confirmación',
      description: 'Realmente desea <strong>eliminar</strong> al usuario ',
      undo: 'Cancelar',
      confirm: 'Si, ¡eliminar!',
      success: '¡Usuario eliminado exitosamente!'
    },
    editModal: {
      success: '¡Usuario actualizado exitosamente!'
    },
    addModal: {
      success: '¡Usuario creado exitosamente!'
    }
  },
  teamCard: {
    createNewUser: 'Crear nuevo usuario',
    edit: 'Editar',
    block: 'Bloquear',
    reactivate: 'Reactivar',
    delete: 'Eliminar',
    profileOptions: {
      admin: 'Administrador',
      manager: 'Gerente',
      recruiter: 'Reclutador'
    },
    modalWorkspacesTitle: 'Workspaces vinculados',
    modalWorkspacesSubtitle:
      'Estos son los workspaces vinculados a este usuario.'
  },
  userModal: {
    edit: {
      title: 'Editar Usuario',
      confirm: '¡Editar Usuario!'
    },
    add: {
      title: 'Agregar Usuario',
      confirm: '¡Crear Usuario!'
    },
    firstname: 'Nombre',
    lastname: 'Apellido',
    email: 'E-mail',
    password: 'Contraseña',
    profile: 'Perfil',
    profileOptions: {
      admin: 'Administrador',
      manager: 'Gerente',
      recruiter: 'Reclutador'
    },
    emailEdit: 'Para cambiar su e-mail, acceda a la edición del perfil o',
    clickHere: 'haga click aqui'
  },
  hiringStages: {
    text: 'Haga click en una etapa para ver los candidatos que se encuentran en ella.'
  },
  jobHeader: {
    firstOption: 'Vacantes atrasadas',
    secondOption: 'Activas',
    thirdOption: 'Paradas',
    fourthOption: 'Todas',
    searchLabel: 'Filtrar por workspaces',
    selectLabel: 'Filtre por'
  },
  candidateHeader: {
    lugarh: {
      title: 'Base general de candidatos',
      subtitle: 'Esta es la base de datos general de CV de tu empresa',
      filter: 'Filtrar',
      addCandidate: 'Agregar candidato',
      export: 'EXPORTAR',
      applyCandidates: 'SOLICITAR VACANTE',
      candidate: {
        apply: 'SOLICITAR VACANTE',
        detail: 'DETALLES',
        preview: 'AVANCE'
      },
      candidateListHeaders: {
        name: 'NOMBRE',
        resumes: 'CURRÍCULUMS',
        apply: 'SOLICITAR VACANTE'
      }
    },
    title:
      "Mostrando candidatos para '{{opportunity}}' en la etapa '{{stage}}'",
    databaseTitle: 'Base de Datos de Candidatos',
    orderBy: 'Ordenar por',
    moveSelected: 'Mover seleccionados para la etapa',
    orderByOptions: {
      created_at: 'Fecha de Inclusión',
      name: 'Nombre del candidato',
      firstname: 'Nombre del candidato',
      review: 'Nota Media',
      applicationDate: 'Fecha de Postulación'
    }
  },
  candidateList: {
    select: 'Mover para la etapa',
    review: 'Nota Media',
    preview: 'Vista Previa',
    detail: 'Detalle',
    apply: 'Postularse para la vacante',
    add: 'Agregar',
    addCandidate: 'Agregar Candidato'
  },
  select: {
    acquisitionChannel: {
      0: 'Seleccione'
    },
    vacancyAllocation: {
      0: 'Seleccione',
      1: 'Híbrido',
      2: 'En persona',
      3: 'Remoto',
      4: 'Sin especificar'
    },
    education: {
      0: 'Escuela Secundaria Incompleta',
      1: 'Escuela Intermedia',
      2: 'Escuela Secundaria Incompleta',
      3: 'Escuela Secundaria',
      4: 'De licenciatura',
      5: 'Postgrado',
      6: 'Maestría',
      7: 'Doctorado'
    },
    language: {
      0: 'Básico',
      1: 'Avanzado',
      2: 'Fluido',
      3: 'Nativo'
    }
  },
  content: {
    whatsrecrud: {
      title: '¿Qué es Recrud?',
      subtitle: 'Lorem ipsum'
    },
    privacypolicy: {
      title: 'Políticas de Privacidad',
      subtitle: 'Lorem ipsum'
    },
    terms: {
      title: 'Términos',
      subtitle: 'Lorem ipsum'
    }
  },
  candidatePreview: {
    about: 'Sobre',
    education: 'Graduación',
    experience: 'Experiencia',
    languages: 'Idiomas',
    closePreview: 'Cerrar Vista Previa',
    educationIn: 'en',
    current: 'actual',
    basic: 'Básico',
    intermediate: 'Intermediario',
    fluent: 'Fluido',
    native: 'Nativo',
    single: 'Soltero',
    married: 'Casado',
    divorced: 'Divorciado',
    widowed: 'Viudo',
    other: 'Otro',
    male: 'Masculino',
    female: 'Femenino'
  },
  languageLevelOptions: {
    basic: 'Básico',
    intermediate: 'Intermediario',
    fluent: 'Avanzado',
    native: 'Nativo'
  },
  candidateFilter: {
    modalTitle: 'Filtrar Candidatos',
    getDataError:
      'Hubo un problema al obtener algunas informaciones. Por favor, intenta actualizar la página.',
    genderOptions: {
      other: 'Otro',
      male: 'Masculino',
      female: 'Femenino'
    },
    civilStatusOptions: {
      single: 'Soltero',
      married: 'Casado',
      divorced: 'Divorciado',
      widowed: 'Viudo',
      other: 'Otro'
    },
    availableToMoveOptions: {
      true: 'Si',
      false: 'No'
    },
    languageLevelOptions: {
      basic: 'Básico',
      intermediate: 'Intermediario',
      fluent: 'Avanzado',
      native: 'Nativo'
    },
    educationLevelOptions: {
      incomplete_middleschool: 'Secundária incompleta',
      middleschool: 'Secundária Completa',
      incomplete_highschool: 'Preparatoria Incompleta',
      highschool: 'Preparatoria completa',
      undergraduate: 'Licenciatura',
      postgraduate: 'Postgrado',
      masters: 'Maestría',
      doctorate: 'Doctorado'
    },
    buttons: {
      filter: 'Filtrar',
      filterCandidate: 'Filtrar candidatos',
      applyFilters: 'Aplicar filtros'
    },
    general: {
      title: 'General',
      clearFilters: 'Limpiar filtros',
      location: 'Localización',
      age: {
        min: 'Edad mín',
        max: 'Edad max'
      },
      gender: 'Género',
      civilStatus: 'Estado Civil',
      salary: {
        min: 'Salario mín',
        max: 'Salario max'
      },
      availableToMove: '¿Disponible para mudanza?',
      workspacesMltiselect: 'Espacios de trabajo'
    },
    education: {
      title: 'Educación',
      name: 'Institucion educativa',
      course: 'Curso',
      level: 'Nivel'
    },
    experience: {
      title: 'Experiencia',
      company: 'Empresa',
      position: 'Posición',
      timeInYears: 'Tiempo de trabajo en años'
    },
    language: {
      title: 'Idioma',
      name: 'Idioma',
      level: 'Nivel'
    }
  },
  aboutContainer: {
    goBack: 'Volver',
    about: 'Sobre mi',
    education: 'Educación',
    birthdate: 'Fecha de nacimiento',
    experience: 'Experiencia Profesional',
    otherInfo: 'Otras Informaciones',
    languages: 'Idiomas',
    certificates: 'Certificados y Logros',
    certificateHours: '{{hours}} horas',
    personalInfo: 'Información Personal',
    yes: 'Si',
    not: 'No',
    email: 'E-mail',
    phone: 'Teléfono',
    localization: 'Localización',
    maritalStatus: 'Estado Civil',
    gender: 'Género',
    salaryExpectation: 'Pretensión Salarial',
    possibilityChange: 'Posibilidad de Mudanza',
    attachedFiles: 'Archivos adjuntos'
  },
  workflowCard: {
    create: 'Crear nuevo Flujo de Contratación',
    edit: 'Editar',
    view: 'Visualizar',
    noWorkspaces: 'No hay Workspaces',
    tooltip:
      'No puede eliminar este flujo de trabajo porque hay vacantes vinculadas a él.',
    modalWorkspacesTitle: 'Workspaces vinculados',
    modalWorkspacesSubtitle: 'Estos son los Workspaces vinculados a este flujo'
  },
  jobOpportunity: {
    addJobTitle: 'Agregar Vacante',
    addJobText:
      'Complete el formulario a continuación para agregar una vacante nueva',
    editJobTitle: 'Editar Vacante',
    editJobText: 'Editar vacante de ',
    name: 'Nombre de la vacante',
    roleFamily: 'Trabajos relacionados',
    roleFamilyPlaceholder:
      'Trabajos relacionados (comience a escribir para ver la lista de roles relacionados disponibles)',
    localization: 'Localización de la Vacante',
    amount: 'Cantidad de Vacantes',
    deadline: 'Plazo de finalización',
    vacancy_allocation: 'Asignación de vacantes',
    budget: 'Presupuesto de la Vacante',
    tags: 'Etiquetas de las vacantes',
    description: 'Descripción de la vacante',
    applyJobOpportunity: 'Postularse a la vacante',
    applyCandidate: '¡Postular candidato!',
    jobNameLabel: 'Nombre de la Vacante',
    applyToStep: 'Postular en la etapa',
    success: '¡Candidato postulado con éxito a la vacante!',
    chooseOne: 'Elija una opción',
    noJobFound: 'No se encontraron vacantes'
  },
  recruiterNotes: {
    newNote: 'Escriba detalles adicionales aquí...',
    notes: 'Anotaciones',
    note: 'anotación',
    new: 'nueva',
    by: 'por {{name}}',
    review: 'Califica a este candidato para la vacante {{jobName}}',
    notAllowed:
      'Só es posible insertar una nota sobre este candidato en el workspace en el que fue aplicado.'
  },
  historyContainer: {
    title: 'Historial',
    description: 'de postulaciones del candidato',
    appliedForJob: 'se postuló para la vacante',
    onDate: 'en {{date}}',
    onWorkspace: 'en el workspace'
  },
  recruiterAnswers: {
    title: 'Respuestas a las preguntas personalizadas de la vacante'
  },
  job: {
    banner: {
      title: 'Registrar Vacante',
      text: ''
    },
    bannerEdit: {
      title: 'Editar vacante',
      text: 'Editar Vacante de '
    },
    updatedMessage: '¡Actualizado exitosamente!',
    form: {
      name: 'Nombre de la Vacante',
      location: 'Localización de la Vacante',
      deadline: 'Plazo de finalización',
      vacancy_allocation: 'Asignación de vacantes',
      tags: 'Etiquetas de la vacante',
      roleFamilyPlaceholder:
        'Trabajos relacionados (comience a escribir para ver la lista de roles relacionados disponibles)',
      amount: 'Cantidad de Vacantes',
      budget: 'Presupuesto de las vacantes',
      status: '¿Activar vacante?',
      description: 'Descripción de la vacante'
    },
    linkWorkflow: 'Vincular a un Flujo de Contratación',
    questions: 'Preguntas personalizadas para los candidatos',
    questionsInfo:
      'Ya hay candidatos postulados a esta vacante, por este motivo las preguntas no se pueden cambiar.',
    recruiters: 'Reclutadores disponíbles',
    recruitersTeam: 'Reclutadores del Equipo',
    managers: 'Gerentes disponibles',
    managersTeam: 'Gerentes del Equipo',
    save: '¡Guardar Cambios!',
    creationFailed:
      'Error al crear la vacante, complete los campos obligatorios y vuelva a intentarlo',
    creationSuccess: 'Creado exitosamente',
    missingWorkflow:
      'Error al crear la vacante, seleccione un Flujo de contratación y vuelva a intentarlo',
    updateStatusfalse: 'La vacante se pausó correctamente!',
    updateStatustrue: 'La vacante se reanudó exitosamente!',
    modalTitle: 'Confirmación',
    modalTextfalse: 'Quiere pausar la vacante ',
    modalTexttrue: 'Quiere reanudar la vacante',
    yes: 'Si',
    no: 'No',
    tagError: 'Ha alcanzado el límite máximo de 34 caracteres.'
  },
  jobShare: {
    shareManually: 'Compartir manualmente',
    shareWith: 'Compartir en{{socialMedia}}',
    message:
      '<p><copy>Haga click aqui</copy> para copiar el enlace para compartir. Después de eso, pegalo donde quieras compartirlo.</p>',
    url: 'Ou use esse endereço: ',
    onCopyingSuccessfully: 'Enlace copiado correctamente.'
  },
  workflowStepCard: {
    create: 'Crear nueva etapa aquí'
  },
  hiringWorkflow: {
    bannerTitle: 'Flujos de Contratación',
    bannerText: 'Administre los Flujos de Contratación de su empresa',
    addNewStage: 'Añadir nueva etapa',
    stageName: 'Nombre de la etapa',
    stageDescription: 'Descripción de la etapa',
    sendEmailAutomatically: '¿Enviar e-mail automáticamente?',
    stageEmailContent: 'Contenido del e-mail',
    createHiringStage: 'Crear etapa de contratación',
    emailSubject: 'Asunto del e-mail'
  },
  listWorkflowsContainer: {
    modalTitle: 'Confirmación',
    modalText:
      'Realmente quiere <strong>eliminar</strong> el Flujo de Contratación',
    successDeleteMessage: '¡El Flujo de Contratación se eliminó exitosamente!',
    successCreateMessage: '¡Flujo de Contratación creado exitosamente!',
    successUpdateMessage: '¡Flujo de Contratación actualizado exitosamente!',
    successHiringStageCreateMessage: '¡Etapa creada exitosamente!',
    successDuplicateMessage: '¡Flujo de Contratación duplicado exitosamente!',
    yes: 'Si',
    no: 'No',
    lockedDeletion:
      'No es posible eliminar el Flujo de contratación ya que está asociado a una vacante',
    copy: 'copia'
  },
  hiringWorkflowEdit: {
    bannerTitle: 'Flujo de Contratación para',
    bannerText: 'Editar el Flujo de Contratación',
    firstP:
      'Registra aquí el flujo del proceso de selección para tu vacante. Puede asociar este flujo de trabajo con una vacante y los pasos se pueden utilizar para filtrar y organizar candidatos. Recuerda, con el flujo de contratación sabrás en qué punto de tu proceso se encuentra cada candidato. Si tiene alguna duda sobre cómo registrarlo ',
    button: 'haga click aquí'
  },
  editWorkdflowContainer: {
    blocked: 'Paso predeterminado, no se puede eliminar',
    editEmail: 'Editar e-mail',
    stage: 'ETAPA DE FLUJO DE CONTRATACIÓN',
    sendEmail: '¿ENVIAR E-MAIL AUTOMÁTICO?',
    emailContent: 'CONTENIDO DEL E-MAIL',
    action: 'ACCIÓN',
    yes: 'Si',
    no: 'No',
    confirm: 'Confirmación',
    deleteContent: 'Realmente quiere <strong>eliminar</strong> la etapa ',
    successDeleteMessage: '¡Etapa eliminada correctamente!',
    editEmailModalTitle: 'Editar e-mail de la etapa',
    editEmailModalSubTitle:
      'Este e-mail se enviará cuando el candidato se agregue a la',
    editEmailModalSubject: 'Asunto',
    editEmailModalContent: 'Cuerpo del email',
    createStageTitle: '+ Incluir nueva Etapa',
    createStageName: 'Nombre de la etapa',
    createStageDescription: 'Descripción de la etapa',
    createStageSendEmail: '¿Enviar e-mail automáticamente?',
    createStageEmailContent: '¡Contenido del e-mail',
    createStageButton: '¡Crear etapa de contratación!',
    successHiringStageCreateMessage: '¡Etapa creada exitosamente!',
    successUpdateAutoSend:
      '¡Envío automático del e-mail actualizado exitosamente!',
    successUpdateEmailContent:
      '¡Contenido del e-mail actualizado existosamente!',
    save: 'Guardar Cambios',
    remove: 'Eliminar',
    emailSubject: 'Asunto del e-mail',
    headerTitle: 'Flujo de contratación',
    emailContentRequired:
      'La función solo se activará una vez que se haya añadido el cuerpo del correo electrónico.'
  },
  notFound: {
    button: 'Volver',
    content:
      'Parece que tomaste un camino equivocado y encontraste un agujero negro en la web que absorbió la página que estabas buscando ¡Pero no te preocupes porque puedes volver!',
    title: 'UPS!!'
  },
  customQuestions: {
    question: 'Pregunta',
    eliminate: 'de Eliminación?',
    unique: 'Respuesta única',
    mult: 'Opción múltiple',
    opened: 'Texto libre',
    answers: 'Opciones',
    rightAnswer: 'Opción correcta',
    required: '¡Campo obligatorio!',
    moveUp: 'Mover para arriba',
    moveDown: 'Mover para abajo',
    duplicate: 'Duplicar la pregunta',
    delete: 'Eliminar pregunta'
  },
  dashboard: {
    hello: 'Hola',
    seeCompanyData: 'Vea los datos del proceso de selección de la empresa:',
    filterByRecruiter: 'Filtrar por Reclutador',
    filterByWorkflow: 'Filtrar por Flujo de Contratación',
    chart01: 'Vacantes abiertas',
    chart02: 'Vacantes concluidas',
    chart1: 'Tiempo promedio de conclusión de vacantes',
    chart2: 'Tiempo promedio para concluir la vacante por reclutador.',
    chart3: 'Fuente de adquisición de los candidatos',
    chart4: 'Postulaciones de candidatos por día',
    chart5: 'Candidatos por grupo de edad',
    chart6: 'Candidatos por género',
    chart7: 'Candidatos por nivel educativo',
    chart8: 'Candidatos por estado civil',
    chart9: 'Candidatos por estado',
    chart10: 'Vacantes concluidas dentro y fuera del plazo por el reclutador',
    chart11: 'Candidatos por fases en los últimos 6 meses.',
    inTime: 'Dentro del plazo',
    late: 'Atrasadas',
    male: 'Masculino',
    female: 'Femenino',
    other: 'Otros',
    errorChart: 'No hay suficientes datos para generar el gráfico.',
    errorCompanyData: 'No se pudieron cargar los datos de la empresa',
    'Solteiro(a)': 'Solteiro',
    'Divorciado(a)': 'Divorciado',
    'Viuvo(a)': 'Viuvo',
    Outro: 'Otro',
    Available: 'Disponible',
    'Rejected in Triage': 'Rechazado en Triage',
    'Approved in Triage': 'Aprobado en Triage',
    'Denied Offer': 'Oferta denegada',
    'Rejected during the process': 'Rechazado durante el proceso',
    'Solicitar documentos': 'Solicitar documentos',
    'Coleta de documentos': 'Colección de documentos',
    Hired: 'Contratado',
    errorDataChart: 'No se pudieron recuperar los datos de algunos gráficos',
    errorExpiredSession: 'La sesión ha expirado'
  },
  changePassword: {
    changePassword: 'Restablecer contraseña',
    password: 'Contraseña',
    passwordConfirmation: 'Confirmar contraseña',
    changePasswordFail:
      'Restablecimiento fallido. Su enlace de restablecimiento de contraseña ha caducado o no es válido',
    changePasswordSuccess: 'Contraseña restablecida correctamente'
  },
  defaultWorkflowStages: {
    defaultStage: 'Esta etapa es estándar y no se puede eliminar.',
    'Approved in Triage': 'Aprobado en el triaje',
    Available: 'Disponible',
    'Failed in the selection process': 'Rechazado en el proceso de selección',
    'Failed in the selection processD':
      'El candidato fue considerado como incompatible después de la selección como entrevistas, pruebas, etc.',
    'Denied Offer': 'Oferta rechazada',
    Hired: 'Contratado',
    'Rejected in Triage': 'Rechazado en el triaje',
    'Approved in TriageD':
      'El candidato fue aprobado en el proceso de selección.',
    AvailableD:
      'El candidato está interesado en participar en el proceso de selección.',
    'Denied OfferD':
      'El candidato rechazó la propuesta o se retiró del proceso.',
    HiredD: 'El candidato fue contratado.',
    'Rejected in TriageD': 'El candidato fue eliminado del proceso selectivo.',
    'Rejected during the process': 'Rechazado durante el proceso',
    'Rejected during the processD':
      'El candidato fue rechazado durante el proceso de selección.',
    'Approved in process': 'Aprobado en el proceso',
    'Approved in processD':
      'El candidato fue aprobado no proceso de selección.',
    'Solicitar documentos': 'Solicitar documentos',
    'Solicitar documentosD':
      'El candidato recibió el link para subir los documentos solicitados.'
  },
  codeConfirmationModal: {
    title: 'Confirmar nuevo e-mail',
    subtitle: 'Ingrese el código que recibió en su nuevo e-mail',
    label: 'Código',
    buttonText: 'Confirmar código'
  },
  passwordConfirmationModal: {
    title: 'Cambio de e-mail',
    subtitle: 'Para cambiar su e-mail, por favor confirme su contraseña',
    label: 'Contraseña',
    buttonText: 'Confirmar contraseña'
  },
  candidatesListContainer: {
    notfound: 'No se encontraron registros.',
    select: 'Seleccionar perfil de contratación'
  },
  jobsContainer: {
    notfound: 'No se encontraron registros.'
  },
  searchBox: {
    loading: 'Cargando...',
    notFound: 'No se encontraron resultados'
  },
  subscriptionSettings: {
    headerSettigns: {
      headerTitle: 'Configuración de la Suscripción',
      headerText: 'Actualizar o cancelar la suscripción'
    },
    nextChargeTitle: 'Próxima carga',
    nextChargeDescriptionDate: 'Su próxima fecha de facturación será el ',
    nextChargeDescriptionValue: 'en la cantidad de ',
    paymentMethodTitle: 'Método de Pago',
    paymentMethodType: 'Tarjeta de Crédito',
    paymentMethodCardNumber: 'que termina con el número',
    paymentMethodCardNumberExpiresAt: 'Expira en:',
    warningWithoutPaymentMethod: 'No hay ningún método de pago registrado.',
    descriptionWithoutPaymentMethod:
      'Puede añadir un método de pago al pagar la siguiente factura y habilitarlo para futuros pagos.',
    invoices: {
      invoicesTitle: 'Facturas',
      expiresAt: 'Fecha de vencimiento',
      value: 'Valor',
      status: 'Situación',
      link: 'Ver',
      statusPaid: 'Pagado',
      statusPending: 'Pendiente',
      statusExpired: 'Expirada',
      statusCanceled: 'Cancelada',
      statusRefunded: 'Reembolsada'
    },
    unsubscribeEmail: {
      subject: 'Solicitud%20de%20anulaci%C3%B3n',
      body: 'Hola%2C%0A%0AMe%20gustar%C3%ADa%20solicitar%20la%20anulaci%C3%B3n%20de%20las%20pr%C3%B3ximas%20facturas%20de%20la%20plataforma%20Recrud%20Reclutamiento%20y%20Selecci%C3%B3n.',
      buttonLabel: 'Cancelar la suscripción'
    }
  },
  candidateInfo: {
    years: 'años'
  },
  badgeList: {
    initialScale: 'No adherente',
    finalScale: 'Totalmente adherente',
    candidateAssessment:
      'La calificación final en estrellas se calcula como el promedio de las evaluaciones enviadas por todos los usuarios, que van desde no adherente hasta excelente. Esta calificación refleja la evaluación general del solicitante.'
  },
  recruiterList: {
    managers: 'Gerentes',
    recruiters: 'Reclutadores'
  },
  workspaces: {
    lugarh: {
      title: 'Espacios de trabajo',
      subtitle: 'Agregar, editar o eliminar espacios de trabajo de tu empresa.',
      modalListUsersTitle: 'Usuarios vinculados',
      modalListUsersSubtitle:
        'Vea la lista de personas vinculadas a este workspace',
      button: {
        create: 'Crear nuevo espacio de trabajo'
      },
      table: {
        actions: 'Acciones',
        soon: 'Próximamente',
        noCnpj: 'Sin CNPJ registrado',
        head: {
          workspaces: 'Workspaces',
          users: 'Usuarios',
          cnpj: 'CNPJ'
        },
        buttons: {
          edit: 'Editar',
          exclude: 'Eliminar'
        }
      }
    }
  }
}

export default es
