import React, { RefObject, useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import {
  ChangeEnvironmentModal,
  ModalType
} from 'components/Modals/lugarh/ChangeEnvironment/ChangeEnvironmentModal'
import { PermissionAgent } from 'components/PermissionAgent/PermissionAgent'
import { ADMIN_PERMISSIONS } from 'contants'
import { useTheme } from 'context/ThemeContext/ThemeContext'
import { useOutsideAlerter } from 'hooks/useOutsideAlerter'
import i18n from 'i18n'

import './HeaderSettings.scss'
import { RoutePaths } from 'router/Router.config'

import { Avatar } from '../Avatar/Avatar'

type Props = {
  initialState: boolean
  wrapperRef: RefObject<HTMLElement>
  onChange: (value: boolean) => void
  role: string
  fullName: string
  logoff: () => void
}

export const HeaderSettings: React.FC<Props> = ({
  initialState,
  onChange,
  wrapperRef,
  role,
  fullName,
  logoff
}) => {
  const [open, setOpen] = useState(initialState)
  const [openModal, setOpenModal] = useState<{
    open: boolean
    type: ModalType
  }>({ open: false, type: 'company' })
  const { t } = useTranslation('headerSettings')
  const { theme } = useTheme()

  useEffect(() => {
    setOpen(initialState)
  }, [initialState])

  useOutsideAlerter(wrapperRef, (target: HTMLElement) => {
    const isNavMobile =
      target?.id === 'navigation-widget-mobile' ||
      (target.parentElement as HTMLElement)?.id === 'navigation-widget-mobile'

    if (openModal.open) {
      return
    }

    if (!isNavMobile) {
      setOpen(false)
      onChange(false)
    }
  })

  const onCloseModal = () => {
    setOpenModal({ open: false, type: 'company' })
    setOpenModal({ open: false, type: 'workspace' })
  }
  return (
    <div className={`dropdown-box header-dropdown ${open ? 'active' : ''}`}>
      <div className='dropdown-navigation header-settings-dropdown'>
        <div className='dropdown-navigation-header'>
          <Link
            to='/my-profile'
            onClick={() => {
              setOpen(false)
              onChange(false)
            }}
          >
            <p className='user-status-title'>
              <strong>
                {t('hello')}, {fullName}!
              </strong>
            </p>
          </Link>
          <p className='user-status-text small'>{role}</p>
        </div>

        <p className='dropdown-navigation-category'>{t('myProfile')}</p>

        <Link
          className='dropdown-navigation-link'
          to='/my-profile'
          onClick={() => {
            setOpen(false)
            onChange(false)
          }}
        >
          {t('profileEdit')}
        </Link>

        <PermissionAgent
          module='company'
          actions={['view']}
          onFail='hidden'
          Component={({ hidden }) =>
            hidden ? null : (
              <>
                <p className='dropdown-navigation-category'>{t('company')}</p>
                {/* <Link
                  className='dropdown-navigation-link'
                  to='/company-settings'
                  onClick={() => {
                    setOpen(false)
                    onChange(false)
                  }}
                >
                  {t('companySettings')}
                </Link> */}
              </>
            )
          }
        />

        <PermissionAgent
          module='subscription-rocketmat'
          actions={['view']}
          onFail='hidden'
          Component={({ hidden }) =>
            hidden ? null : (
              <>
                <Link
                  to='/subscription-settings'
                  className='dropdown-navigation-link'
                  onClick={() => {
                    setOpen(false)
                    onChange(false)
                  }}
                >
                  {t('subscriptionSettings')}
                </Link>
              </>
            )
          }
        />

        {/* <PermissionAgent
          module='hiring-workflow'
          actions={['view']}
          onFail='hidden'
          Component={({ hidden }) =>
            hidden ? null : (
              <div
                className='dropdown-navigation-link'
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setOpenModal({ open: true, type: 'company' })
                  // setOpen(false)
                  // onChange(false)
                }}
              >
                {t('changeCompany')}
              </div>
            )
          }
        /> */}
        {/* <PermissionAgent
          module='hiring-workflow'
          actions={['view']}
          onFail='hidden'
          Component={({ hidden }) =>
            hidden ? null : (
              <div
                className='dropdown-navigation-link'
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setOpenModal({ open: true, type: 'workspace' })
                  // setOpen(false)
                }}
              >
                {t('changeWorkspace')}
              </div>
            )
          }
        /> */}
        <div
          className='dropdown-navigation-link'
          style={{ cursor: 'pointer' }}
          onClick={() => setOpenModal({ open: true, type: 'workspace' })}
        >
          {t('changeWorkspace')}
        </div>
        <PermissionAgent
          module='hiring-workflow'
          actions={['view']}
          onFail='hidden'
          Component={({ hidden }) =>
            hidden ? null : (
              <Link
                to={RoutePaths.workspaces}
                className='dropdown-navigation-link'
                onClick={() => {
                  setOpen(false)
                  onChange(false)
                }}
              >
                {t('manageWorkspace')}
              </Link>
            )
          }
        />
        <ChangeEnvironmentModal
          open={openModal.open}
          onClose={onCloseModal}
          customClass='ws-modal'
          variant={openModal.type}
        />
        <PermissionAgent
          module='hiring-workflow'
          actions={['view']}
          onFail='hidden'
          Component={({ hidden }) =>
            hidden ? null : (
              <Link
                to='/hiring-workflow'
                className='dropdown-navigation-link'
                onClick={() => {
                  setOpen(false)
                  onChange(false)
                }}
              >
                {t('hiringWorkflows')}
              </Link>
            )
          }
        />

        <PermissionAgent
          module='user'
          actions={ADMIN_PERMISSIONS}
          onFail='hidden'
          Component={({ hidden }) =>
            hidden ? null : (
              <Link
                to='/team-management'
                className='dropdown-navigation-link'
                onClick={() => {
                  setOpen(false)
                  onChange(false)
                }}
              >
                {t('manageTeam')}
              </Link>
            )
          }
        />

        <p className='dropdown-navigation-category'>{t('language')}</p>

        <a
          className='dropdown-navigation-link language-link'
          href='#'
          onClick={() => {
            i18n.changeLanguage('en-US')
            setOpen(false)
            onChange(false)
          }}
        >
          {t('english')}
        </a>

        <a
          className='dropdown-navigation-link language-link'
          href='#'
          onClick={() => {
            i18n.changeLanguage('pt-BR')
            setOpen(false)
            onChange(false)
          }}
        >
          {t('portuguese')}
        </a>

        <a
          className='dropdown-navigation-link language-link'
          href='#'
          onClick={() => {
            i18n.changeLanguage('es')
            setOpen(false)
            onChange(false)
          }}
        >
          {t('spanish')}
        </a>

        <p className='dropdown-navigation-category'>{t('session')}</p>

        <a className='dropdown-navigation-link logout-link' onClick={logoff}>
          {t('leave')}
        </a>
      </div>
    </div>
  )
}
