import React, { useState } from 'react'

import InfiniteScroll from 'react-infinite-scroll-component'
import { useNavigate } from 'react-router-dom'

import { AddCandidateCard } from 'components/AddCandidateCard/AddCandidateCard'
import { ApplyJobModal } from 'components/ApplyJobModal/ApplyJobModal'
import {
  CandidateCard,
  CandidateCardRef
} from 'components/CandidateCard/CandidateCard'
import { CandidateList } from 'components/CandidateList/CandidateList'
import { CandidatePreview } from 'components/CandidatePreview/CandidatePreview'
import { Loading } from 'components/Loading/Loading'
import { useWorkspacePermission } from 'hooks/useWorkspacePermission'

import { timestampToDate } from '../../utils/date'

type Props = {
  getRefs: (value: CandidateCardRef) => void
  candidates: Record<string, Candidate>
  hasMore: boolean
  viewType: 'grid' | 'list'
  fetchCandidates: () => void
}

export const CandidatesDatabaseContainer: React.FC<Props> = ({
  getRefs,
  candidates,
  hasMore,
  fetchCandidates,
  viewType
}) => {
  const { dontShowOnGeralWorkspace } = useWorkspacePermission()
  const navigate = useNavigate()
  const [candidatePreviewModal, setCandidatePreviewModal] = useState(false)
  const [selectedCandidate, setSelectedCandidate] = useState<Candidate>()
  const [applyJobModal, setApplyJobModal] = useState(false)

  return (
    <>
      <ApplyJobModal
        open={applyJobModal}
        candidate={selectedCandidate?.uuid || ''}
        onClose={() => setApplyJobModal(!applyJobModal)}
      />
      <CandidatePreview
        user={`${selectedCandidate?.firstname} ${selectedCandidate?.lastname}`}
        about={selectedCandidate?.about}
        open={candidatePreviewModal}
        education={selectedCandidate?.educationalExperiences}
        experience={selectedCandidate?.professionalExperiences}
        languages={selectedCandidate?.languages}
        infos={[
          selectedCandidate?.fullAddress ||
            selectedCandidate?.geoLocation ||
            '',
          selectedCandidate?.sex || '',
          selectedCandidate?.maritalStatus || ''
        ]}
        onClose={setCandidatePreviewModal}
      />
      <InfiniteScroll
        dataLength={Object.values(candidates).length}
        next={fetchCandidates}
        hasMore={hasMore}
        style={{
          overflow: 'hidden',
          padding: 0,
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: viewType === 'grid' ? 'row' : 'column',
          gap: 20
        }}
        loader={
          <div className='row w-100'>
            <div className='col-12 d-flex justify-content-center'>
              <Loading />
            </div>
          </div>
        }
        className='container column gap-3 mt-3'
      >
        {dontShowOnGeralWorkspace && <AddCandidateCard viewType={viewType} />}
        {Object.values(candidates).map((candidate, index) => {
          return viewType === 'grid' ? (
            <CandidateCard
              isDbCandidate
              key={candidate.uuid}
              name={`${candidate.firstname} ${candidate.lastname}`}
              date={timestampToDate(candidate.createdAt)}
              onJobApply={() => {
                setSelectedCandidate(candidate)
                setApplyJobModal(true)
              }}
              onShowPreview={() => {
                setCandidatePreviewModal(true)
                setSelectedCandidate(candidate)
              }}
              onShowDetail={() =>
                navigate(`/candidate-detail/${candidate.uuid}`, {
                  state: { candidate }
                })
              }
              getRefs={refs => index === 0 && getRefs(refs)}
            />
          ) : (
            <CandidateList
              isDbCandidate
              key={candidate?.uuid}
              name={`${candidate?.firstname} ${candidate?.lastname}`}
              date={timestampToDate(candidate?.createdAt)}
              onJobApply={() => {
                setSelectedCandidate(candidate)
                setApplyJobModal(true)
              }}
              onShowPreview={() => {
                setCandidatePreviewModal(true)
                setSelectedCandidate(candidate)
              }}
              onShowDetail={() =>
                navigate(`/candidate-detail/${candidate.uuid}`, {
                  state: { candidate }
                })
              }
            />
          )
        })}
      </InfiniteScroll>
    </>
  )
}
