import React, { useCallback, useEffect, useState } from 'react'

import { Chart, registerables } from 'chart.js'
import format from 'date-fns/format'
import sub from 'date-fns/sub'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { DashboadCard } from 'components/DashboadCard/DashboadCard'
import DashboardChart from 'components/DashboardChart/DashboardChart'
import { Loading } from 'components/Loading/Loading'
import { MultipleSelect } from 'components/Select/lugarh/MultipleSelect'
import { Select } from 'components/Select/Select'
import { useWindowSize } from 'hooks/useWindowSize'
import { useWorkspacePermission } from 'hooks/useWorkspacePermission'
import { workflow } from 'services/api'
import {
  acquisition,
  candidateAge,
  candidateByLocation,
  candidateEducationLevel,
  candidateGender,
  candidateMaritalStatus,
  lastSixMonthsCandidatesStage
} from 'services/api/candidates'
import {
  jobApplicationsLastSixMonths,
  jobApplicationsLastThirtyDays,
  jobClosedByRecruiter,
  jobClosingTime,
  jobOpen
} from 'services/api/jobs'
import { read } from 'services/api/users'
import useStore from 'store'
import * as notificationActions from 'store/notification/actions'
import './DashboardContainer.scss'

import { WorkspaceSchema } from 'services/api/workspaces/workspaces.d'

import { convertTime } from '../../utils/transformSecondsToDays'

let candidateStageChartRef: Chart
let lastThirtyDaysChartRef: Chart
let ageChartRef: Chart
let genderChartRef: Chart
let educationLevelChartRef: Chart
let maritalStatusChartRef: Chart
let provinceChartRef: Chart
let acquisitionChartRef: Chart
let finishJobChartRef: Chart
let avgFinishJobChartRef: Chart
let jobsChartRef: Chart
let finishedJobsChartRef: Chart
let closedByRecruiterChartRef: Chart

const HEXES = ['#127EFF', '#6AC425', '#D12923', '#950099', '#1DC5FF', '#00FF00']
Chart.register(...registerables)

export interface DashboardFiltersPayload {
  user?: string
  workspaces?: string
  hiring_workflow?: string
}

export function DashboardContainer(): JSX.Element {
  const { showOnGeralWorkspace } = useWorkspacePermission()
  const { t } = useTranslation('dashboard')
  const navigate = useNavigate()
  const { selectors, dispatch } = useStore()
  const fullName = selectors.auth.fullName()
  const expandedMenu = selectors.asideMenu.isExpanded()
  const [loading, setLoading] = useState(true)
  const [lastThirtyDays, setLastThirtyDays] = useState([] as Array<number>)
  const [lastSixMonths, setLastSixMonths] = useState([] as Array<number>)
  const [closingTime, setClosingTime] = useState(
    [] as Array<{ label: string; value: number }>
  )
  const [jobsOppened, setJobsOppened] = useState(
    {} as { late: number; in_time: number }
  )
  const [jobsCLosed, setJobsCLosed] = useState(
    {} as { late: number; in_time: number }
  )
  const [closedByRecruiters, setClosedByRecruiters] = useState(
    [] as Array<{ label: string; value: { in_time: number; late: number } }>
  )
  const [candidateStage, setCandidateStage] = useState(
    [] as Array<{ label: string; values: [] }>
  )
  const [workflows, setWorkflows] = useState<WorkflowSchema[]>([])
  const [workflowSelected, setWorkflowSelected] = useState<string>('')
  const [recruiterSelected, setRecruiterSelected] = useState(
    null as null | string
  )
  const [candidatesAcquisition, setCandidatesAcquisition] = useState(
    [] as Array<{ label: string; value: number }>
  )
  const [candidatesAge, setCandidatesAge] = useState(
    [] as Array<{ maxAge: number; minAge: number; value: number }>
  )
  const [candidatesGender, setCandidatesGender] = useState(
    [] as Array<{ label: string; value: number }>
  )
  const [candidatesEducationLevel, setCandidatesEducationLevel] = useState(
    [] as Array<{ label: string; value: number }>
  )
  const [candidatesMaritalStatus, setCandidatesMaritalStatus] = useState(
    [] as Array<{ label: string; value: number }>
  )
  const [candidatesByLocation, setCandidatesByLocation] = useState(
    [] as Array<{ label: string; value: number }>
  )
  const [selectedRecruiter, setSelectedRecruiter] = useState([] as any)
  const [workspacesList, setWorkspacesList] = useState<WorkspaceSchema[]>()
  const [workspacesSelected, setWorkspacesSelected] = useState<string>()

  const [width] = useWindowSize()

  const user = selectors.auth.user()
  const dataPrivacySettings = user?.company?.data_privacy_settings

  const getWorkspacesList = () => {
    const userWorkspaces = selectors.auth.user()?.workspace
    const noGeneralWorkspace = userWorkspaces?.filter(
      (workspace: WorkspaceSchema) => workspace.name !== 'Geral'
    )
    setWorkspacesList(noGeneralWorkspace)
  }

  useEffect(() => {
    setLoading(true)
    getWorkspacesList()
    Promise.allSettled([
      fetchJobs(),
      fetchLastSixMonths(),
      fetchClosedByRecruiter(),
      fetchClosingTime(),
      fetchAcquisitionChart(),
      fetchLastThirtyDays(),
      fetchCandidateAge(),
      fetchCandidateGender(),
      fetchCandidateEducationLevel(),
      fetchCandidateMaritalStatus(),
      fetchCandidateByLocation(),
      fetchWorkflows()
    ]).then(() => {
      setLoading(false)
    })

    fetchRecruitersList()
  }, [])

  useEffect(() => {
    dataPrivacySettings?.ask_marital_status && maritalStatusChart()
  }, [candidatesMaritalStatus])

  useEffect(() => {
    dataPrivacySettings?.ask_sex && genderChart()
  }, [candidatesGender])

  useEffect(() => {
    dataPrivacySettings?.ask_birthdate && ageChart()
  }, [candidatesAge])

  useEffect(() => {
    provinceChart()
  }, [candidatesByLocation])

  useEffect(() => {
    educationLevelChart()
  }, [candidatesEducationLevel])

  useEffect(() => {
    lastThirtyDaysChart()
  }, [lastThirtyDays])

  useEffect(() => {
    candidatesStageChart()
  }, [candidateStage])

  useEffect(() => {
    averageFinishJobs()
  }, [lastSixMonths])

  useEffect(() => {
    jobs()
    finishedJobs()
  }, [jobsOppened, jobsCLosed])

  useEffect(() => {
    closedByRecruiter()
  }, [closedByRecruiters])

  useEffect(() => {
    finishJob()
  }, [closingTime])

  useEffect(() => {
    acquisitionChart()
  }, [candidatesAcquisition])

  useEffect(() => {
    if (recruiterSelected !== null || workspacesSelected !== null) {
      setLoading(true)
      Promise.allSettled([
        fetchJobs(),
        fetchLastSixMonths(),
        fetchClosedByRecruiter(),
        fetchClosingTime(),
        fetchAcquisitionChart(),
        fetchLastThirtyDays(),
        fetchCandidateAge(),
        fetchCandidateGender(),
        fetchCandidateEducationLevel(),
        fetchCandidateMaritalStatus(),
        fetchCandidateByLocation(),
        fetchWorkflows()
      ]).then(() => {
        setLoading(false)
      })
    }
  }, [recruiterSelected, workspacesSelected])

  const errorMessage = (response_status: number, origin: string) => {
    if (response_status >= 200 && response_status < 300) return
    console.error(response_status, origin)
    if (response_status === 401) {
      showNotification({ message: t('errorExpiredSession'), type: 'error' })
      selectors.auth.clearUser()
      navigate('/login')
    } else {
      showNotification({ message: t('errorDataChart'), type: 'error' })
    }
  }

  const filteredPayload = (
    workflowSelected?: string
  ): DashboardFiltersPayload => {
    const requestPayload: DashboardFiltersPayload = {
      ...(recruiterSelected && { user: recruiterSelected }),
      ...(workspacesSelected &&
        workspacesSelected.length > 0 && { workspaces: workspacesSelected }),
      ...(workflowSelected && { hiring_workflow: workflowSelected })
    }
    return requestPayload
  }

  const fetchRecruitersList = async () => {
    if (selectors.auth.user()?.role === 'admin') {
      const [, data] = await read({ page: 1, per_page: 100 })
      if (data) {
        const recruiters = data?.data.filter(role => role.role === 'recruiter')
        setSelectedRecruiter(recruiters)
      } else {
        showNotification({
          message: t('errorDataChart'),
          type: 'error'
        })
      }
    }
  }

  const fetchLastThirtyDays = async () => {
    const lastThirtyDaysPayload = filteredPayload()

    const [status, data] = await jobApplicationsLastThirtyDays(
      lastThirtyDaysPayload
    )
    if (status === 200) {
      setLastThirtyDays(data?.values)
    } else {
      errorMessage(status, 'fetchLastThirtyDays')
    }
  }

  const fetchLastSixMonths = async () => {
    const lastSixMonthsPayload = filteredPayload()

    const [status, data] =
      await jobApplicationsLastSixMonths(lastSixMonthsPayload)
    if (status === 200) {
      setLastSixMonths(data?.value)
    } else {
      errorMessage(status, 'fetchLastSixMonths')
    }
  }

  const fetchClosingTime = async () => {
    const closingTimePayload = filteredPayload()

    const [status, data] = await jobClosingTime(closingTimePayload)
    if (status === 200) {
      setClosingTime(data)
    } else {
      errorMessage(status, 'fetchClosingTime')
    }
  }

  const fetchJobs = async () => {
    const jobsPayload = filteredPayload()

    const [status, data] = await jobOpen(jobsPayload)
    if (status === 200) {
      setJobsOppened(data?.open)
      setJobsCLosed(data?.closed)
    } else {
      errorMessage(status, 'fetchJobs')
    }
  }

  const fetchClosedByRecruiter = async () => {
    const closedByRecruiterPayload = filteredPayload()

    const [status, data] = await jobClosedByRecruiter(closedByRecruiterPayload)
    if (status === 200) {
      setClosedByRecruiters(data)
    } else {
      errorMessage(status, 'fetchClosedByRecruiter')
    }
  }

  const fetchLastSixMonthsCandidatesStage = async (
    workflowSelected: string
  ) => {
    if (!workflowSelected) return

    setWorkflowSelected(workflowSelected)
    const [status, data] = await lastSixMonthsCandidatesStage(
      filteredPayload(workflowSelected)
    )

    if (status === 200) {
      setCandidateStage(data)
    } else {
      errorMessage(status, 'fetchLastSixMonthsCandidatesStage')
    }
  }

  const fetchWorkflows = async () => {
    const [, data] = await workflow.read({
      page: 1,
      per_page: 1000
    })
    if (data) {
      setWorkflows(data?.data)
      fetchLastSixMonthsCandidatesStage(data?.data?.[0]?.uuid)
    } else showNotification({ message: t('errorDataChart'), type: 'error' })
  }

  const fetchAcquisitionChart = async () => {
    const acquisitionPayload = filteredPayload()

    const [status, data] = await acquisition(acquisitionPayload)
    if (status === 200) {
      setCandidatesAcquisition(data)
    } else {
      errorMessage(status, 'fetchAcquisitionChart')
    }
  }

  const fetchCandidateAge = async () => {
    const candidateAgePayload = filteredPayload()

    const [status, data] = await candidateAge(candidateAgePayload)
    if (status === 200) {
      setCandidatesAge(data)
    } else {
      errorMessage(status, 'fetchCandidateAge')
    }
  }

  const fetchCandidateGender = async () => {
    const candidateGenderPayload = filteredPayload()

    const [status, data] = await candidateGender(candidateGenderPayload)
    if (status === 200) {
      setCandidatesGender(data)
    } else {
      errorMessage(status, 'fetchCandidateGender')
    }
  }

  const fetchCandidateEducationLevel = async () => {
    const candidateEducationLevelPayload = filteredPayload()

    const [status, data] = await candidateEducationLevel(
      candidateEducationLevelPayload
    )
    if (status === 200) {
      setCandidatesEducationLevel(data)
    } else {
      errorMessage(status, 'fetchCandidateEducationLevel')
    }
  }

  const fetchCandidateMaritalStatus = async () => {
    const candidateMaritalStatusPayload = filteredPayload()

    const [status, data] = await candidateMaritalStatus(
      candidateMaritalStatusPayload
    )
    if (status === 200) {
      setCandidatesMaritalStatus(data)
    } else {
      errorMessage(status, 'fetchCandidateMaritalStatus')
    }
  }

  const fetchCandidateByLocation = async () => {
    const candidateByLocationPayload = filteredPayload()

    const [status, data] = await candidateByLocation(candidateByLocationPayload)
    if (status === 200) {
      setCandidatesByLocation(data)
    } else {
      errorMessage(status, 'fetchCandidateByLocation')
    }
  }

  const lastThirtyDaysChart = () => {
    const chartCanvas = document.getElementById(
      'last-thirty-days-chart'
    ) as HTMLCanvasElement

    if (chartCanvas === null) {
      return
    }

    const chartData = lastThirtyDays

    const renderLast30XLabel = value => {
      const today = new Date()
      const axesDate = sub(today, { days: 30 - value })
      const dateDay = format(axesDate, 'dd/LL')

      return dateDay
    }

    if (typeof lastThirtyDaysChartRef !== 'undefined') {
      lastThirtyDaysChartRef.destroy()
    }

    lastThirtyDaysChartRef = new Chart(chartCanvas, {
      type: 'line',
      data: {
        labels: chartData.map((_, index) => renderLast30XLabel(index + 1)),
        datasets: [
          {
            label: '',
            data: chartData.map((el, index) => {
              return { x: index + 1, y: el }
            }),
            backgroundColor: HEXES[0],
            pointBackgroundColor: HEXES[1],
            borderColor: HEXES[1]
          }
        ]
      },
      options: {
        plugins: {
          legend: {
            display: false
          }
        },
        scales: {
          y: {
            ticks: {
              stepSize: 10
            }
          }
        }
      }
    })
    return lastThirtyDaysChartRef
  }

  const candidatesStageChart = () => {
    const chartCanvas = document.getElementById(
      'candidate-stage'
    ) as HTMLCanvasElement

    if (chartCanvas === null) {
      return
    }

    const renderLast6MonthsXLabel = value => {
      const today = new Date()
      const axesDate = sub(today, { months: 5 - value })
      const dateDay = format(axesDate, 'MMM')

      return dateDay
    }

    if (typeof candidateStageChartRef !== 'undefined') {
      candidateStageChartRef.destroy()
    }

    const chartData = candidateStage
    const colors = HEXES
    candidateStageChartRef = new Chart(chartCanvas, {
      type: 'bar',
      data: {
        labels: [...Array(6)].map((_, index) => renderLast6MonthsXLabel(index)),
        datasets: chartData.map((stage, i) => ({
          label: t(stage.label),
          data: stage.values,
          backgroundColor: colors[i % colors.length]
        }))
      },
      options: {
        legend: {
          display: true
        },
        scales: {
          y: {
            ticks: {
              stepSize: 10
            },
            stacked: true
          },
          x: {
            ticks: {
              display: width > 500,
              stepSize: 1
            },
            stacked: true
          }
        }
      }
    })
    return candidateStageChartRef
  }

  const ageChart = () => {
    const chartCanvas = document.getElementById(
      'age-chart'
    ) as HTMLCanvasElement
    if (chartCanvas === null) {
      return
    }

    if (typeof ageChartRef !== 'undefined') {
      ageChartRef.destroy()
    }

    const chartData = candidatesAge
    ageChartRef = new Chart(chartCanvas, {
      type: 'doughnut',
      data: {
        labels: chartData?.map(
          el =>
            `${el.minAge ? el.minAge : ''} ${
              el.maxAge && el.minAge ? '-' : ''
            } ${el.maxAge ? el.maxAge : ''}`
        ),
        datasets: [
          {
            data: chartData?.map(el => el.value),
            backgroundColor: HEXES
          }
        ]
      }
    })
    return ageChartRef
  }

  const genderChart = () => {
    const chartCanvas = document.getElementById(
      'sex-chart'
    ) as HTMLCanvasElement
    if (chartCanvas === null) {
      return
    }

    const chartData = candidatesGender

    const newLabel = chartData?.map(el => t(el.label))
    const index = newLabel.indexOf('No sex info')
    if (index > -1) newLabel.splice(index, 1)

    if (typeof genderChartRef !== 'undefined') {
      genderChartRef.destroy()
    }

    genderChartRef = new Chart(chartCanvas, {
      type: 'doughnut',
      data: {
        labels: newLabel,
        datasets: [
          {
            data: chartData?.map(el => el.value),
            backgroundColor: [HEXES[2], HEXES[1], HEXES[5], HEXES[3]]
          }
        ]
      }
    })
    return genderChartRef
  }

  const educationLevelChart = () => {
    const chartCanvas = document.getElementById(
      'education-level-chart'
    ) as HTMLCanvasElement

    if (chartCanvas === null) {
      return
    }

    const chartData = candidatesEducationLevel

    if (typeof educationLevelChartRef !== 'undefined') {
      educationLevelChartRef.destroy()
    }

    educationLevelChartRef = new Chart(chartCanvas, {
      type: 'bar',
      data: {
        labels: chartData?.map(el => el.label),
        datasets: [
          {
            label: '',
            data: chartData?.map(el => el.value),
            backgroundColor: [HEXES[0], HEXES[1], HEXES[2]]
          }
        ]
      },

      options: {
        plugins: {
          legend: {
            display: false
          }
        },
        scales: {
          y: {
            ticks: {
              stepSize: 10
            }
          },
          x: {
            ticks: {
              display: width > 500
            }
          }
        }
      }
    })
    return educationLevelChartRef
  }

  const maritalStatusChart = useCallback(() => {
    const chartCanvas = document.getElementById(
      'maritial-status-chart'
    ) as HTMLCanvasElement

    if (chartCanvas === null) {
      return
    }

    const chartData = candidatesMaritalStatus

    if (typeof maritalStatusChartRef !== 'undefined') {
      maritalStatusChartRef.destroy()
    }

    const newLabel = chartData?.map(el => t(el.label))
    const index = newLabel.indexOf('No marital status info')
    if (index > -1) newLabel.splice(index, 1)
    maritalStatusChartRef = new Chart(chartCanvas, {
      type: 'bar',
      data: {
        labels: newLabel,
        datasets: [
          {
            label: '',
            data: chartData?.map(el => el.value),
            backgroundColor: [
              HEXES[0],
              HEXES[1],
              HEXES[2],
              HEXES[3],
              HEXES[4],
              HEXES[5]
            ]
          }
        ]
      },
      options: {
        plugins: {
          legend: {
            display: false
          }
        },
        scales: {
          y: {
            ticks: {
              stepSize: 20
            }
          }
        }
      }
    })
    return maritalStatusChartRef
  }, [candidatesMaritalStatus])

  const provinceChart = () => {
    const chartCanvas = document.getElementById(
      'location-chart'
    ) as HTMLCanvasElement

    if (chartCanvas === null) {
      return
    }

    if (typeof provinceChartRef !== 'undefined') {
      provinceChartRef.destroy()
    }

    provinceChartRef = new Chart(chartCanvas, {
      type: 'bar',
      data: {
        labels: candidatesByLocation?.map(s => s.label),
        datasets: [
          {
            label: '',
            data: candidatesByLocation?.map(el => el.value),
            backgroundColor: [
              HEXES[0],
              HEXES[1],
              HEXES[2],
              HEXES[3],
              HEXES[4],
              HEXES[5],
              HEXES[6]
            ]
          }
        ]
      },
      options: {
        plugins: {
          legend: {
            display: false
          }
        },
        scales: {
          x: {
            ticks: {
              stepSize: 10
            }
          }
        }
      }
    })
    return provinceChartRef
  }

  const acquisitionChart = () => {
    const chartCanvas = document.getElementById(
      'acquisition-source-chart'
    ) as HTMLCanvasElement

    if (chartCanvas === null) {
      return
    }

    const chartData = candidatesAcquisition

    if (typeof acquisitionChartRef !== 'undefined') {
      acquisitionChartRef.destroy()
    }

    acquisitionChartRef = new Chart(chartCanvas, {
      type: 'bar',
      data: {
        labels: chartData?.map(el => el.label),
        datasets: [
          {
            label: '',
            data: chartData?.map(el => el.value),
            backgroundColor: [HEXES[0], HEXES[1], HEXES[2], HEXES[3], HEXES[4]]
          }
        ]
      },
      options: {
        plugins: {
          legend: {
            display: false
          }
        },
        scales: {
          y: {
            ticks: {
              stepSize: 10
            }
          }
        }
      }
    })
    return acquisitionChartRef
  }

  const finishJob = () => {
    const chartCanvas = document.getElementById(
      'avg-closing-by-recruiter-chart'
    ) as HTMLCanvasElement

    if (chartCanvas === null) {
      return
    }

    const chartData = closingTime

    if (typeof finishJobChartRef !== 'undefined') {
      finishJobChartRef.destroy()
    }

    finishJobChartRef = new Chart(chartCanvas, {
      type: 'bar',
      data: {
        labels: chartData?.map(el => el.label),
        datasets: [
          {
            label: '',
            data: chartData?.map(el => convertTime(el.value)),
            backgroundColor: HEXES[0]
          }
        ]
      },
      options: {
        plugins: {
          legend: {
            display: false
          }
        },
        scales: {
          x: {
            ticks: {
              stepSize: 10
            }
          }
        }
      }
    })
    return finishJobChartRef
  }

  const averageFinishJobs = () => {
    const chartCanvas = document.getElementById(
      'avg-closing-chart'
    ) as HTMLCanvasElement

    if (chartCanvas === null) {
      return
    }

    const renderLast6MonthsXLabel = value => {
      const today = new Date()
      const axesDate = sub(today, { months: 5 - value })
      const dateDay = format(axesDate, 'MMM')

      return dateDay
    }

    const chartData = lastSixMonths

    if (typeof avgFinishJobChartRef !== 'undefined') {
      avgFinishJobChartRef.destroy()
    }

    avgFinishJobChartRef = new Chart(chartCanvas, {
      type: 'line',
      data: {
        labels: [...Array(6)].map((_, index) => renderLast6MonthsXLabel(index)),
        datasets: [
          {
            label: '',
            data: chartData?.map(el => convertTime(el)),
            backgroundColor: HEXES[0],
            pointBackgroundColor: HEXES[1],
            borderColor: HEXES[1]
          }
        ]
      },
      options: {
        plugins: {
          legend: {
            display: false
          }
        },
        scales: {
          y: {
            ticks: {
              stepSize: 10
            }
          }
        }
      }
    })
    return avgFinishJobChartRef
  }

  const jobs = () => {
    const chartCanvas = document.getElementById(
      'open-jobs-chart'
    ) as HTMLCanvasElement

    if (chartCanvas === null) {
      return
    }
    const chartData = jobsOppened

    if (typeof jobsChartRef !== 'undefined') {
      jobsChartRef.destroy()
    }

    jobsChartRef = new Chart(chartCanvas, {
      type: 'doughnut',
      data: {
        labels: [t('inTime'), t('late')] as string[],
        datasets: [
          {
            data: [chartData.in_time, chartData.late],
            backgroundColor: [HEXES[0], HEXES[1]]
          }
        ]
      }
    })
    return jobsChartRef
  }

  const finishedJobs = () => {
    const chartCanvas = document.getElementById(
      'closed-jobs-chart'
    ) as HTMLCanvasElement

    if (chartCanvas === null) {
      return
    }
    const chartData = jobsCLosed

    if (typeof finishedJobsChartRef !== 'undefined') {
      finishedJobsChartRef.destroy()
    }

    finishedJobsChartRef = new Chart(chartCanvas, {
      type: 'doughnut',
      data: {
        labels: [t('inTime'), t('late')] as string[],
        datasets: [
          {
            data: [chartData.in_time, chartData.late],
            backgroundColor: [HEXES[2], HEXES[3]]
          }
        ]
      }
    })
    return finishedJobsChartRef
  }

  const closedByRecruiter = () => {
    const chartCanvas = document.getElementById(
      'closed-jobs-by-recruiter-chart'
    ) as HTMLCanvasElement

    if (chartCanvas === null) {
      return
    }
    const chartData = closedByRecruiters

    if (typeof closedByRecruiterChartRef !== 'undefined') {
      closedByRecruiterChartRef.destroy()
    }

    closedByRecruiterChartRef = new Chart(chartCanvas, {
      type: 'bar',
      data: {
        labels: chartData?.map(el => el.label),
        datasets: [
          {
            label: t('inTime'),
            data: chartData?.map(el => el.value.in_time),
            backgroundColor: HEXES[0]
          },
          {
            label: t('late'),
            data: chartData?.map(el => el.value.late),
            backgroundColor: HEXES[1]
          }
        ]
      },
      options: {
        scales: {
          x: {
            ticks: {
              stepSize: 10
            },
            stacked: true
          },
          y: {
            stacked: true
          }
        }
      }
    })
    return closedByRecruiterChartRef
  }

  const showNotification = (payload: NotificationConfig) =>
    dispatch(notificationActions.notify(payload))

  const handleChangeWorkspaces = (workspaces: string[]) => {
    setWorkspacesSelected(workspaces.join(','))
  }

  return (
    <>
      <div
        className={`content-grid ${
          expandedMenu ? 'content-grid-expanded-menu' : ''
        }`}
      >
        <header className={'section-header'}>
          {selectors.auth.user()?.role === 'admin' && (
            <>
              <div className={'section-header-dashboard'}>
                <h2 className={'section-title'}>
                  {t('hello')}, {fullName}!
                </h2>
                <p className={'section-pretitle'}>{t('seeCompanyData')}</p>
              </div>
              <div className='section-header-filters'>
                {showOnGeralWorkspace && workspacesList && (
                  <MultipleSelect
                    items={workspacesList}
                    onChange={handleChangeWorkspaces}
                    defaultValue={workspacesSelected?.split(',') || []}
                  />
                )}

                <Select
                  id='recruter-select'
                  label={t('filterByRecruiter')}
                  value={recruiterSelected || ''}
                  onChange={e => {
                    setRecruiterSelected(e.target.value)
                  }}
                  noPadding={true}
                >
                  <option value=''></option>

                  {selectedRecruiter?.map((item: any) => {
                    return (
                      <option value={item.uuid} key={item.uuid}>
                        {item.firstname} {item.lastname}
                      </option>
                    )
                  })}
                </Select>
              </div>
            </>
          )}
        </header>

        <div className='content'>
          <>{loading && <Loading />}</>
          <div style={{ visibility: loading ? `hidden` : 'visible' }}>
            <div className='container'>
              {' '}
              <div className='row justify-content-around'>
                <div className='col-12 col-md-6'>
                  <DashboadCard title={t('chart01')}>
                    <DashboardChart
                      id='open-jobs-chart'
                      visible={
                        !loading &&
                        (jobsOppened.late > 0 || jobsOppened.in_time > 0)
                      }
                    />
                  </DashboadCard>
                </div>

                <div className='col-12 col-md-6'>
                  <DashboadCard title={t('chart02')}>
                    <DashboardChart
                      id='closed-jobs-chart'
                      visible={
                        !loading &&
                        (jobsCLosed.late > 0 || jobsCLosed.in_time > 0)
                      }
                    />
                  </DashboadCard>
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <DashboadCard title={t('chart1')}>
                    <DashboardChart
                      id='avg-closing-chart'
                      visible={!loading && lastSixMonths?.length > 0}
                    />
                  </DashboadCard>
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <DashboadCard title={t('chart2')}>
                    <DashboardChart
                      id='avg-closing-by-recruiter-chart'
                      visible={!loading && closingTime?.length > 0}
                    />
                  </DashboadCard>
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <DashboadCard title={t('chart10')}>
                    <DashboardChart
                      id='closed-jobs-by-recruiter-chart'
                      visible={!loading && closedByRecruiters?.length > 0}
                    />
                  </DashboadCard>
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <DashboadCard title={t('chart3')}>
                    <DashboardChart
                      id='acquisition-source-chart'
                      visible={!loading && candidatesAcquisition?.length > 0}
                    />
                  </DashboadCard>
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <DashboadCard title={t('chart4')}>
                    <DashboardChart
                      id='last-thirty-days-chart'
                      visible={!loading && lastThirtyDays?.length > 0}
                    />
                  </DashboadCard>
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <DashboadCard
                    title={t('chart11')}
                    select={
                      <Select
                        id='workflow-select'
                        label={t('filterByWorkflow')}
                        value={workflowSelected || ''}
                        onChange={e => {
                          fetchLastSixMonthsCandidatesStage(e.target.value)
                        }}
                      >
                        <option value={workflows?.[0]?.uuid}>
                          {workflows?.[0]?.name}
                        </option>
                        {workflows?.map(
                          (workflow: WorkflowSchema, index: number) => {
                            return (
                              index >= 1 && (
                                <option
                                  value={workflow.uuid}
                                  key={workflow.uuid}
                                >
                                  {workflow.name}
                                </option>
                              )
                            )
                          }
                        )}
                      </Select>
                    }
                  >
                    <DashboardChart
                      id='candidate-stage'
                      visible={!loading && candidateStage?.length > 0}
                    />
                  </DashboadCard>
                </div>
              </div>
              <div className='row justify-content-around'>
                {dataPrivacySettings?.ask_birthdate && (
                  <div className='col-12 col-md-6'>
                    <DashboadCard title={t('chart5')}>
                      <DashboardChart
                        id='age-chart'
                        visible={!loading && candidatesAge?.length > 0}
                      />
                    </DashboadCard>
                  </div>
                )}

                {dataPrivacySettings?.ask_sex && (
                  <div className='col-12 col-md-6'>
                    <DashboadCard title={t('chart6')}>
                      <DashboardChart
                        id='sex-chart'
                        visible={!loading && candidatesGender?.length > 0}
                      />
                    </DashboadCard>
                  </div>
                )}
              </div>
              <div className='row'>
                <div className='col-12'>
                  <DashboadCard title={t('chart7')}>
                    <DashboardChart
                      id='education-level-chart'
                      visible={!loading && candidatesEducationLevel?.length > 0}
                    />
                  </DashboadCard>
                </div>
              </div>
              <div className='row'>
                {dataPrivacySettings?.ask_marital_status && (
                  <div className='col-12'>
                    <DashboadCard title={t('chart8')}>
                      <DashboardChart
                        id='maritial-status-chart'
                        visible={
                          !loading && candidatesMaritalStatus?.length > 0
                        }
                      />
                    </DashboadCard>
                  </div>
                )}
              </div>
              <div className='row'>
                <div className='col-12'>
                  <DashboadCard title={t('chart9')}>
                    <DashboardChart
                      id='location-chart'
                      visible={!loading && candidatesByLocation?.length > 0}
                    />
                  </DashboadCard>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
