/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState, useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import { Button } from 'components/Button/Button'
import { MenuItem } from 'components/MenuItem/MenuItem'
import { Modal } from 'components/Modal/Modal'
import { TimeLine, TimeLineItems } from 'components/TimeLine/TimeLine'
import './CandidatePreview.scss'
import useStore from 'store'
import { getDateFormat, getFullYear, timestampToDate } from 'utils/date'

import moment from 'moment'

type Props = {
  open: boolean
  user: string
  infos?: Array<string>
  about?: string
  education?: Array<CandidateEducationalExperiences>
  experience?: Array<CandidateProfessionalExperiences>
  languages?: Array<CandidateLanguages>
  onClose: (value: boolean) => void
}

export const CandidatePreview: React.FC<Props> = ({
  open,
  user,
  infos,
  about,
  education,
  experience,
  languages,
  onClose
}) => {
  const [modalOpen, setModalOpen] = useState(open)
  const [current, setCurrent] = useState(0)
  const { t } = useTranslation('candidatePreview')

  const { selectors } = useStore()
  const userData = selectors.auth.user()

  const firstLetter = () => {
    const firstLetter = [...user][0].toUpperCase()
    return firstLetter
  }

  const personalInfoLgpd = (key: string, position: number) => {
    const privacy = userData?.company?.data_privacy_settings
    if (position === 1) {
      if (!privacy?.ask_sex) return false
      else return t(key)
    }
    if (position === 2) {
      if (!privacy?.ask_marital_status) return false
      else {
        return t(key)
      }
    } else {
      return key
    }
  }

  useEffect(() => {
    setModalOpen(open)
  }, [open])

  useEffect(() => {
    onClose(modalOpen)
  }, [modalOpen])

  return (
    <div className='candidate-preview-wrapper'>
      <Modal onClose={() => setModalOpen(!modalOpen)} open={modalOpen}>
        <div className='row'>
          <div className='col-12 col-md-4'>
            <div className='border-right'>
              {' '}
              <div className='user-preview'>
                <div className='user-preview-header' />

                <svg
                  viewBox='0 0 100 100'
                  version='1.1'
                  xmlns='http://www.w3.org/2000/svg'
                  className='hexagon'
                >
                  <polygon
                    points='50 1 95 25 95 75 50 99 5 75 5 25'
                    fill='url(#img)'
                  />

                  <text textAnchor='middle' x='50' y='60'>
                    {firstLetter()}
                  </text>
                </svg>

                <p className='user-profile'>{user}</p>

                {infos?.map((el, index) => {
                  return (
                    <p className='user-info' key={index}>
                      {personalInfoLgpd(el, index)}
                    </p>
                  )
                })}
              </div>
              <div className='sidebar-menu-item'>
                <div className='sidebar-menu-body secondary'>
                  <MenuItem
                    label={t('about')}
                    current={current}
                    index={0}
                    onClick={() => setCurrent(0)}
                  />

                  <MenuItem
                    label={t('education')}
                    current={current}
                    index={1}
                    onClick={() => setCurrent(1)}
                  />

                  <MenuItem
                    label={t('experience')}
                    current={current}
                    index={2}
                    onClick={() => setCurrent(2)}
                  />

                  <MenuItem
                    label={t('languages')}
                    current={current}
                    index={3}
                    onClick={() => setCurrent(3)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='col-12 col-md-8'>
            <div className={`w-100 content ${current === 0 ? '' : 'd-none'}`}>
              <h1>{t('about')}</h1>
              <p style={{ wordWrap: 'break-word' }}>{about}</p>
            </div>

            <div className={`w-100 content ${current === 1 ? '' : 'd-none'}`}>
              <h1>{t('education')}</h1>
              <p>
                <TimeLine
                  items={
                    education?.map(education => {
                      const startDate = getFullYear(
                        timestampToDate(education.start_date)
                      )
                      const endDate =
                        education.end_date === 0
                          ? t('current')
                          : getFullYear(timestampToDate(education.end_date))

                      return {
                        title: `${education.course} - ${education.institution}`,
                        subTitle: `${startDate} - ${endDate}`,
                        content: education.description
                      } as TimeLineItems
                    }) || []
                  }
                />
              </p>
            </div>

            <div className={`w-100 content ${current === 2 ? '' : 'd-none'}`}>
              <h1>{t('experience')}</h1>
              <p>
                <TimeLine
                  items={
                    experience?.map(experience => {
                      const startDate = getFullYear(
                        timestampToDate(experience.start_date)
                      )
                      const endDate =
                        experience.end_date === 0
                          ? t('current')
                          : getFullYear(timestampToDate(experience.end_date))

                      return {
                        title: `${experience.role} - ${experience.company}`,
                        subTitle: `${startDate} - ${endDate}`,
                        content: experience.description
                      } as TimeLineItems
                    }) || []
                  }
                />
              </p>
            </div>

            <div className={`w-100 content ${current === 3 ? '' : 'd-none'}`}>
              <h1>{t('languages')}</h1>
              <p>
                <TimeLine
                  items={
                    languages?.map(language => {
                      return {
                        title: language.language,
                        subTitle: t(language.fluency)
                      } as TimeLineItems
                    }) || []
                  }
                />
              </p>
            </div>
          </div>
          <div className='w-100 popup-box-sidebar-footer'>
            <Button className='white' onClick={() => setModalOpen(false)}>
              {t('closePreview')}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  )
}
