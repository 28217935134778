/* eslint-disable indent */
import React, { useCallback, useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useNavigate } from 'react-router-dom'

import { JobCard, JobCardRefs } from 'components/JobCard/JobCard'
import { JobList } from 'components/JobList/JobList'
import { Loading } from 'components/Loading/Loading'
import { Modal } from 'components/Modal/Modal'
import { PermissionAgent } from 'components/PermissionAgent/PermissionAgent'
import { jobs } from 'services/api'
import useStore from 'store'
import ReactGA from 'utils/analytics'
import userCheckPermission from 'utils/userCheckPermission'

import { JobListCards } from './JobListCards'
import { ShareJob } from './ShareJob/ShareJob'

import './JobsContainer.scss'

interface JobQuery {
  active?: string | boolean
  late?: string | boolean
  filter?: string
  all?: boolean
  workspaces?: string
}

type Props = {
  viewType: 'grid' | 'list'
  getRefs: (value: JobCardRefs) => void
  filter: 'active' | 'disabled' | 'all' | 'late'
  search: string
  jobsTotal: (value: number) => void
}

export const JobsContainer: React.FC<Props> = ({
  viewType,
  getRefs,
  filter,
  search,
  jobsTotal
}) => {
  const [modalOpen, setModalOpen] = useState({ opened: false, uuid: '' })
  const [page, setPage] = useState(1)
  const [filters, setFilters] = useState<object | undefined>(undefined)
  const [hasMore, setHasMore] = useState(true)
  const [ref, setRefs] = useState<JobCardRefs>({} as JobCardRefs)
  const [jobsList, setJobsList] = useState<Job[]>([])
  const [addButtonRef, setAddButtonRef] = useState<JobCardRefs>(
    {} as JobCardRefs
  )
  const { selectors } = useStore()
  const slug = selectors.auth.companySlug()
  const { t } = useTranslation('jobsContainer')

  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()

  // Fetches jobs from the server based on the current page and filters.
  const fetchJobs = useCallback(async () => {
    setLoading(true)
    try {
      const [error, data] = await jobs.list({ page, filters })
      if (data?.total !== undefined) jobsTotal(data?.total)
      setJobsList(prevJobsList => {
        const existingUUIDs = new Set(prevJobsList.map(job => job.uuid))
        const uniqueJobs =
          data?.data.filter(job => !existingUUIDs.has(job.uuid)) || []
        return [...prevJobsList, ...uniqueJobs]
      })
      setHasMore(data?.data.length === 10)
      setPage(prevPage => prevPage + 1)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }, [page, filters])

  // set refs to get tutorial
  useEffect(() => {
    const refs = {
      ...addButtonRef,
      ...ref
    }
    getRefs(refs)
  }, [ref])

  // handle filter and search
  useEffect(() => {
    const filterIsLate = filter === 'late'
    const filterIsActive = filter === 'active'
    const filterIsDisabled = filter === 'disabled'

    const query: JobQuery = {
      late: filterIsLate || undefined,
      active: filterIsActive ? true : filterIsDisabled ? false : undefined,
      workspaces: search || undefined
      // filter: search || undefined
    }

    setJobsList([])
    setFilters(query)
    setHasMore(true)
    setPage(1)
  }, [filter, search])

  useEffect(() => {
    if (hasMore && page === 1 && filters !== undefined) {
      fetchJobs()
    }
  }, [hasMore, page, filters, fetchJobs])

  const AddCard = viewType === 'grid' ? JobCard : JobList

  return (
    <>
      <Modal
        title='+ Compartilhar vaga'
        open={modalOpen.opened}
        onClose={() => setModalOpen({ opened: false, uuid: '' })}
      >
        <ShareJob
          jobUuid={modalOpen.uuid}
          site={
            process.env.NODE_ENV === 'development'
              ? `http://${process.env.REACT_APP_DOMAIN}/${slug}`
              : `https://${slug}.${process.env.REACT_APP_DOMAIN}`
          }
        />
      </Modal>

      <PermissionAgent module='job' actions={['view']} onFail='hidden'>
        <InfiniteScroll
          dataLength={jobsList.length}
          next={fetchJobs}
          hasMore={hasMore}
          loader={
            <div className='row w-100'>
              <div className='col-12 d-flex justify-content-center align-items-center'>
                <Loading />
              </div>
            </div>
          }
          style={{ overflow: 'hidden' }}
          className={viewType === 'grid' ? 'list-jobs mt-5' : 'mt-5'}
        >
          <PermissionAgent module='job' actions={['create']} onFail='hidden'>
            <AddCard
              hiddenActiveStatus={userCheckPermission({
                resource: 'job',
                scope: ['filter:job-status']
              })}
              status='add-new-job'
              getRefs={refs => setAddButtonRef(refs)}
              onCreateNewVacancy={() => {
                ReactGA.event('create_new_job_start', {
                  event_category: 'job_listing',
                  event_label: 'create_new_job_start'
                })
                navigate('/job')
              }}
              hiddenTeam={userCheckPermission({
                resource: 'job',
                scope: ['filter:user-allocated']
              })}
              hiddenEdit={userCheckPermission({
                resource: 'job',
                scope: ['filter:user-edit']
              })}
            />
          </PermissionAgent>
          <JobListCards
            jobs={jobsList.length > 0 ? jobsList : []}
            setModalOpen={setModalOpen}
            setRefs={setRefs}
            type={viewType}
            onUpdate={() => {
              setJobsList([])
              setHasMore(true)
              setPage(1)
            }}
          />

          {jobsList.length === 0 && !loading && (
            <div className='d-flex justify-content-center align-items-center w-100'>
              <h2 className={viewType === 'list' ? 'mt-5' : ''}>
                {t('notfound')}
              </h2>
            </div>
          )}
        </InfiniteScroll>
      </PermissionAgent>
    </>
  )
}
