import React from 'react'

import classNames from 'classnames'

export interface FormRowProps {
  split?: boolean
  formRowClasses?: string
}

export const FormRow: React.FC<FormRowProps> = ({
  split,
  formRowClasses,
  children
}) => {
  return (
    <div
      className={classNames('form-row row', {
        split,
        [`${formRowClasses}`]: !!formRowClasses
      })}
    >
      {children}
    </div>
  )
}
