import { handleApiResponse } from 'services/api/default/response/response.util'
import { WorkspacesApiPath } from 'services/api/workspaces/workspaces.constants'

import api from './../api'
import { CreateWorkspacePayload, WorkspaceSchema } from './workspaces.d'

/**
 * Creates a new workspace.
 *
 * @param payload - The payload containing the data for creating the workspace.
 * @type CreateWorkspacePayload
 *
 * @returns A promise that resolves to the default API response.
 * @type DefaultAPIResponse<WorkspaceSchema>
 * @example { status: 'success', statusCode: 200, message: 'Workspace created successfully', data: {...} }
 */
export const create = async (
  payload: CreateWorkspacePayload
): Promise<DefaultAPIResponse<WorkspaceSchema>> => {
  const finalPayload = {
    ...payload,
    status: 'active',
    id_matriz: '664ba1156e77e39286817ee0'
  }
  console.log('SIM, MEU AMOR, EU RECEBI A SUA REQUEST', finalPayload)
  const response = await api.post(WorkspacesApiPath.create, finalPayload)
  console.log('TÁ LÁ BEBE, SUA RESPOSTA', response)
  return handleApiResponse(response)
}

/**
 * Retrieves a list of workspaces.
 *
 * @returns A Promise that resolves to a default API response.
 * @type DefaultAPIResponse<WorkspaceSchema>
 * @example { status: 'success', statusCode: 200, message: 'Workspace created successfully', data: {...} }
 */
export const list = async (): Promise<
  DefaultAPIResponse<WorkspaceSchema[]>
> => {
  const response = await api.get(WorkspacesApiPath.read, {
    params: {
      page: 1,
      per_page: 100000
    }
  })
  return handleApiResponse(response)
}
