import React from 'react'

import useStore from 'store'

interface PermissionAgentProps {
  module: PermissionModule
  actions: PermissionAction[]
  onFail: 'disabled' | 'hidden'
  Component?: any // eslint-disable-line @typescript-eslint/no-explicit-any
  componentProps?: Object // eslint-disable-line @typescript-eslint/ban-types
}

export const PermissionAgent: React.FC<PermissionAgentProps> = ({
  Component,
  componentProps = {},
  module: permissionModule,
  actions,
  onFail,
  children
}) => {
  const { selectors } = useStore()
  const permissions = selectors.permissions.permissions()
  const permission = permissions[permissionModule]

  if (!permission) return null

  const anyDenied = actions
    .map(action => permission.scopes.includes(`${permissionModule}:${action}`))
    .filter(action => !action)

  const injectProp = { [onFail]: !!anyDenied.length }

  if (onFail === 'hidden' && injectProp.hidden) {
    return null
  }

  return children ? (
    <>{children}</>
  ) : (
    <Component {...componentProps} {...injectProp} />
  )
}
