import React from 'react'

import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { ReactComponent as MembersIcon } from 'assets/images/icons/members.svg'
import { ReactComponent as PlusIcon } from 'assets/svg/plus.svg'
import { Button } from 'components/Button/Button'
import { PermissionAgent } from 'components/PermissionAgent/PermissionAgent'
import 'components/CandidateCard/CandidateCard.scss'
import { useWorkspacePermission } from 'hooks/useWorkspacePermission'

type AddCandidateCardProps = {
  viewType: 'list' | 'grid'
}
export const AddCandidateCard = ({ viewType }: AddCandidateCardProps) => {
  const { t } = useTranslation('candidateList')
  const { dontShowOnGeralWorkspace } = useWorkspacePermission()
  const navigate = useNavigate()
  if (viewType === 'list') {
    return (
      <PermissionAgent
        module='candidate'
        actions={['create']}
        onFail='disabled'
        Component={({ disabled }) => (
          <Button
            onClick={() => navigate(`/add-candidate`)}
            disabled={disabled}
            className='button void candidate-new-list'
          >
            <PlusIcon /> {t('addCandidate')}
          </Button>
        )}
      />
    )
  }
  return (
    <>
      {dontShowOnGeralWorkspace && (
        <div className='demo-box candidate-box'>
          <div className='demo-box-icon-wrap'>
            <MembersIcon className='icon-add-friend demo-box-icon' />
          </div>
          <div style={{ width: '190px' }}>
            <p className='demo-box-title' data-testid='add-candidate-label'>
              {t('addCandidate')}
            </p>
          </div>
          <div>
            <PermissionAgent
              module='candidate'
              actions={['create']}
              onFail='disabled'
              Component={({ disabled }) => (
                <div>
                  <Button
                    onClick={() => navigate(`/add-candidate`)}
                    disabled={disabled}
                    className='button void candidate-new'
                  >
                    {t('add')}
                  </Button>
                </div>
              )}
            />
          </div>
        </div>
      )}
    </>
  )
}
