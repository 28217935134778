// SelectableList.tsx
import React, { useEffect, useState } from 'react'

import {
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText
} from '@mui/material'
import _, { Dictionary, groupBy } from 'lodash'
import './SelectableList.style.scss'
import { useTranslation } from 'react-i18next'

interface SelectableListProps<T> {
  items: T[]
  onSelect: (itemsSelected: string[]) => void
  labels: (keyof T & string)[]
  itemIdentifier: keyof T & string
  selectedItems?: string[]
  notEditable?: string[]
}
/**
 * A selectable list component.
 * @component
 * @param {Array} items - The list of items to be displayed.
 * @param {Function} onSelect - The callback function to be called when an item is selected.
 *        It sends as a parameter an array with the uuids of the selected items.
 * @param {Array} labels - The list of properties to be used for searching/filtering the items.
 *        All the items will be concatenated and used as labels for the items.
 *        IMPORTANT: The first element of the array will be used to sort the items alphabetically.
 * @param {String} itemIdentifier - The property to be used as the unique identifier of the items.
 *
 * @usage <SelectableList<T> items={items} onSelect={handleSelect} labels={['name', 'lastname']} itemIdentifier='uuid' />
 */

const SelectableList = <T,>({
  items,
  onSelect,
  labels,
  itemIdentifier,
  selectedItems,
  notEditable
}: SelectableListProps<T>): JSX.Element => {
  const { t } = useTranslation('selectableList')
  const [rawItemsList, setRawItemsList] = useState<Dictionary<T[]>>()
  const [selectedItemsList, setSelectedItemsList] = useState<string[]>(
    selectedItems || []
  )
  const [itemsList, setItemsList] = useState<Dictionary<T[]>>()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [allSelected, setAllSelected] = useState<boolean>(false)

  const handleToggle = (value: string) => () => {
    if (notEditable?.includes(value)) return

    const currentIndex = selectedItemsList?.indexOf(value)
    const newChecked = selectedItemsList ? [...selectedItemsList] : []

    const itemNotInTheList = currentIndex === -1
    if (itemNotInTheList) {
      // Add the item to the list
      newChecked.push(value)
      // Remove the item from the list
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setSelectedItemsList(newChecked)
    onSelect(newChecked)
  }

  const filterItems = (): Dictionary<T[]> => {
    const filteredItemsList = _.mapValues(rawItemsList, items =>
      items.filter(item =>
        labels.some(key =>
          (item[key] as string).toLowerCase().includes(searchTerm.toLowerCase())
        )
      )
    )
    const nonEmptyGroups = _.omitBy(
      filteredItemsList,
      group => group.length === 0
    )
    return nonEmptyGroups
  }

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setSearchTerm(e.target.value)

  const handleSelectAll = () => {
    let newChecked: string[] = []

    if (!allSelected) {
      const allItems = Object.values(rawItemsList ?? {}).flat()
      const allItemsIds = allItems.map(item => item[itemIdentifier] as string)
      newChecked = allItemsIds.filter(id => !notEditable?.includes(id))
    }

    console.log('____ select all newChecked', newChecked)
    onSelect(newChecked)
    setSelectedItemsList(newChecked)
    setAllSelected(!allSelected)
  }

  useEffect(() => {
    const notEditableSelected =
      notEditable?.filter(id => !selectedItemsList.includes(id)) ?? []

    if (notEditableSelected.length > 0) {
      const updatedSelectedItems = [
        ...selectedItemsList,
        ...notEditableSelected
      ]
      setSelectedItemsList(updatedSelectedItems)
      onSelect(updatedSelectedItems)
    }
  }, [notEditable, selectedItemsList])

  useEffect(() => {
    const sortedItems = items.sort((a, b) => {
      return (a[labels[0]] as string).localeCompare(b[labels[0]] as string)
    })
    const groupedItems = groupBy(sortedItems, item =>
      (item[labels[0]] as string)[0].toUpperCase()
    )
    setItemsList(groupedItems)
    setRawItemsList(groupedItems)
  }, [items])

  // On SEARCH TERM change AND search term has more than 3 characters and no spaces
  useEffect(() => {
    const searchTermIsValid = searchTerm && searchTerm.trim().length > 3

    if (searchTermIsValid) {
      const itemsFiltered = filterItems()
      setItemsList(itemsFiltered)
    } else {
      rawItemsList && setItemsList(rawItemsList)
    }
  }, [searchTerm])

  return (
    <div className='selectable-list'>
      <div className='form__field form__field--search'>
        <svg
          width='18'
          height='18'
          viewBox='0 0 18 18'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M12.5 11H11.71L11.43 10.73C12.41 9.59 13 8.11 13 6.5C13 2.91 10.09 0 6.5 0C2.91 0 0 2.91 0 6.5C0 10.09 2.91 13 6.5 13C8.11 13 9.59 12.41 10.73 11.43L11 11.71V12.5L16 17.49L17.49 16L12.5 11ZM6.5 11C4.01 11 2 8.99 2 6.5C2 4.01 4.01 2 6.5 2C8.99 2 11 4.01 11 6.5C11 8.99 8.99 11 6.5 11Z'
            fill='#320033'
          />
        </svg>
        <input
          type='text'
          name='searchUser'
          className='form__input'
          placeholder={t('lugarh.searchInputPlaceholder')}
          onChange={handleSearchChange}
        />
      </div>
      <List className='form__list'>
        {itemsList && Object.entries(itemsList ?? {}).length > 0 ? (
          <ListItem key='selectAll' disablePadding>
            <ListItemButton
              component='div'
              role='selectAll'
              onClick={handleSelectAll}
              dense
            >
              <ListItemIcon>
                <Checkbox
                  edge='start'
                  checked={allSelected}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': 'checkbox-list-label-all' }}
                />
              </ListItemIcon>
              <ListItemText
                id='checkbox-list-label-all'
                primary={t('lugarh.selectAllLabel')}
              />
            </ListItemButton>
          </ListItem>
        ) : (
          <p>{t('lugarh.notFound')}</p>
        )}

        {Object.entries(itemsList ?? {}).map(([group, items]) => (
          <React.Fragment key={group}>
            <ListItem className='form__list-item'>
              <ListItemText primary={group} className='form__list-title' />
            </ListItem>
            {items.map(item => {
              const labelId = `checkbox-list-label-${item[itemIdentifier]}`
              const notEditableItem = notEditable?.includes(
                item[itemIdentifier] as string
              )
              return (
                <ListItem key={item[itemIdentifier] as string} disablePadding>
                  <ListItemButton
                    role={'checkbox'}
                    onClick={handleToggle(item[itemIdentifier] as string)}
                    dense
                    disabled={notEditableItem}
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge='start'
                        checked={
                          selectedItemsList?.indexOf(
                            item[itemIdentifier] as string
                          ) !== -1
                        }
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': labelId }}
                        disabled={notEditableItem}
                      />
                    </ListItemIcon>
                    <ListItemText
                      id={labelId}
                      primary={labels.map(label => item[label]).join(' ')}
                    />
                  </ListItemButton>
                </ListItem>
              )
            })}
          </React.Fragment>
        ))}
      </List>
    </div>
  )
}

export default SelectableList
