/* eslint-disable indent */
import React, { useCallback, useEffect, useState } from 'react'

import { CandidateCardRef } from 'components/CandidateCard/CandidateCard'
import {
  CandidateDBHeader,
  CandidateDBHeaderRef
} from 'components/CandidateDBHeader/CandidateDBHeader'
import { Onboarding, RefList } from 'components/Onboarding/Onboarding'
import {
  CandidateFilterContainer,
  CandidateFilterRef,
  FilterForm
} from 'containers/CandidateFilter/CandidateFilterContainer'
import { CandidatesFilterModal } from 'containers/CandidateFilter/lugarh/CandidatesFilterModal'
import { CandidatesDatabaseContainer } from 'containers/CandidatesDatabase/CandidatesDatabaseContainer'
import { useFilterForm } from 'context/CandidateFilterContext'
import { usePermission } from 'hooks/usePermission'
import { candidates as candidatesAPI } from 'services/api'
import useStore from 'store'
import { NotificationActions } from 'store/notification'
import ReactGA from 'utils/analytics'

export function CandidatesDatabasePage(): JSX.Element {
  const { selectors } = useStore()
  const expandedMenu = selectors.asideMenu.isExpanded()

  usePermission({ moduleName: 'candidate', action: 'list' })

  const [refList, setRefList] = React.useState({})
  const [headerRefList, setHeaderRefList] = React.useState({})
  const [filterRefList, setFilterRefList] = React.useState({})
  const [cardRefList, setCardRefList] = React.useState({})
  const [orderBy, setOrderBy] = React.useState('')
  const [pageData, setPageData] = useState({ page: 1, per_page: 1000 })
  const [hasMore, setHasMore] = useState(true)
  const [candidates, setCandidates] = useState<Record<string, Candidate>>({})
  const { filterFormState: filter } = useFilterForm()
  const [openFilter, setOpenFilter] = useState<boolean>(false)

  const [viewType, setViewType] = React.useState<'grid' | 'list'>('grid')
  const { dispatch } = useStore()

  useEffect(() => {
    setRefList({ ...headerRefList, ...cardRefList, ...filterRefList })
  }, [headerRefList, cardRefList, filterRefList])

  useEffect(() => {
    ReactGA.pageView()
  }, [])

  const setHeaderTutorial = (value: CandidateDBHeaderRef) => {
    const obj: RefList = {
      1: { ref: value.orderByRef as React.MutableRefObject<HTMLElement> }
    }
    setHeaderRefList(obj)
  }

  const setFilterTutorial = (value: CandidateFilterRef) => {
    const obj: RefList = {
      2: {
        ref: value.filterRef as React.MutableRefObject<HTMLElement>,
        position: 'left'
      }
    }
    setFilterRefList(obj)
  }

  const setCandidateCardTutorial = (value: CandidateCardRef) => {
    const obj: RefList = {
      3: {
        ref: value.previewRef as React.MutableRefObject<HTMLElement>
      },
      4: { ref: value.detailRef as React.MutableRefObject<HTMLElement> },
      5: { ref: value.jobApplyRef as React.MutableRefObject<HTMLElement> }
    }
    setCardRefList(obj)
  }
  const showNotification = (payload: NotificationConfig) =>
    dispatch(NotificationActions.notify(payload))

  useEffect(() => {
    if (pageData.page === 1) fetchCandidates()
  }, [pageData])

  useEffect(() => {
    if (filter && orderBy) {
      setCandidates({})
      setHasMore(true)
      setPageData({
        page: 1,
        per_page: 10
      })
    }
  }, [filter, orderBy])

  const onSetFilter = () => {
    setCandidates({})
    setHasMore(true)
    setPageData({
      page: 1,
      per_page: 1000
    })
  }

  const fetchCandidates = useCallback(async () => {
    try {
      const [error, data] = await candidatesAPI.list({
        ...pageData,
        order_by: orderBy,
        filter: {
          geo_location: filter?.general?.location,
          min_age: filter?.general?.age.min,
          max_age: filter?.general?.age.max,
          sex: filter?.general?.gender,
          marital_status: filter?.general?.civilStatus,
          salary_expectation_min: filter?.general?.salary.min,
          salary_expectation_max: filter?.general?.salary.max,
          city_relocation:
            filter?.general?.availableToMove === 'true'
              ? true
              : filter?.general?.availableToMove === 'false'
                ? false
                : undefined,
          educational_institution: filter?.education?.name,
          educational_course: filter?.education?.course,
          educational_level: filter?.education?.level,
          professional_experience_company: filter?.experience?.company,
          professional_experience_role: filter?.experience?.position,
          professional_experience_years:
            filter?.experience?.timeInYears?.toString(),
          language: filter?.language?.name,
          language_fluency: filter?.language?.level,
          workspaces: filter?.general?.workspaces
        }
      })
      if (error) throw new Error(error.toString())
      const newMap = {}
      data?.forEach(candidate => {
        newMap[candidate.uuid] = candidate
      })

      setCandidates({ ...candidates, ...newMap })
      setHasMore(data?.length === pageData.per_page)
      setPageData({
        page: pageData.page + 1,
        per_page: pageData.per_page
      })
    } catch (error) {
      showNotification({ message: error.message, type: 'error' })
    }
  }, [candidates, filter, orderBy, pageData, showNotification])

  const handleOpenFilter = (value: boolean) => {
    setOpenFilter(value)
  }

  return (
    <div
      className={`content-grid ${
        expandedMenu ? 'content-grid-expanded-menu' : ''
      }`}
    >
      <Onboarding reflist={refList} page='cadidatesDB' />

      {/* <CandidateFilterContainer
        onFilter={onSetFilter}
        getRefs={setFilterTutorial}
        openFilter={openFilter}
        handleOpenFilter={handleOpenFilter}
      /> */}

      <CandidatesFilterModal
        open={openFilter}
        onClose={() => handleOpenFilter(false)}
        onSubmit={onSetFilter}
        customClass='candidates-filter'
      />

      <CandidateDBHeader
        getRefs={setHeaderTutorial}
        onChangeViewType={setViewType}
        onOrderBy={value => setOrderBy(value)}
        handleOpenFilter={handleOpenFilter}
      />

      <CandidatesDatabaseContainer
        candidates={candidates}
        hasMore={hasMore}
        fetchCandidates={fetchCandidates}
        getRefs={setCandidateCardTutorial}
        viewType={viewType}
      />
    </div>
  )
}
