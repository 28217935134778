import React from 'react'

export interface FormProps {
  id: string
  onSubmit: (e) => void
  autoComplete?: boolean
}

export const Form: React.FC<FormProps> = ({
  id,
  children,
  onSubmit,
  autoComplete = false
}) => (
  <form
    id={id}
    className='form'
    data-testid='form'
    onSubmit={onSubmit}
    autoComplete={autoComplete ? 'on' : 'off'}
  >
    {children}
  </form>
)

export const Row: React.FC = ({ children }) => (
  <div className='form-row'>{children}</div>
)

export const Item: React.FC = ({ children }) => (
  <div className='form-item'>{children}</div>
)
