const en: AppLocale = {
  languages: {
    'pt-BR': 'Português',
    'en-US': 'English'
  },
  notifications: {
    lugarh: {
      error: {
        'An error occurred while processing your request':
          'An error occurred while processing your request',
        'Not authorized, please check the workspace':
          'Not authorized, please check the workspace',
        permission: 'You do not have permission to access this resource.'
      },
      success: {
        'Your request was successfully completed':
          'Your request was successfully completed'
      }
    }
  },
  goHomeButton: {
    lugarh: {
      goHome: 'Go to Homepage'
    }
  },
  errors: {
    onlySpaces: 'Only spaces are not allowed',
    minBudget: 'Minimum budget is 650',
    errorTitle: 'Error',
    forbbidenError: "You don't have permission to access this resource.",
    requiredField: 'Required field',
    specialCharacters: 'Only numbers and letters are allowed',
    dateInvalidFormat: 'Please enter your date of birth in the correct format.',
    deadlineInvalidFormat: 'Please enter a valid deadline.',
    futureDate:
      'The date of birth cannot be in the future. Please enter a valid date.',
    invalidBirthdate:
      'The provided date of birth is outside the acceptable range. Make sure to enter a valid date.',
    invalidStartDateFormat:
      'Please enter the start date in the correct format.',
    futureStartDate: 'The start date cannot be in the future.',
    invalidStartDate:
      'The provided start date is outside the acceptable range. Make sure to enter a valid date.',
    invalidEndDateFormat: 'Please enter the end date in the correct format.',
    invalidEndDateOrder: 'The course end date must be after the start date.',
    invalidEndDate:
      'The provided end date is outside the acceptable range. Make sure to enter a valid date.',
    futureEndDate: 'The course end date cannot be in the future.',
    expiredtoken: 'Your session expired',
    emailInvalid: 'Invalid email!',
    fieldInvalid: 'Invalid field!',
    fieldMinLength: 'Must be at least 3 characters.',
    onRequestFail:
      'An error occurred while retrieving the data, please try again',
    addNoteError: 'An error occurred while adding review, please try again',
    addReview: 'Error adding note',
    cnpj: 'Incorret Company ID format',
    cnpjMaxLength: 'You have reached the maximum limit of 18 characters.',
    cpfCnpjInvalid: 'The number you entered is invalid.',
    cpfCnpjMinLength: 'Must be at least 14 characters.',
    cpfCnpjMaxLength: 'You have reached the maximum limit of 18 characters.',
    passwordMin: 'Password must be at least 6 characters',
    passwordConfirm: 'Passwords must be the same!',
    finalizeCompanyRegistration:
      'To access this page you need to complete the companys registration.',
    invalidSlug: 'Invalid link',
    linkAvailable: 'Link Available!',
    linkUnavailable: 'Link Not Available',
    invalidComapanyName: 'Company name cannot contain special characters.',
    applicationError: 'This record already exists in our databases.',
    timeoutError: 'Server took too long to reply. Try again',
    emptyCity: 'The job location field must be informed.',
    limitReached: 'This feature has already reached the limit.',
    creditCardNumberInvalid: 'Card number invalid',
    creditCardCvvInvalid: 'CVV invalid',
    creditCardExpirationMonthInvalid: 'Month invalid',
    creditCardExpirationYearInvalid: 'Year invalid',
    hiringWorkflowNameMaxLength:
      'You have reached the maximum limit of 80 characters.',
    hiringWorkflowDescriptionMaxLength:
      'You have reached the maximum limit of 300 characters.',
    downloadAttachedFile: 'The file does not exist or is no longer available',
    serverError: 'The server was unable to complete your request.'
  },
  success: {
    candidateMoved: 'Candidate successfully moved',
    noteAdded: 'Note successfully added',
    addReview: 'Review successfully added',
    applicationSuccess: 'Success',
    jobMoved: 'Job successfully moved',
    jobDuplicated: 'Job successfully duplicated'
  },
  cookie: {
    message:
      'We use cookies to help personalise content and provide a safer experience, we store no personal details. ' +
      'By browsing the pages, you declare that you agree with our Privacy Policy.',
    buttonAccept: 'Accept',
    buttonRefuse: 'Refuse'
  },
  common: {
    current: 'Present',
    gender: {
      male: 'Male',
      female: 'Female',
      other: 'Other'
    },
    civilStatus: {
      single: 'Single',
      married: 'Amrried',
      divorced: 'Divorced',
      widowed: 'Widower',
      other: 'Other'
    },
    applyToJob: 'Apply to Job'
  },
  createWorkflow: {
    create: 'Create Workflow',
    update: 'Update Workflow'
  },
  home: {
    metatags: {
      title: 'Lugarh | R&S',
      url: 'https://recrud.bne.com.br',
      description: 'The next generation of candidate relationship management.'
    },
    content: 'Edite o arquivo src/Home.tsx e salve para atualizar'
  },
  uploadBox: {
    minSizeRecommended: 'suggested size 400 x 50 px',
    logo: 'Logo',
    suggestedSize: 'Suggested size'
  },
  searchBar: {
    allCandidates: 'Show all candidates',
    allJobs: 'Show all jobs',
    placeholder: 'Search for candidates, jobs and tags',
    results: {
      candidates: 'Candidates found',
      jobs: 'Jobs found'
    },
    searching: 'Searching...',
    notFound: 'No results found',
    appliedFor: 'applied to {{number}} job(s)',
    candidatesNumber: '{{number}} candidates',
    createdAt: 'Created at {{date}}',
    candidates: 'candidates'
  },
  header: {
    about: 'About Us',
    terms: 'Terms of Use',
    signup: 'Create Account'
  },
  headerSettings: {
    workspaces: 'Workspaces',
    myProfile: 'My Profile',
    profileEdit: 'Edit Profile',
    company: 'Company',
    subscriptionSettings: 'Subscription Settings',
    companySettings: 'Company Settings',
    jobPostings: 'Job Postings',
    admission: 'Admission',
    changeCompany: 'Change Company',
    changeWorkspace: 'Change Workspace',
    manageWorkspace: 'Workspace Management',
    hiringWorkflows: 'Hiring Workflows',
    manageTeam: 'Team Management',
    language: 'Language',
    english: 'English',
    portuguese: 'Português',
    spanish: 'Español',
    session: 'Session',
    leave: 'Logout',
    hello: 'Hi',
    sections: {
      dashboard: 'Dashboard',
      jobs: 'Jobs',
      candidates: 'Candidate Database'
    },
    helpButtonDescription: 'Help',
    helpButtonSubject: 'Help with Candidate Management'
  },
  user: {
    role: {
      admin: 'Administrator',
      admin_rocketmat: 'Administrator',
      manager: 'Manager',
      recruiter: 'Recruiter',
      recruiter_webtactics: 'Recruiter Webtactics'
    },
    indicationButton: 'Indicate Recrud',
    copyLinkSuccessMessage: 'Link copied successfully'
  },
  router: {
    pageNotFound: 'Page not found. Please contact the support.'
  },
  login: {
    lugarh: {
      title:
        '[[span]]Revolutionize the[[/span]] [[span.highlight]]end-to-end[[/span]] [[span]]HR process in a single place with Lugarh[[/span]]',
      subtitle:
        'In addition to a powerful Recruitment and Selection tool, we offer a complete ecosystem for',
      itemOne: 'Admission',
      itemTwo: 'People Management',
      itemThree: 'HR BPO',
      specialist: 'Talk with a specialist',
      form: {
        title: 'Login into your account',
        email: {
          placeholder: 'E-mail'
        },
        password: {
          placeholder: 'Password',
          show: 'Show',
          hide: 'Hide'
        },
        keepConnected: {
          label: 'Remember me'
        },
        submitButton: 'Login',
        submitButtonLoading: 'Logging in...',
        errors: {
          emailRequired: 'E-mail is required',
          emailInvalid: 'Invalid e-mail',
          passwordMin: 'Must be at least 6 characters',
          passwordRequired: 'Password is required'
        }
      },
      forgotPassword: 'Forgot your password?',
      noAccount: 'Don’t have an account?',
      createAccount: 'Create Account',
      chooseLanguage: 'Choose your language',
      errors: {
        blockedUser:
          'User without access permission. Contact your administrator for more information.',
        expiredSubscription:
          'Subscription expired. Contact your administrator for more information.',
        expiredSession: 'Session expired. Log in again.',
        loginError: 'Username or password is invalid. Try again.'
      }
    },
    metatags: {
      title: 'Lugarh | R&S',
      url: 'https://recrud.bne.com.br',
      description:
        'Recrud is better than a ATS. Recrud is the next generation of candidate relationship management'
    },
    welcome: 'Welcome to',
    firstParagraph: 'A simple, easy and smart recruiting plataform.',
    secondParagraph: 'Enjoy your experience!',
    loginTitle: 'Login into your account',
    firstname: 'First Name',
    lastname: 'Last Name',
    email: 'E-mail',
    password: 'Password',
    loginCheckBox: 'Remember me',
    forgotPassword: 'Forgot your password?',
    loginAccount: 'Login!',
    registerTitle: 'Signup!',
    repeatPassword: 'Confirm password',
    registerCheckBox: 'I agree the terms of use!',
    registerNow: 'Signup now!',
    aboutUs: 'About Us',
    privacy: 'Privacy Policy',
    terms: 'Terms of Use',
    whatsRecrud: 'What is Recrud?',
    loginError: 'Incorrect username or password. Try again',
    expiredSession: 'Session expired. Sign in again',
    blockedUser:
      'User without access permission. Contact your administrator for more information.',
    expiredSubscription:
      'Subscription expired. Contact the administrator for more information.',
    signupSuccess: 'Registration successful!',
    registerError: 'An error occurred. Please, try again',
    registerErrorDataExists: 'This record already exists in our databases',
    register: 'Signup',
    login: 'Login',
    passwordConfirm: 'The passwords do not match!',
    language: 'language',
    acquisitionChannel: 'How did you find us?'
  },

  card: {
    lugarh: {
      title: 'Talk to an expert and find out the best plans for your company',
      subtitle:
        'To discover the plans that best meet your needs, we invite you to speak to our experts, who are ready to help you in a personalized way.',
      ctaButton: 'Know the plans',
      backButton: 'Back'
    }
  },
  selectableList: {
    lugarh: {
      searchInputPlaceholder: 'Search by name',
      selectAllLabel: 'Select all',
      notFound: 'No results for this search'
    }
  },
  workspaceForm: {
    lugarh: {
      fetchUsersError: 'Failed to fetch users',
      placeholder: {
        name: 'Workspace Name',
        slug: 'Workspace Nickname',
        cnpj: 'CNPJ',
        postcode: 'Postcode',
        address_line1: 'Address Line 1',
        address_line2: 'Address Line 2',
        city: 'City',
        region: 'Region',
        country: 'Country'
      },
      addAddressLabel: 'Add Address',
      submitButton: {
        stepOne: 'Continue',
        stepTwo: 'Create',
        loading: 'Processing...'
      },
      errors: {
        'Nome do workspace deve ter no máximo 80 caracteres':
          'Workspace name must have a maximum of 80 characters',
        'Nome do workspace é obrigatório': 'Workspace name is required',
        'CNPJ deve conter apenas números': 'CNPJ must contain only numbers',
        'CNPJ deve ter 14 dígitos': 'CNPJ must have 14 digits',
        'CEP é obrigatório': 'Postcode is required',
        'Endereço é obrigatório': 'Address is required',
        'Cidade é obrigatória': 'City is required',
        'País é obrigatório': 'Country is required',
        'Adicione pelo menos um usuário': 'Add at least one user',
        'É obrigatório adicionar usuários': 'It is mandatory to add users'
      }
    }
  },
  editUserForm: {
    lugarh: {
      fetchWorkspacesError: 'Failed to fetch workspaces',
      placeholder: {
        firstname: 'First Name',
        lastname: 'Last Name',
        password: 'Password',
        email: 'E-mail',
        role: {
          select: 'Select a role',
          admin: 'Administrator',
          manager: 'Manager',
          recruiter: 'Recruiter'
        }
      },
      submitButton: {
        stepOne: 'Continue',
        stepTwo: 'Send',
        loading: 'Processing...'
      },
      errors: {
        'Required field': 'Required Field',
        'Password must have at least 6 characters': 'Min. 6 characters',
        'Select at least one workspace': 'Select at least one workspace',
        'Workspace is a required field': 'Workspace is a required field'
      }
    }
  },
  createWorkspaceModal: {
    lugarh: {
      title: 'Create Workspace',
      subtitle: 'Insert the information below to proceed',
      stepOne: '1. Basic Info',
      stepTwo: '2. Users'
    }
  },
  createUserModal: {
    lugarh: {
      title: 'Create User',
      subtitle: 'Insert the information below to proceed',
      stepOne: '1. User info',
      stepTwo: '2. Choose workspace'
    }
  },
  editUserModal: {
    lugarh: {
      title: 'Edit User',
      subtitle: 'Insert the information below to proceed',
      stepOne: '1. User info',
      stepTwo: '2. Choose workspace'
    }
  },
  changeEnvironmentModal: {
    lugarh: {
      title: 'Workspaces',
      subtitle: 'Choose a workspace to work',
      actions: {
        change: 'Change Workspace',
        manage: 'Manage Workspace'
      }
    }
  },
  singleSelectableList: {
    lugarh: {
      placeholder: {
        search: 'Search by name'
      }
    }
  },
  moveWorkspaceModal: {
    lugarh: {
      modalTitle: 'Move Job to Another Workspace',
      modalSubtitle: 'Select the workspace you want to move the job to',
      submitButton: 'Move Job'
    }
  },
  resetPassword: {
    lugarh: {
      form: {
        title: 'Reset Password',
        password: {
          show: 'Show',
          hide: 'Hide'
        },
        newPassword: {
          placeholder: 'New Password'
        },
        confirmNewPassword: {
          placeholder: 'Confirm New Password'
        },
        submitButton: 'Change Password',
        errors: {
          newPasswordMin: 'Must be at least 6 characters',
          newPasswordRequired: 'New password is required',
          confirmNewPasswordRequired: 'Confirm new password is required',
          confirmNewPasswordMatch: 'Passwords must be the same'
        },
        goToLogin: 'Back to login screen'
      },
      resetPasswordFail: 'Failed to reset password.',
      resetPasswordSuccess: 'Password changed successfully.'
    }
  },
  forgotPassword: {
    lugarh: {
      form: {
        title: 'Forgot my password',
        email: {
          placeholder: 'E-mail'
        },
        submitButton: 'Recover password',
        errors: {
          emailRequired: 'E-mail is required',
          emailInvalid: 'Invalid e-mail'
        },
        goToLogin: 'Back to login screen'
      }
    },
    metatags: {
      title: 'Lugarh | R&S',
      url: 'https://recrud.bne.com.br',
      description:
        'Recrud is better than a ATS. Recrud is the next generation of candidate relationship management'
    },
    welcome: 'Welcome to',
    firstParagraph: "Manage your company's selection processes",
    secondParagraph: 'in a simple and intelligent way!',
    forgotPassword: 'Forgot password',
    cardParagraph: 'Enter your email to receive password change instructions',
    recoveryPassword: 'Recuperar senha',
    emailValidationError: 'Please insert a valid email',
    emailNotFound: 'Email not registered, try again',
    sendEmailFail: 'Failed to send recovery email',
    sendEmailSuccess: 'Recovery email sent successfully'
  },
  plans: {
    metatags: {
      title: 'string',
      description: 'string',
      url: 'string'
    },
    selectPlanTitle: 'Select Plan',
    plansCardBasic: {
      title: 'Basic',
      subTitle: 'For companies with few vacancies',
      planValue: 'R$29,90',
      planPeriod: '/month',
      feat1: 'Up to 2 vacancies per month',
      feat2: 'Unlimited users',
      feat3: 'Unlimited applicants'
    },
    plansCardSmart: {
      title: 'Smart',
      subTitle: 'For companies with medium volume of vacancies',
      planValue: 'R$49,90',
      planPeriod: '/month',
      feat1: 'Up to 5 vacancies per month',
      feat2: 'Unlimited users',
      feat3: 'Unlimited applicants'
    },
    plansCardAdvanced: {
      title: 'Advanced',
      subTitle: 'For a larger volume of vacancies',
      planValue: 'R$69,90',
      planPeriod: '/month',
      feat1: 'Up to 10 vacancies per month',
      feat2: 'Unlimited users',
      feat3: 'Unlimited applicants'
    },
    plansCardEnterprise: {
      title: 'Enterprise',
      subTitle: 'For a larger volume of vacancies',
      planValue: 'Customized quotation',
      planPeriod: '',
      feat1: 'Unlimited vacancies',
      feat2: 'Unlimited users',
      feat3: 'Unlimited applicants',
      selectButton: 'Talk to an expert'
    },
    selectButton: 'Get started now'
  },
  checkoutPlan: {
    metatags: {
      title: 'string',
      description: 'string',
      url: 'string'
    },
    companyFormTitle: 'Company data',
    paymentFormTitle: 'Payment method',
    creditCardLabel: 'Credit Card',
    cardNumber: 'Card number',
    cvv: 'CVV',
    expirationMonth: 'Expiration month',
    expirationYear: 'Expiration year',
    cardHolderName: 'Card holder',
    changePlanButton: 'Change plan',
    paymentButton: 'Checkout',
    cardTokenizationError: 'Check your credit card details',
    iugoCardTokenizationFailure:
      'An internal error occurred while processing the card',
    billingAddressFromTitle: 'Billing Address',
    phone: 'Phone',
    cpfCnpj: 'CPF/CNPJ',
    zipcode: 'Zipcode',
    district: 'District',
    street: 'Address',
    number: 'Number',
    complement: 'Complement',
    city: 'City',
    state: 'State'
  },
  myProfile: {
    banner: {
      title: 'Edit Profile',
      text: 'Edit your personal information, passwords and more!'
    },
    form: {
      firstname: 'First name',
      lastname: 'Last name',
      email: 'Email',
      password: 'Password',
      confirmPassword: 'Confirm password',
      save: 'Save changes!',
      successEditMessage: 'Changes saved successfully!'
    },
    confirmationModal: {
      wrongPassword: 'Incorrect password',
      failToSend: 'Failed to send email',
      passwordConfirmationSucess:
        'Password confirmed. A code has been sent to your new email',
      unavailable: 'It is not possible to perform the operation at this time',
      wrongCode: 'Error validating code'
    }
  },
  addCandidate: {
    lugarh: {
      title: 'Add Candidate',
      subtitle:
        "This is your general data panel of your company's selection process",
      submitFormButton: 'SAVE INFORMATION',
      personalDataBox: {
        title: 'Personal data',
        firstname: 'Firstname *',
        lastname: 'Lastname *',
        birthdate: 'Birthdate',
        maritalStatus: 'Marital Status',
        dateFormat: 'MM/DD/YYYY',
        maritalStatusOptions: {
          select: 'Select',
          divorced: 'Divorced',
          married: 'Married',
          single: 'Single',
          widowed: 'Widowed',
          other: 'Other'
        },
        sex: 'Gender',
        sexOptions: {
          female: 'Female',
          male: 'Male',
          other: 'Other'
        },
        phone: 'Telephone',
        email: 'E-mail',
        geoLocation: 'Location *',
        cityRelocation: 'Are you available for relocation?',
        cityRelocationOptions: {
          yes: 'Yes',
          no: 'No'
        },
        salary_expectation: 'Salary Expectation',
        linkedin_profile: 'LinkedIn',
        facebook_profile: 'Facebook',
        twitter_profile: 'Twitter',
        origin: 'Candidate Origin',
        about: 'About *'
      },
      languageBox: {
        title: 'Language',
        language: 'Language *',
        level: 'Level *',
        levelOptions: {
          basic: 'Basic',
          intermediate: 'Intermediate',
          fluent: 'Fluent',
          native: 'Native'
        },
        button: 'Add new language',
        deleteButton: 'Delete'
      },
      educationBox: {
        title: 'Education',
        course: 'Course *',
        institution: 'School',
        startDate: 'Start Date *',
        endDate: 'End Date',
        level: 'Level *',
        dateFormat: 'MM/DD/YYYY',
        levelOptions: {
          incomplete_middleschool: 'Elementary school',
          middleschool: 'Middle school',
          incomplete_highschool: 'Ensino medio Incompleto',
          highschool: 'High school',
          undergraduate: 'Undergraduate degree',
          postgraduate: 'Graduate school',
          masters: 'Master degree',
          doctorate: 'Doctorate degree'
        },
        description: 'About',
        button: 'Add new course',
        deleteButton: 'Delete'
      },
      certificateBox: {
        title: 'Certificados e conquistas',
        name: 'Name *',
        hours: 'Hours *',
        button: 'Add new achievement',
        deleteButton: 'Delete'
      },
      experienceBox: {
        title: 'Professional experience',
        role: 'Role *',
        company: 'Company',
        startDate: 'Start date *',
        endDate: 'End date *',
        description: 'Description of activities',
        currentJob: 'I am currently working at this company *',
        button: 'Add new experience',
        deleteButton: 'Delete',
        dateFormat: 'MM/DD/YYYY'
      },
      otherInformationBox: {
        title: 'Other informations',
        other_info: 'Other informations'
      },
      attachmentsBox: {
        title: 'Upload files',
        button: 'Add attachment',
        attachmentError:
          'Only PDF, PNG, JPEG and JPG files with a maximum of 3mb are allowed.',
        underlineText: 'Click to upload',
        description: 'or drag and drop the file here',
        fileTypes: 'PDF, PNG or JPG (max. 3MB)'
      },
      messages: {
        success: 'Candidate added successfully!'
      },
      submitButton: 'Submit'
    },
    button: {
      addAttachments: 'Add file'
    },
    banner: {
      title: 'Add Candidate',
      text: 'Add candidate to database'
    },
    form: {
      email: 'E-mail',
      firstname: 'First name',
      lastname: 'Last name',
      about: 'About',
      geoLocation: 'Location',
      phone: 'Telephone',
      maritalStatus: 'Marital Status',
      sex: 'Gender',
      birthdate: 'Birthdate',
      salaryExpectation: 'Salary Expectation',
      cityRelocation: 'Are you available for relocation?',
      linkedinProfile: 'Linkedin',
      facebookProfile: 'Facebook',
      twitterProfile: 'Twitter',
      save: 'Save!',
      successMessage: 'Record successfully inserted!'
    },
    titleBox: {
      personalData: 'Personal data',
      language: 'Language',
      education: 'Education',
      certificatesAndAchievements: 'Certificates and achievements',
      experience: 'Experience',
      otherInformation: 'Other information',
      attachments: 'Upload files'
    },
    languageBox: {
      language: 'Language',
      level: 'Level'
    },
    educationBox: {
      course: 'Course',
      institution: 'School',
      startDate: 'Start date',
      endDate: 'End date',
      level: 'Level',
      about: 'About'
    },
    certificateBox: {
      name: 'Name',
      hours: 'Hours'
    },
    experienceBox: {
      role: 'Role',
      company: 'Company',
      startDate: 'Start date',
      endDate: 'End date',
      currentJob: 'Current job',
      description: 'Activity description'
    },
    otherInformation: 'Other information',
    attachmentSubTitleDescription:
      'Please attach supporting documents. [E.g.: Resume, CV and/or Cover letter]',
    attachmentSubTitleFileDescription: 'Attachments',
    attachmentError:
      'Only PDF, PNG, JPEG and JPG files with a maximum of 5mb are allowed.',
    sexOptions: {
      female: 'Female',
      male: 'Male',
      other: 'Other'
    },
    maritalStatusOptions: {
      select: 'Select',
      divorced: 'Divorced',
      married: 'Married',
      single: 'Single',
      widowed: 'Widowed',
      other: 'Other'
    },
    cityRelocationOptions: {
      yes: 'Yes',
      no: 'No'
    }
  },
  candidateDetail: {
    movedTo: 'moved to {{stageName}} by {{authorName}}.',
    appliedTo: 'applied to job.',
    current: 'Present'
  },
  companySettings: {
    save: 'Save changes!',
    banner: {
      title: 'Company Settings',
      text: 'Edit company information, talent page, sensitive data and more!'
    },
    color: {
      companyPageHeaderColor: 'Careers Page header color',
      companyPageFontColor: 'Careers Page font color',
      companyPageButtonColor: 'Careers Page buttons color',
      companyPageButtonFontColor:
        'Careers Page buttons font color                  '
    },
    careerPageSetting: {
      title: 'Career page Settings',
      showHomeButtonTitle: 'Show home button',
      showHomeButtonText:
        'Decide whether the candidate will see the home button.',
      showMainPageTitle: 'Show main page',
      showMainPageText:
        'Decide whether the candidate will be able to access the main page.',
      careerPageLabel: 'Active?'
    },
    lgpd: {
      title: 'GDRP / Sensitive data / PII',
      label: {
        question: 'Active?',
        required: 'Required?'
      },
      askPhoneTitle: 'Ask for phone number',
      askPhoneText:
        'Decide whether the candidate will provide the phone number and whether you will handle that information.',
      civilStatusTitle: 'Ask for Marital status',
      civilStatusText:
        'Decide whether the candidate will inform your marital status and whether you will handle that information.',
      askGenderTitle: 'Ask for Gender',
      askGenderText:
        'Decide whether the candidate will inform their gender and whether you will handle that information.',
      askBirthdateTitle: 'Ask Birthdate',
      askBirthdateText:
        'Decide whether the candidate will inform their birthdate and whether you will handle that information.',
      askWagePretensionTitle: 'Ask for Salary Claim',
      askWagePretensionText:
        'Decide whether the candidate will report their salary claim and whether you will handle that information.',
      askMovingAvailabilityTitle: 'Ask for willingness to relocate',
      askMovingAvailabilityText:
        'Decide whether the candidate will inform his willingness to relocate and whether you will handle that information.',
      askAboutLinkedinProfileTitle: 'Ask about LinkedIn profile',
      askAboutLinkedinProfileText:
        'Decide whether the candidate will report their LinkedIn Profile and whether you will handle that information.',
      askAboutFacebookProfileTitle: 'Ask about Facebook profile',
      askAboutFacebookProfileText:
        'Decide whether the candidate will report their Facebook Profile and whether you will handle that information.',
      askAboutTwitterProfileTitle: 'Ask about Twitter profile',
      askAboutTwitterProfileText:
        'Decide whether the candidate will report their Twitter Profile and whether you will handle that information.',
      termsOfUseTitle: 'Terms of use',
      termsOfUseText: 'Insert a link to the terms of use.'
    },
    companyForm: {
      name: 'Legal name',
      fancyName: 'DBA',
      cnpj: 'Company ID',
      description: 'Company description',
      location: 'Location',
      site: 'Carreers page link'
    },
    changesSaved: 'Changes saved successfully!',
    messageChangesSaved:
      'Your Company page has been changed. You can check it by visiting the link:'
  },
  onboarding: {
    buttons: {
      next: 'Next',
      previous: 'Previous',
      skip: 'Skip'
    },
    dashboard: {
      finishOnboardingButton: 'Finish Onboarding Tour',
      content: [
        {
          title: 'Onboarding Tour',
          text: 'As this is your first time here, we will help you with this simple tutorial so that you get to know the Recrud better and can explore it in the best way.'
        },
        {
          title: 'The navigation bar',
          text: "This is Recrud's  navigation bar. Here you can access your vacancies, candidate bank and return to the dashboard."
        },
        {
          title: 'The search bar',
          text: 'If you want to find specific Jobs or Candidates you can search here and save time.'
        },
        {
          title: 'The Admin panel',
          text: "If you are an administrator, here you can configure your company's page, manage your hiring flow and add or remove users from your team. <br /> <br /> If you are a recruiter or manager, here you you can edit your profile and change your password."
        },
        {
          title: 'The Dashboard',
          text: 'Track your hiring process data via the dashboard, such as average hiring time, numbers of your candidate and more.'
        },
        {
          title: 'The Recrud',
          text: 'You now know the initial part of the system well. Navigate to the other pages to continue the tutorial.'
        }
      ]
    },
    jobs: {
      finishOnboardingButton: 'Finish the tour through the Jobs page',
      content: [
        {
          title: 'Jobs',
          text: "This is the jobs panel. All of your active Jobs will be displayed on your company page so that candidates can apply. Let's see a little more about this page."
        },
        {
          title: 'Creating a new Job',
          text: 'Need to create a new job? To register with Recrud just click here and fill out the form.'
        },
        {
          title: 'Job status',
          text: 'A job can be activated or stopped, and this bar will indicate its status. If it is green, your job is active, if it is red, your job is stopped. <br /> Candidates will only be able to apply for active jobs.'
        },
        {
          title: 'Team',
          text: 'One or more recruiters can manage a job, as well as one or more managers can analyze candidates. You can add or remove a recruiter or manager to the team by editing your job.'
        },
        {
          title: 'Candidates',
          text: 'Manage the candidates who signed up for this job, to continue your recruitment and selection process.'
        },
        {
          title: 'Sharing a job',
          text: 'You can share your job page so that candidates can apply. Just use the links available for each type of social network (Facebook, Twitter, Linkedin, etc.) that you can find here in the “share” button. Use the specific links for each order, so that it will be possible to track the best sources of candidates for your company.'
        },
        {
          title: 'Editing a job',
          text: 'Change job information and add or remove recruiters to be part of the team for the job recruitment process.'
        },
        {
          title: 'Filtering jobs',
          text: 'If you have a lot of jobs and want to find them quickly, just use the filter panel.'
        },
        {
          title: 'Changing the visualization type',
          text: 'Do you want to view your jobs in another format? Choose here if you want to see jobs on cards or in list format.'
        }
      ]
    },
    cadidatesDB: {
      finishOnboardingButton: 'Finish the Candidate Database tour',
      content: [
        {
          title: 'Candidates',
          text: 'This is your candidate database. Here are all candidates who applied regardless of the jobs they applied for.'
        },
        {
          title: 'Sorting candidates',
          text: 'If you want to sort your candidates to facilitate your search, check here the sorting options.'
        },
        {
          title: 'Filtering candidates',
          text: 'Do you have many candidates? You can filter on this side panel by specific fields regarding education, experience and etc..'
        },
        {
          title: 'Previewing the resume',
          text: 'You do not need to access the candidate page one by one. Click here to view a summary of the information to facilitate your assessment.'
        },
        {
          title: 'Accessing the details',
          text: 'If the preview was not enough you can access all the information of this candidate by clicking here for his full resume and his history.'
        },
        {
          title: 'Applying the candidate to a job',
          text: 'If you want to add this candidate to a Job, you can do it here.'
        }
      ]
    },
    candidateDetail: {
      finishOnboardingButton: 'Finish the candidate detail tour',
      content: [
        {
          title: 'Detailing the candidate',
          text: 'Here you will find all the detailed information about a candidate, including history of applications, recruiters notes and much more.'
        },
        {
          title: 'History',
          text: "In the candidate's history you can check all the hiring steps that the candidate went through in a hiring process."
        },
        {
          title: 'Moving forward with the candidate',
          text: 'If you have permission to manage this process, you can move the candidate to other steps of the hiring process.'
        },
        {
          title: 'Candidate evaluation',
          text: "You can view a candidate's review by a recruiter or manager."
        },
        {
          title: 'Reviewing a candidate',
          text: 'To rate the candidate, just use the stars.'
        },
        {
          title: 'Adding notes',
          text: 'If you want to register information regarding the candidate, use the text box to leave it registered'
        }
      ]
    },
    candidates: {
      finishOnboardingButton: 'Finish the canditate list tour',
      content: [
        {
          title: 'Candidates',
          text: 'These are the candidates who applied for a job. Here you can manage them to fulfill the tasks of your hiring flow.'
        },
        {
          title: 'Hiring workflow steps',
          text: 'This bar displays all the steps in the workflow assigned to this vacancy. Here you can filter your candidates by step. Click on a step to see the candidates.'
        },
        {
          title: 'Sorting candidates',
          text: 'If you want to sort your candidates to facilitate your search, check here the sorting options.'
        },
        {
          title: 'Filtering candidates',
          text: 'Do you have many candidates? You can filter on this side panel by specific fields regarding education, experience and etc..'
        },
        {
          title: 'Previewing the resume',
          text: 'You do not need to access the candidate page one by one. Click here to view a summary of the information to facilitate your assessment.'
        },
        {
          title: 'Accessing the details',
          text: 'If the preview was not enough you can access all the information of this candidate by clicking here for his full resume and his history.'
        },
        {
          title: 'Moving candidates',
          text: 'If you have already decided to move a candidate to another step you can do this here.'
        }
      ]
    },
    companySettings: {
      finishOnboardingButton: 'Finish the company settings tour',
      content: [
        {
          title: 'Company Settings',
          text: "Set up your Company's Career Page on Recrud here. This page will be used to share your jobs to the world."
        },
        {
          title: 'Main Data',
          text: "Start by adding the main data. Don't worry, only the company name, location and description will be displayed on the company page."
        },
        {
          title: 'Company logo',
          text: 'Add the company logo to compose the page header. We indicate that it has a minimum size of 300px in width and that the background is transparent to obtain a better result.'
        },
        {
          title: 'Configure company colors',
          text: "Configure the color of the header, texts and buttons on the company page. We recommend that you do not choose too light colors for the text on the page so that it does not impair users' reading."
        },
        {
          title: 'Sensitive data',
          text: 'Choose which additional information you want to ask candidates when applying to a job.'
        }
      ]
    },
    companyTeam: {
      finishOnboardingButton: 'Finish the Team Management tour',
      content: [
        {
          title: "Your company's team",
          text: "Here you can manage your company's users, set permissions"
        },
        {
          title: 'Users',
          text: 'Each card represents a user. Users can be administrators, managers and recruiters. <br /> Administrators can edit company settings, edit the hiring workflow and manage the team. <br /> Managers can only evaluate candidates <br / > Recruiters can create and edit their jobs and evaluate candidates'
        },
        {
          title: 'User status',
          text: "A user can be disabled or enabled and this bar will indicate the user's status. If it is green, the user is enabled, if it is red, the user is disabled and cannot access the platform."
        },
        {
          title: 'Disabling and enabling users',
          text: 'To disable or enable a user, just click here.'
        },
        {
          title: 'Editing a user',
          text: 'To edit a user included changing password and permissions click here.'
        },
        {
          title: 'Deleting a user',
          text: 'To delete a user click on the trash can icon.'
        },
        {
          title: 'Adding a user',
          text: 'To add a new user, just click on this button and fill out the form.'
        }
      ]
    },
    jobsEdit: {
      finishOnboardingButton: 'Finish Job Editing Tour',
      content: [
        {
          title: 'Creating and Editing a Job',
          text: 'Configure your Job information here.'
        },
        {
          title: 'Link to a workflow',
          text: 'You need to select a workflow for your Job to follow the steps of your hiring flow.'
        },
        {
          title: 'Screening Questions',
          text: 'You can create screening quetions to be displayed to the candidate at the time of registration. You can create single-answer, multiple-answer and open-ended questions. Single-answer questions can be marked as eliminatory, moving the candidate to the Screened Out step automatically if he answers incorrectly.'
        },
        {
          title: 'Recruiters & Managers',
          text: 'Drag available recruiters and/or managers to be able to make decisions and actions during the hiring process.'
        },
        {
          title: 'Save',
          text: 'Save the changes made.'
        }
      ]
    },
    workflow: {
      finishOnboardingButton: 'Finish Tour',
      content: [
        {
          title: 'Hiring Workflow',
          text: 'Here you can create and manage hiring workflows for any type of Jobs you have. This means that you will know exactly what steps your candidates will have to go through during the hiring process.'
        },
        {
          title: 'Edit/View workflow',
          text: 'To edit or view a workflow just click here and you will be redirected to a page with more details.'
        },
        {
          title: 'Delete Worflow',
          text: 'To delete a workflow click on the trash can. A workflow can only be deleted if it is not linked to any Job.'
        },
        {
          title: 'Add Workflow',
          text: 'To create a new workflow, just click on this button and fill out the form.'
        }
      ],
      add: 'Add new Workflow',
      update: 'Update Workflow'
    },
    workflowEdit: {
      finishOnboardingButton: 'Finish Tour',
      content: [
        {
          title: 'Hiring Workflow',
          text: 'Here you can manage the steps of your hiring workflow. The steps listed here will be available for candidates to be moved during the hiring process. This way you will be able to better control your selection process and also have a history of the steps that the candidates have gone through.'
        },
        {
          title: 'Default Steps',
          text: 'Recrud already has some default steps that cannot be deleted. The first step is Available. Each candidate who applies for a Job starts at this step. The other default steps are: Screened In, Screened Out, Offer Rejected and Hired.'
        },
        {
          title: 'Add etapa',
          text: "You can create new steps between Available and Screened Out. To do this, simply click on 'Create a new step here' and fill in the information."
        },
        {
          title: 'Custom steps',
          text: 'Custom steps are displayed in orange. '
        },
        {
          title: 'Delete step',
          text: 'Custom steps can be deleted if your workflow is not linked to a Job.'
        },
        {
          title: 'Send automatic email',
          text: 'You can set Recrud to send an email to the candidates as soon as they are moved to a step. To do this, simply enable this option.'
        },
        {
          title: 'Automatic email content',
          text: 'The content of the automatic email can be changed by clicking this button. You can edit it as you like.'
        }
      ]
    }
  },
  jobCard: {
    candidates: 'Candidates',
    createNewVacancy: 'Create New Job',
    edit: 'Edit',
    move: 'Move',
    duplicate: 'Duplicate',
    share: 'Share',
    team: 'Team',
    managers: 'Manager',
    recruiter: 'Recruiter',
    noteam: 'No team selected',
    toggleJob: 'Pause/Enable Job',
    overdueJob: 'Overdue job',
    closedJob: 'Closed job',
    stopJob: 'Pause',
    startJob: 'Start'
  },
  teamManagement: {
    title: 'Team management',
    text: 'Create and edit users for your company',
    blockModal: {
      title: 'Are you sure you want to disable this user?',
      description:
        'The user will no longer be able to use Recrud while disabled.',
      undo: 'You can undo this action at any time',
      confirm: 'Yes, disable!',
      success: 'User successfully disabled!'
    },
    unblockModal: {
      title: 'Are you sure you want to enable this user?',
      description: 'The user can use Recrud while enabled.',
      undo: 'You can undo this action at any time',
      confirm: 'Yes, enable!',
      success: 'User successfully enabled!'
    },
    deleteModal: {
      title: 'Are you sure you want to remove this user?',
      description: 'The user will no longer be able to use Recrud.',
      undo: 'This action cannot be undone',
      confirm: 'Yes, remove!',
      success: 'User removed successfully!'
    },
    editModal: {
      success: 'User updated successfully!'
    },
    addModal: {
      success: 'User created successfully!'
    }
  },
  teamCard: {
    createNewUser: 'Add new user',
    edit: 'Edit',
    block: 'Disable',
    reactivate: 'Enable',
    delete: 'Delete',
    profileOptions: {
      admin: 'Administrator',
      manager: 'Manager',
      recruiter: 'Recruiter'
    },
    modalWorkspacesTitle: 'Related Workspaces',
    modalWorkspacesSubtitle: 'See which workspaces this user is related to.'
  },
  userModal: {
    edit: {
      title: 'Edit User',
      confirm: 'Edit User!'
    },
    add: {
      title: 'Add new user',
      confirm: 'Add user!'
    },
    firstname: 'First name',
    lastname: 'Last name',
    email: 'E-mail',
    password: 'Password',
    profile: 'Profile',
    profileOptions: {
      admin: 'Administrator',
      manager: 'Manager',
      recruiter: 'Recruiter'
    },
    emailEdit: '',
    clickHere: ''
  },
  hiringStages: {
    text: 'Click on a step to see the candidates who are on that step.'
  },
  jobHeader: {
    firstOption: 'Past due jobs',
    secondOption: 'Active',
    thirdOption: 'Stopped',
    fourthOption: 'All',
    searchLabel: 'Filter by workspaces',
    selectLabel: 'Filter by'
  },
  candidateHeader: {
    lugarh: {
      title: 'General candidate base',
      subtitle: 'This is your company’s general resumes database',
      filter: 'FILTER',
      addCandidate: 'ADD CANDIDATE',
      export: 'EXPORT',
      applyCandidates: 'APPLY TO ROLE',
      candidate: {
        apply: 'APPLY TO ROLE',
        detail: 'DETAILS',
        preview: 'PREVIEW'
      },
      candidateListHeaders: {
        name: 'NAME',
        resumes: 'RESUMES',
        apply: 'APPLY TO ROLE'
      }
    },
    title: "Showing candidates for '{{opportunity}}' in the step '{{stage}}'",
    databaseTitle: 'Candidate Database',
    orderBy: 'Sort by',
    moveSelected: 'Move selected candidates to',
    orderByOptions: {
      created_at: 'Application date',
      name: 'Candidate name',
      firstname: 'Candidate name',
      review: 'Average Score',
      applicationDate: 'Application date'
    }
  },
  candidateList: {
    select: 'Move to step',
    review: 'Average Score',
    preview: 'Preview',
    detail: 'Detail',
    apply: 'Apply to job',
    add: 'Add',
    addCandidate: 'Add Candidate'
  },
  select: {
    acquisitionChannel: {
      0: 'Select'
    },
    vacancyAllocation: {
      0: 'Select',
      1: 'Hybrid',
      2: 'Presential',
      3: 'Remote',
      4: 'Unspecified'
    },
    education: {
      0: 'Incomplete Middle School',
      1: 'Middle School',
      2: 'Incomplete High School',
      3: 'High School',
      4: 'Undergraduate',
      5: 'Post Graduate',
      6: 'Master’s',
      7: 'Doctorate'
    },
    language: {
      0: 'Basic',
      1: 'Advanced',
      2: 'Fluent',
      3: 'Native'
    }
  },
  content: {
    whatsrecrud: {
      title: 'What is the Recrud?',
      subtitle: 'Lorem ipsum'
    },
    privacypolicy: {
      title: 'Privacy Policy',
      subtitle: 'Lorem ipsum'
    },
    terms: {
      title: 'Terms',
      subtitle: 'Lorem ipsum'
    }
  },
  candidatePreview: {
    about: 'About',
    education: 'Education',
    experience: 'Experience',
    languages: 'Languages',
    closePreview: 'Close Preview',
    educationIn: 'in',
    current: 'current',
    basic: 'Basic',
    intermediate: 'Intermediate',
    fluent: 'Fluent',
    native: 'Native',
    single: 'Single',
    married: 'Married',
    divorced: 'Divorced',
    widowed: 'Widowed',
    other: 'Other',
    male: 'Male',
    female: 'Female'
  },
  languageLevelOptions: {
    basic: 'Basic',
    intermediate: 'Advanced',
    fluent: 'Fluent',
    native: 'Native'
  },
  candidateFilter: {
    modalTitle: 'Filter Candidates',
    getDataError:
      'There was a problem fetching some information. Please try to reload the page.',
    genderOptions: {
      other: 'Other',
      male: 'Male',
      female: 'Female'
    },
    civilStatusOptions: {
      single: 'Single',
      married: 'Married',
      divorced: 'Divorced',
      widowed: 'Widowed',
      other: 'Other'
    },
    availableToMoveOptions: {
      true: 'Yes',
      false: 'No'
    },
    languageLevelOptions: {
      basic: 'Basic',
      intermediate: 'Advanced',
      fluent: 'Fluent',
      native: 'Native'
    },
    educationLevelOptions: {
      incomplete_middleschool: 'Elementary school',
      middleschool: 'Middle school',
      incomplete_highschool: 'Ensino medio Incompleto',
      highschool: 'High school',
      undergraduate: 'Undergraduate degree',
      postgraduate: 'Graduate school',
      masters: 'Master degree',
      doctorate: 'Doctorate degree'
    },
    buttons: {
      filter: 'Filter',
      filterCandidate: 'Filter Candidates',
      applyFilters: 'Apply filters'
    },
    general: {
      title: 'General',
      clearFilters: 'Clear Filters',
      location: 'Location',
      age: {
        min: 'Age min',
        max: 'Age max'
      },
      gender: 'Gender',
      civilStatus: 'Marital Status',
      salary: {
        min: 'Exp Salary min',
        max: 'Exp Salary max'
      },
      availableToMove: 'Willing to relocate?',
      workspacesMltiselect: 'Workspaces'
    },
    education: {
      title: 'Education',
      name: 'School',
      course: 'Course',
      level: 'Level'
    },
    experience: {
      title: 'Experience',
      company: 'Company',
      position: 'Job Title',
      timeInYears: 'Tenure in years'
    },
    language: {
      title: 'Language',
      name: 'Language',
      level: 'Level of proficience'
    }
  },
  aboutContainer: {
    goBack: 'Back',
    about: 'About Me',
    education: 'Education',
    experience: 'Experience',
    otherInfo: 'Other information',
    birthdate: 'Birthdate',
    languages: 'Languages',
    certificates: 'Licenses & certifications',
    certificateHours: '{{hours}} hours',
    personalInfo: 'Personal Information',
    yes: 'Yes',
    not: 'No',
    email: 'E-mail',
    phone: 'Phone',
    localization: 'Location',
    maritalStatus: 'Marital status',
    gender: 'Gender',
    salaryExpectation: 'Salary expectation',
    possibilityChange: 'Willing to relocate',
    attachedFiles: 'Attached Files'
  },
  workflowCard: {
    create: 'Create new workflow',
    edit: 'Rename',
    view: 'Edit',
    noWorkspaces: 'No Workspaces',
    tooltip: 'You cannot delete this workflow as there are jobs linked to it.',
    modalWorkspacesTitle: 'Related Workspaces',
    modalWorkspacesSubtitle: 'See the workspaces that are using this workflow'
  },
  jobOpportunity: {
    addJobTitle: 'New job',
    addJobText: 'Fill out the form below to add a new job',
    editJobTitle: 'Edit job',
    editJobText: 'Edit job ',
    name: 'Job name',
    roleFamily: 'Related Jobs',
    roleFamilyPlaceholder:
      'Related Jobs (Start typing to see list of available related roles)',
    localization: 'Job Location',
    amount: 'Open positions',
    deadline: 'Fill by date',
    vacancy_allocation: 'Vacancy Allocation',
    budget: 'Job budget',
    tags: 'Job tags',
    description: 'Job Description',
    applyJobOpportunity: 'Apply to Job',
    applyCandidate: 'Apply candidate!',
    jobNameLabel: 'Job name',
    applyToStep: 'Apply to step',
    success: 'Candidate successfully applied!',
    chooseOne: 'Choose a job',
    noJobFound: 'No job found'
  },
  recruiterNotes: {
    newNote: 'Write additional details here...',
    notes: 'Notes',
    note: 'note',
    new: 'new',
    by: 'by {{name}}',
    review: 'Rate this candidate for the job {{jobName}}',
    notAllowed:
      'You can only insert a note about this candidate in the workspace where they were applied.'
  },
  historyContainer: {
    title: 'History',
    description: 'candidates application history',
    appliedForJob: 'applied to',
    onDate: 'in {{date}}',
    onWorkspace: 'in workspace'
  },
  recruiterAnswers: {
    title: 'Answers to customized job questions'
  },
  job: {
    banner: {
      title: 'Create Job',
      text: ''
    },
    bannerEdit: {
      title: 'Editar vaga',
      text: 'Editar Vaga de '
    },
    updatedMessage: 'Successfully updated!',
    form: {
      name: 'Job Name',
      location: 'Job Location',
      deadline: 'Fill By date',
      vacancy_allocation: 'Vacancy Allocation',
      tags: 'Tags',
      roleFamilyPlaceholder:
        'Related Jobs (Start typing to see list of available related roles)',
      amount: 'Open positions',
      budget: 'Budget',
      status: 'Activate job?',
      description: 'Job Description'
    },
    linkWorkflow: 'Link to a workflow',
    questions: 'Screening questions',
    questionsInfo:
      'There are already candidates who applied for this vacancy, so the questions cannot be changed.',
    recruiters: 'Available recruiters',
    recruitersTeam: 'Selected recruiters',
    managers: 'Available Managers',
    managersTeam: 'Selected Managers',
    save: 'Save changes!',
    creationFailed:
      'Failed to create the job, fill in the required fields and try again',
    creationSuccess: 'Job created successfully',
    missingWorkflow:
      'Failed to create the job. Select a workflow and try again',
    updateStatusfalse: 'The job was successfully paused!',
    updateStatustrue: 'The vacancy was successfully enabled!',
    modalTitle: 'Confirmation',
    modalTextfalse: 'Do you want to pause the job?',
    modalTexttrue: 'Do you want to enable the job',
    yes: 'Yes',
    no: 'No',
    tagError: 'You have reached the maximum limit of 34 characters.'
  },
  jobShare: {
    shareManually: 'Manual share',
    shareWith: 'Share on {{socialMedia}}',
    message:
      '<p>Click <copy>here</copy> to copy the link to the clipboard and paste where you want to share.</p>',
    url: 'Or use this link: ',
    onCopyingSuccessfully: 'Link successfully copied.'
  },
  workflowStepCard: {
    create: 'Create new step here'
  },
  hiringWorkflow: {
    bannerTitle: 'Hiring Workflow',
    bannerText: 'Manage your companys hiring workflows',
    addNewStage: 'Add new step',
    stageName: 'Step name',
    stageDescription: 'Step description',
    sendEmailAutomatically: 'Send email automatically?',
    stageEmailContent: 'Email content',
    createHiringStage: 'Save workflow step',
    emailSubject: 'E-mail subject'
  },
  listWorkflowsContainer: {
    modalTitle: 'Confirm',
    modalText: 'Do you really want to <strong>delete</strong> this workflow?',
    successDeleteMessage: 'Workflow removed successfully!',
    successCreateMessage: 'Workflow created successfully!',
    successUpdateMessage: 'Workflow updated successfully!',
    successHiringStageCreateMessage: 'Step created successfully!',
    successDuplicateMessage: 'Workflow duplicated successfully',
    yes: 'Yes',
    no: 'No',
    lockedDeletion:
      'It is not possible to delete the workflow because it is associated with a Job',
    copy: 'copy'
  },
  hiringWorkflowEdit: {
    bannerTitle: 'Workflow ',
    bannerText: 'Edit hiring workflow',
    firstP:
      'Register the flow of the selection process for your vacancy here. You can associate this workflow with a vacancy, and the steps can be used to filter and organize candidates. Remember, with the hiring flow, you will know where each candidate is in your process. If you have questions about how to use Workflow ',
    button: 'click here'
  },
  editWorkdflowContainer: {
    blocked: "Default step can't remove",
    editEmail: 'Edit e-mail',
    stage: 'WORKFLOW STEP',
    sendEmail: 'SEND E-MAIL AUTOMATICALY?',
    emailContent: 'E-MAIL CONTENT',
    action: 'ACTION',
    yes: 'Yes',
    no: 'No',
    confirm: 'Confirm',
    deleteContent: 'You really want to <strong>detele</strong> this step ',
    successDeleteMessage: 'Step deleted successfully!',
    editEmailModalTitle: 'Edit step e-mail',
    editEmailModalSubTitle:
      'This email will be sent when the candidate is moved to ',
    editEmailModalSubject: 'Subject',
    editEmailModalContent: 'Email body',
    createStageTitle: '+ Add new step',
    createStageName: 'Step name',
    createStageDescription: 'Step description',
    createStageSendEmail: 'Automatically send email?',
    createStageEmailContent: 'Email content',
    createStageButton: 'Save hiring step!',
    successHiringStageCreateMessage: 'Step created successfully!',
    successUpdateAutoSend: 'Sending automatic email updated successfully!',
    successUpdateEmailContent: 'Email content updated successfully!',
    save: 'Save changes',
    remove: 'Delete',
    emailSubject: 'E-mail subject',
    headerTitle: 'Hiring Flow',
    emailContentRequired:
      'The feature will only be activated once the email body is added.'
  },
  notFound: {
    button: 'Voltar',
    content:
      "It looks like you took a wrong turn and found a black hole in the web that absorbed the page you were looking for! But don't panic because you can come back!",
    title: 'OOPS!!'
  },
  customQuestions: {
    question: 'Question',
    eliminate: 'Knockout?',
    unique: 'Single-answer',
    mult: 'Multiple-answer',
    opened: 'Open-ended',
    answers: 'Alternatives',
    rightAnswer: 'Correct answer',
    required: 'Required field!',
    moveUp: 'Move up',
    moveDown: 'Move down',
    duplicate: 'Make a copy',
    delete: 'Delete'
  },
  dashboard: {
    hello: 'Hi',
    seeCompanyData: 'See data from your company selection process:',
    filterByWorkflow: 'Filter by Workflow',
    filterByRecruiter: 'Filter by Recruiter',
    chart01: 'Open Jobs',
    chart02: 'Closed Jobs',
    chart1: 'Average time to fill',
    chart2: 'Average time to fill by Recruiter',
    chart3: 'Candidate source',
    chart4: 'Candidate application by day',
    chart5: 'Candidate application by age',
    chart6: 'Candidate application by gender',
    chart7: 'Candidate application by education',
    chart8: 'Candidate application by marital status',
    chart9: 'Candidate application by region',
    chart10: 'Closed job by Recruiter on time and past due jobs',
    chart11: 'Candidates by phases in the last 6 months',
    inTime: 'on time',
    late: 'Past due',
    male: 'Male',
    female: 'Female',
    other: 'Other',
    errorChart: 'Not enough data to generate the graph',
    errorCompanyData: 'Failed to load company data',
    'Solteiro(a)': 'Sinle',
    'Divorciado(a)': 'Divorced',
    'Viuvo(a)': '',
    Outro: '',
    Available: 'Available',
    'Rejected in Triage': 'Rejected in Triage',
    'Approved in Triage': 'Approved in Triage',
    'Denied Offer': 'Denied Offer',
    'Rejected during the process': 'Rejected during the process',
    'Solicitar documentos': 'Request Documents',
    'Coleta de documentos': 'Documents Collection',
    Hired: 'Hired',
    errorDataChart: 'Failed to retrieve data for some charts',
    errorExpiredSession: 'Session expired'
  },
  changePassword: {
    changePassword: 'Change password',
    password: 'Password',
    passwordConfirmation: 'Confirm password',
    changePasswordFail:
      'Password reset failed. Your password reset link has expired or is invalid',
    changePasswordSuccess: 'Password successfully reset'
  },
  defaultWorkflowStages: {
    defaultStage: "It's a default step and cannot be removed.",
    'Approved in Triage': 'Screened Out',
    Available: 'Available',
    'Failed in the selection process': 'Failed in the selection process',
    'Failed in the selection processD':
      'The candidate was eliminated in the selection process like interview, test, etc.',
    'Denied Offer': 'Offer rejected',
    Hired: 'Hired',
    'Rejected in Triage': 'Screened In',
    'Approved in TriageD': 'The candidate passed the selection process.',
    AvailableD:
      'The candidate is interested in participating in the selection process.',
    'Denied OfferD':
      'The candidate either refused the offer or withdrew from the process.',
    HiredD: 'The candidate was hired.',
    'Rejected in TriageD':
      'The candidate was eliminated in the selection process.',
    'Rejected during the process': 'Rejected during the process',
    'Rejected during the processD':
      'The candidate was rejected during the selection process.',
    'Approved in process': 'Approved in process',
    'Approved in processD':
      'The candidate was approved in the selection process.',
    'Solicitar documentos': 'Request Documents',
    'Solicitar documentosD':
      'The candidate received a link to upload documents.'
  },
  codeConfirmationModal: {
    title: 'Confirm new email',
    subtitle: 'Enter the code you received in your new email',
    label: 'Code',
    buttonText: 'Confirm Code'
  },
  passwordConfirmationModal: {
    title: 'Email change',
    subtitle: 'To change your email please confirm your password',
    label: 'Password',
    buttonText: 'Confirm password'
  },
  candidatesListContainer: {
    notfound: 'No records found.',
    select: 'Choose hiring profile'
  },
  jobsContainer: {
    notfound: 'No records found.'
  },
  searchBox: {
    loading: 'Loading...',
    notFound: 'No records found.'
  },
  subscriptionSettings: {
    headerSettigns: {
      headerTitle: 'Subscription Settings',
      headerText: 'Update or Cancel Subscription'
    },
    nextChargeTitle: 'Next Charge',
    nextChargeDescriptionDate: 'Your next billing date will be on ',
    nextChargeDescriptionValue: 'in the amount of ',
    paymentMethodTitle: 'Payment Method',
    paymentMethodType: 'Credit Card',
    paymentMethodCardNumber: 'ending with number',
    paymentMethodCardNumberExpiresAt: 'Expires at:',
    warningWithoutPaymentMethod: 'There is no payment method registered.',
    descriptionWithoutPaymentMethod:
      'You can add a payment method when you pay the next invoice and enable it for future payments.',
    invoices: {
      invoicesTitle: 'Invoices',
      expiresAt: 'Expires Date',
      value: 'Value',
      status: 'Status',
      link: 'View',
      statusPaid: 'Paid',
      statusPending: 'Pending',
      statusExpired: 'Expired',
      statusCanceled: 'Canceled',
      statusRefunded: 'Refunded'
    },
    unsubscribeEmail: {
      subject: 'Cancellation%20Request',
      body: 'Hello%2C%0A%0AI%20want%20to%20cancel%20the%20subsequent%20invoices%20for%20the%20Recrud%20recurrent%20service.%20Could%20you%20support%20me%20in%20this%20process%3F%0A%0A%20Tks',
      buttonLabel: 'Unsubscribe'
    }
  },
  candidateInfo: {
    years: 'years'
  },
  badgeList: {
    initialScale: 'Not adherent',
    finalScale: 'Totally adherent',
    candidateAssessment:
      'The final star rating is calculated as the average of evaluations submitted by all users, ranging from not adherent to excellent. This rating reflects the overall evaluation of the applicant.'
  },
  recruiterList: {
    managers: 'Managers',
    recruiters: 'Recruiters'
  },
  workspaces: {
    lugarh: {
      title: 'Workspaces',
      subtitle: 'Add, edit, or remove workspaces from your company.',
      modalListUsersTitle: 'Linked Users',
      modalListUsersSubtitle: 'See the list of people linked to this workspace',
      button: {
        create: 'Create new workspace'
      },
      table: {
        actions: 'Actions',
        soon: 'Coming soon',
        noCnpj: 'No CNPJ registered',
        head: {
          workspaces: 'Workspaces',
          users: 'Users',
          cnpj: 'CNPJ'
        },
        buttons: {
          edit: 'Edit',
          exclude: 'Delete'
        }
      }
    }
  }
}

export default en
