/* eslint-disable import/order */
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import PhoneInput from 'react-phone-number-input'
import * as Yup from 'yup'
import { Button } from 'components/Button/Button'
import { Checkbox } from 'components/Checkbox/Checkbox'
import { DatePickerInput } from 'components/DatePickerInput/DatePickerInput'
import { Form } from 'components/Form/Form'
import { FormItem } from 'components/FormItem/FormItem'
import { FormRow } from 'components/FormRow/FormRow'
import { Input } from 'components/Input/Input'
import { Select } from 'components/Select/Select'
import { WidgetBox } from 'components/WidgetBox/WidgetBox'
import { candidates } from 'services/api'
import useStore from 'store'
import * as notificationActions from 'store/notification/actions'
import { dateToTimestamp, handleDateStr } from 'utils/date'
import 'react-phone-number-input/style.css'
import { InputCity } from 'components/InputCity/InputCity'
import { TextArea } from 'components/TextArea/TextArea'

import { ReactComponent as AddIcon } from '../../assets/svg/add-circle-outline-icon.svg'
import { ReactComponent as TrashIcon } from '../../assets/svg/trash-icon.svg'
import 'react-datepicker/dist/react-datepicker.css'
import styles from './CandidateCreate.module.scss'
import {
  ValidatewDateOptions,
  useDateValidation
} from 'hooks/useDateValidation'
import moment from 'moment'

type CandidateAttachments = {
  lastModified: number
  name: string
  size: number
  type: string
  webkitRelativePath: string
}

type CandidateLanguage = {
  language: string
  fluency: string
}

type CandidateEducation = {
  description: string
  end_date: string
  start_date: string
  level: string
  institution: string
  course: string
}

type CandidateCertificate = {
  name: string
  working_hours: number
}

type CandidateExperience = {
  role: string
  company: string
  start_date: string
  end_date: string
  current_job?: boolean
  description: string
}

type FormData = {
  email: string
  firstname: string
  lastname: string
  about: string
  geo_location: string
  full_address: string
  phone?: string
  marital_status?: MaritialStatusOptions
  sex?: SexOptions
  birthdate?: string
  salary_expectation: number
  city_relocation?: boolean
  linkedin_profile?: string
  facebook_profile?: string
  twitter_profile?: string
  languages?: CandidateLanguage[]
  educational_experiences?: CandidateEducation[]
  certificates?: CandidateCertificate[]
  professional_experiences?: CandidateExperience[]
  other_info?: string | undefined
}

type MaritialStatusArray = { value: MaritialStatusOptions; label: string }[]

type MapExperiencesFunction = (
  experiences: (CandidateExperience | CandidateEducation)[] | undefined
) =>
  | (CandidateProfessionalExperiences | CandidateEducationalExperiences)[]
  | undefined

export const CandidateCreateContainer: React.FC = () => {
  const { t } = useTranslation('addCandidate')
  const { t: errorsTranslations } = useTranslation('errors')
  const {
    validateDate,
    validateStartEndDate,
    errors: dateError
  } = useDateValidation()
  const { dispatch } = useStore()
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)
  const [maritalStatusSelectTouched, setMaritalStatusSelectTouched] =
    useState(false)
  const [triedToSend, setTriedToSend] = useState(false)
  const [formData, setFormData] = useState({} as FormData)
  const [attachmentFiles, setAttachmentFiles] = useState(
    [] as Array<CandidateAttachments>
  )
  const [attachmentFilesError, setAttachmentFilesError] = useState(false)

  const maritalStatusOptions: MaritialStatusArray = [
    { value: 'divorced', label: t('maritalStatusOptions.divorced') },
    { value: 'married', label: t('maritalStatusOptions.married') },
    { value: 'single', label: t('maritalStatusOptions.single') },
    { value: 'widowed', label: t('maritalStatusOptions.widowed') },
    { value: 'other', label: t('maritalStatusOptions.other') }
  ]

  const languageFluencyOptions = [
    { value: 'basic', label: t('select:language.0') },
    { value: 'intermediate', label: t('select:language.1') },
    { value: 'fluent', label: t('select:language.2') },
    { value: 'native', label: t('select:language.3') }
  ]

  const educationLevelOptions = [
    { value: 'incomplete_middleschool', label: t('select:education.0') },
    { value: 'middleschool', label: t('select:education.1') },
    { value: 'incomplete_highschool', label: t('select:education.2') },
    { value: 'highschool', label: t('select:education.3') },
    { value: 'undergraduate', label: t('select:education.4') },
    { value: 'postgraduate', label: t('select:education.5') },
    { value: 'masters', label: t('select:education.6') },
    { value: 'doctorate', label: t('select:education.7') }
  ]

  const showNotification = (payload: NotificationConfig) =>
    dispatch(notificationActions.notify(payload))

  const createCandidateSchema = Yup.object().shape({
    email: Yup.string().email(errorsTranslations('emailInvalid')),
    firstname: Yup.string()
      .trim()
      .matches(
        /^[a-zA-Z\u00C0-\u00FF]+(?:\s+[a-zA-Z\u00C0-\u00FF]+)*$/,
        errorsTranslations('fieldInvalid')
      )
      .min(3, errorsTranslations('fieldMinLength'))
      .required(errorsTranslations('requiredField')),
    lastname: Yup.string()
      .trim()
      .matches(
        /^[a-zA-Z\u00C0-\u00FF]+(?:\s+[a-zA-Z\u00C0-\u00FF]+)*$/,
        errorsTranslations('fieldInvalid')
      )
      .min(3, errorsTranslations('fieldMinLength'))
      .required(errorsTranslations('requiredField')),
    about: Yup.string().trim().required(errorsTranslations('requiredField')),
    geo_location: Yup.string().required(errorsTranslations('requiredField')),
    phone: Yup.string(),
    marital_status: Yup.string(),
    sex: Yup.string(),
    birthdate: Yup.string(),
    salary_expectation: Yup.number(),
    city_relocation: Yup.boolean(),
    linkedin_profile: Yup.string(),
    facebook_profile: Yup.string(),
    twitter_profile: Yup.string(),
    languages: Yup.array().of(
      Yup.object().shape({
        language: Yup.string().required(t('errors:requiredField')),
        fluency: Yup.string().required(t('errors:requiredField'))
      })
    ),
    educational_experiences: Yup.array().of(
      Yup.object().shape({
        level: Yup.string().required(t('errors:requiredField')),
        course: Yup.string().required(t('errors:requiredField')),
        start_date: Yup.string().min(10).required(t('errors:requiredField')),
        end_date: Yup.string().min(10).required(t('errors:requiredField'))
      })
    ),
    certificates: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required(t('errors:requiredField')),
        working_hours: Yup.number()
          .min(1, t('errors:requiredField'))
          .required(t('errors:requiredField'))
      })
    ),
    professional_experiences: Yup.array().of(
      Yup.object().shape({
        role: Yup.string().required(t('errors:requiredField')),
        current_job: Yup.boolean(),
        start_date: Yup.string().min(10).required(t('errors:requiredField')),
        end_date: Yup.string().when('current_job', {
          is: false,
          then: () => Yup.string().min(10).required(t('errors:requiredField'))
        })
      })
    )
  })

  const getAttachment = attachment => {
    const attach = attachment.target.files[0]
    setAttachmentFilesError(false)
    if (
      attach.type === 'application/pdf' ||
      attach.type === 'image/png' ||
      attach.type === 'image/jpeg' ||
      (attach.type === 'image/jpg' && attach.size <= 5000000)
    ) {
      const attachment_file = [...attachmentFiles]
      attachment_file.push(attach)
      setAttachmentFiles(attachment_file)
    } else {
      setAttachmentFilesError(true)
      setTimeout(() => {
        setAttachmentFilesError(false)
      }, 10000)
    }
  }

  const delAttachment = index => {
    const currentAttachmentFiles = [...attachmentFiles]
    currentAttachmentFiles.splice(index, 1)
    setAttachmentFiles(currentAttachmentFiles)
  }

  const createCandidate = async (
    candidate: FormData,
    attachmentFiles: Array<any>
  ) => {
    try {
      setIsLoading(true)
      setTriedToSend(true)

      const {
        birthdate,
        educational_experiences,
        professional_experiences,
        ...candidateRemaining
      } = candidate

      const invalidBirthdate =
        birthdate && !validateDate(birthdate, ValidatewDateOptions.BIRTHDATE)
      if (invalidBirthdate) throw new Error(dateError.invalidDate || '')

      // TODO: Abstract this to a util function
      const mapExperiences: MapExperiencesFunction = experiences => {
        if (!experiences || experiences.length === 0) {
          return undefined
        }

        return experiences.map(experience => {
          let endDate = experience.end_date
          let experienceData = experience

          if ('role' in experience) {
            endDate = experience.current_job
              ? moment().format('DD/MM/YYYY')
              : experience.end_date
            const { current_job, ...rest } = experience
            experienceData = rest
          }

          const validation = validateStartEndDate(
            experience.start_date,
            endDate,
            birthdate || ''
          )
          if (!validation)
            throw new Error(dateError.startDate || dateError.endDate || '')

          return {
            ...experienceData,
            start_date: dateToTimestamp(experience.start_date),
            end_date: dateToTimestamp(endDate)
          } as
            | CandidateProfessionalExperiences
            | CandidateEducationalExperiences
        })
      }

      const createCandidatePayload: CreateCandidatePayload = {
        ...candidateRemaining,
        ...(birthdate && { birthdate: dateToTimestamp(birthdate) }),
        ...(educational_experiences && {
          educational_experiences: mapExperiences(
            educational_experiences
          ) as CandidateEducationalExperiences[]
        }),
        ...(professional_experiences && {
          professional_experiences: mapExperiences(
            professional_experiences
          ) as CandidateProfessionalExperiences[]
        })
      }

      const [error, data] = await candidates.create(createCandidatePayload)

      if (error) throw new Error(error.toString())

      if (attachmentFiles.length) {
        const attachmentPromise = attachmentFiles.map(async attachmentFile => {
          const formData = new FormData()
          formData.append('attachment', attachmentFile)

          if (data !== null) {
            await candidates.setAttachments({
              uuid: data.uuid,
              attachment: formData
            })
          }
        })

        await Promise.all(attachmentPromise)
      }

      navigate('/candidates-database')
    } catch (error: unknown) {
      const errorMessage = (error as Error).message
      showNotification({ message: errorMessage, type: 'error' })
    } finally {
      setIsLoading(false)
      setTriedToSend(false)
    }
  }

  const handleErrorMessage = (
    field: string,
    nestedField?: { name: string; index: number },
    errors?,
    values?,
    touched?
  ) => {
    if (nestedField) {
      const { name: nameNestedField, index } = nestedField
      return touched[nameNestedField] &&
        touched[nameNestedField][index] &&
        touched[nameNestedField][index][field] &&
        !values[nameNestedField][index][field]
        ? (errors?.[nameNestedField]?.[index]?.[field] as string)
        : ''
    }

    return touched[field] && !values[field] ? (errors[field] as string) : ''
  }

  return (
    <>
      <Formik
        initialValues={{
          email: '',
          firstname: '',
          lastname: '',
          about: '',
          geo_location: '',
          full_address: '',
          phone: '',
          marital_status: '',
          sex: '',
          birthdate: '',
          salary_expectation: 0,
          city_relocation: false,
          linkedin_profile: '',
          facebook_profile: '',
          twitter_profile: '',
          languages: formData.languages || [],
          educational_experiences: formData.educational_experiences || [],
          certificates: formData.certificates || [],
          professional_experiences: formData.professional_experiences || [],
          other_info: ''
        }}
        validationSchema={createCandidateSchema}
        onSubmit={() => createCandidate(formData, attachmentFiles)}
      >
        {({
          values,
          errors,
          setFieldValue,
          setFieldTouched,
          handleSubmit,
          handleBlur,
          touched,
          isValid
        }) => (
          <Form id='add-candidate-form' onSubmit={handleSubmit}>
            <WidgetBox>
              <div className='row'>
                <div className='col-12'>
                  <p className='title'>{t('titleBox.personalData')}</p>
                </div>
              </div>
              <FormRow>
                <FormItem formItemClasses='col-sm-6'>
                  <Input
                    type='text'
                    label={t('form.firstname')}
                    required={true}
                    name='firstname'
                    value={values.firstname}
                    onChange={value => {
                      setFormData({ ...formData, firstname: value })
                      setFieldValue('firstname', value)
                    }}
                    onBlur={handleBlur}
                    error={touched.firstname ? errors.firstname : undefined}
                    small
                  ></Input>
                </FormItem>

                <FormItem formItemClasses='col-sm-6 spaceUp'>
                  <Input
                    type='text'
                    label={t('form.lastname')}
                    required={true}
                    name='lastname'
                    value={values.lastname}
                    onChange={value => {
                      setFormData({ ...formData, lastname: value })
                      setFieldValue('lastname', value)
                    }}
                    onBlur={handleBlur}
                    error={touched.lastname ? errors.lastname : undefined}
                    small
                  ></Input>
                </FormItem>
              </FormRow>

              <FormRow>
                <FormItem formItemClasses='col-sm-6 spaceUp'>
                  <DatePickerInput
                    label={t('form.birthdate')}
                    name='birthdate'
                    value={values?.birthdate || ''}
                    onBlur={handleBlur}
                    onChange={value => {
                      setFormData({ ...formData, birthdate: value })
                      setFieldValue('birthdate', value)
                      validateDate(value, ValidatewDateOptions.BIRTHDATE, true)
                    }}
                    error={dateError.invalidDate ?? ''}
                    small
                  />
                </FormItem>

                <FormItem formItemClasses='col-sm-6 spaceUp'>
                  <Select
                    name='marital_status'
                    label={t('form.maritalStatus')}
                    onBlur={() => setMaritalStatusSelectTouched(true)}
                    onChange={e => {
                      setFormData({
                        ...formData,
                        marital_status: e.target.value as MaritialStatusOptions
                      })
                      setFieldValue('marital_status', e.target.value)
                    }}
                    value={formData.marital_status}
                    error={
                      formData.marital_status === undefined &&
                      (maritalStatusSelectTouched || triedToSend)
                        ? errors.marital_status
                        : ''
                    }
                  >
                    <option value=''>{t('maritalStatusOptions.select')}</option>
                    {maritalStatusOptions.map((option, index) => {
                      return (
                        <option
                          value={option.value}
                          key={`${index}-${option.value}`}
                        >
                          {option.label}
                        </option>
                      )
                    })}
                  </Select>
                </FormItem>
              </FormRow>
              <FormRow>
                <div className='col-12 col-md-6 col-sm-12 mb-3'>
                  <p className='mt-0'>{t('form.sex')}</p>
                  <div className='d-flex flex-wrap'>
                    <div className='mr-2 d-flex'>
                      <label className='radio m-0 mr-2'>
                        <input
                          type='radio'
                          name='sex'
                          checked={formData.sex === 'female'}
                          onChange={() => {
                            setFormData({ ...formData, sex: 'female' })
                            setFieldValue('sex', 'female')
                          }}
                        />
                        <span className='checkmark' />
                      </label>
                      <p className='m-0 d-flex align-items-center'>
                        {t('sexOptions.female')}
                      </p>
                    </div>

                    <div className='d-flex mr-2'>
                      <label className='radio m-0 mr-2'>
                        <input
                          type='radio'
                          name='sex'
                          checked={formData.sex === 'male'}
                          onChange={() => {
                            setFormData({ ...formData, sex: 'male' })
                            setFieldValue('sex', 'male')
                          }}
                        />
                        <span className='checkmark' />
                      </label>
                      <p className='m-0 d-flex align-items-center'>
                        {t('sexOptions.male')}
                      </p>
                    </div>

                    <div className='mr-2 d-flex'>
                      <label className='radio m-0 mr-2'>
                        <input
                          type='radio'
                          name='sex'
                          checked={formData.sex === 'other'}
                          onChange={() => {
                            setFormData({ ...formData, sex: 'other' })
                            setFieldValue('sex', 'other')
                          }}
                        />
                        <span className='checkmark' />
                      </label>
                      <p className='m-0 d-flex align-items-center'>
                        {t('sexOptions.other')}
                      </p>
                    </div>
                  </div>
                </div>
                <FormItem formItemClasses='col-sm-6 spaceUp'>
                  <PhoneInput
                    type='text'
                    name='phone'
                    value={values.phone}
                    onChange={value => {
                      setFormData({ ...formData, phone: value?.toString() })
                      setFieldValue('phone', value)
                    }}
                    onBlur={() => setFieldTouched('phone', true)}
                  />
                </FormItem>
              </FormRow>
              <FormRow>
                <FormItem formItemClasses='col-sm-6 spaceUp'>
                  <Input
                    type='text'
                    label={t('form.email')}
                    name='email'
                    value={values.email}
                    onChange={value => {
                      setFormData({ ...formData, email: value })
                      setFieldValue('email', value)
                    }}
                    onBlur={handleBlur}
                    error={touched.email ? errors.email : undefined}
                    small
                  ></Input>
                </FormItem>

                <FormItem formItemClasses='col-sm-6 spaceUp'>
                  <InputCity
                    type='text'
                    label={t('form.geoLocation')}
                    required={true}
                    name='geo_location'
                    value={values.geo_location}
                    onChange={value => {
                      setFormData({
                        ...formData,
                        geo_location: value[1],
                        full_address: value[0]
                      })
                      setFieldValue('geo_location', value[0])
                    }}
                    error={
                      touched.geo_location || triedToSend
                        ? errors.geo_location
                        : undefined
                    }
                    onBlur={handleBlur}
                    small
                  />
                </FormItem>
              </FormRow>
              <FormRow>
                <div className='col-12 col-md-6 col-sm-12 mb-3'>
                  <p className='mt-0'>{t('form.cityRelocation')}</p>
                  <div className='d-flex flex-wrap'>
                    <div className='mr-2 d-flex'>
                      <label className='radio m-0 mr-2'>
                        <input
                          type='radio'
                          name='city_relocation'
                          checked={formData.city_relocation === true}
                          onChange={() => {
                            setFormData({ ...formData, city_relocation: true })
                            setFieldValue('city_relocation', true)
                          }}
                        />
                        <span className='checkmark' />
                      </label>
                      <p className='m-0 d-flex align-items-center'>
                        {t('cityRelocationOptions.yes')}
                      </p>
                    </div>

                    <div className='mr-2 d-flex'>
                      <label className='radio m-0 mr-2'>
                        <input
                          type='radio'
                          name='city_relocation'
                          checked={formData.city_relocation === false}
                          onChange={() => {
                            setFormData({ ...formData, city_relocation: false })
                            setFieldValue('city_relocation', false)
                          }}
                        />
                        <span className='checkmark' />
                      </label>
                      <p className='m-0 d-flex align-items-center'>
                        {t('cityRelocationOptions.no')}
                      </p>
                    </div>
                  </div>
                </div>
                <FormItem formItemClasses='col-sm-6 spaceUp'>
                  <Input
                    type='money'
                    label={t('form.salaryExpectation')}
                    name='salary_expectation'
                    value={values.salary_expectation || ''}
                    onBlur={() => {
                      setFieldTouched('salary_expectation', true)
                    }}
                    onChange={value => {
                      setFormData({ ...formData, salary_expectation: +value })
                      setFieldValue('salary_expectation', value)
                    }}
                    error={
                      touched.salary_expectation || triedToSend
                        ? errors.salary_expectation
                        : undefined
                    }
                    small
                  />
                </FormItem>
              </FormRow>
              <FormRow>
                <FormItem formItemClasses='col-sm-6'>
                  <Input
                    type='text'
                    label={t('form.linkedinProfile')}
                    name='linkedin_profile'
                    value={values.linkedin_profile}
                    onChange={value => {
                      setFormData({ ...formData, linkedin_profile: value })
                      setFieldValue('linkedin_profile', value)
                    }}
                    onBlur={handleBlur}
                    error={
                      touched.linkedin_profile
                        ? errors.linkedin_profile
                        : undefined
                    }
                    small
                  ></Input>
                </FormItem>

                <FormItem formItemClasses='col-sm-6 spaceUp'>
                  <Input
                    type='text'
                    label={t('form.facebookProfile')}
                    name='facebook_profile'
                    value={values.facebook_profile}
                    onChange={value => {
                      setFormData({ ...formData, facebook_profile: value })
                      setFieldValue('facebook_profile', value)
                    }}
                    onBlur={handleBlur}
                    error={
                      touched.facebook_profile
                        ? errors.facebook_profile
                        : undefined
                    }
                    small
                  ></Input>
                </FormItem>
              </FormRow>
              <FormRow>
                <FormItem formItemClasses='col-sm-6 spaceUp'>
                  <Input
                    type='text'
                    label={t('form.twitterProfile')}
                    name='twitter_profile'
                    value={values.twitter_profile}
                    onChange={value => {
                      setFormData({ ...formData, twitter_profile: value })
                      setFieldValue('twitter_profile', value)
                    }}
                    onBlur={handleBlur}
                    error={
                      touched.twitter_profile
                        ? errors.twitter_profile
                        : undefined
                    }
                    small
                  ></Input>
                </FormItem>
              </FormRow>
              <FormRow>
                <FormItem formItemClasses='col-12'>
                  <TextArea
                    label={t('form.about')}
                    required={true}
                    name='about'
                    value={values.about}
                    onChange={value => {
                      setFormData({ ...formData, about: value })
                      setFieldValue('about', value)
                    }}
                    onBlur={handleBlur}
                    error={touched.about ? errors.about : undefined}
                    small
                  />
                </FormItem>
              </FormRow>
            </WidgetBox>
            <WidgetBox className='mt-3'>
              <div className='row'>
                <div className='col-12'>
                  <p className='title'>{t('titleBox.language')}</p>
                </div>
              </div>
              {values.languages.map((el, index) => {
                return (
                  <div className='row mt-3' key={index}>
                    <div className='col-12 col-md-6'>
                      <Input
                        type='text'
                        label={t('languageBox.language')}
                        name='language'
                        value={values.languages[index].language}
                        onChange={value => {
                          const languages = [...values.languages]
                          languages[index].language = value
                          setFieldValue('languages', languages)
                          setFormData({ ...formData, languages })
                        }}
                        error={handleErrorMessage(
                          'language',
                          {
                            name: 'languages',
                            index
                          },
                          errors,
                          values,
                          touched
                        )}
                        onBlur={() => {
                          setFieldTouched(`languages[${index}].language`, true)
                        }}
                        small
                      />
                    </div>

                    <div className='col-12 col-md-6 d-flex justify-content-between'>
                      <div className='w-100'>
                        <Select
                          label={t('languageBox.level')}
                          name='language-fluency'
                          value={values.languages[index].fluency}
                          onChange={event => {
                            const languages = [...values.languages]
                            languages[index].fluency = event.target.value
                            setFieldValue('languages', languages)
                            setFormData({ ...formData, languages })
                          }}
                          error={handleErrorMessage(
                            'fluency',
                            {
                              name: 'languages',
                              index
                            },
                            errors,
                            values,
                            touched
                          )}
                          onBlur={() => {
                            setFieldTouched(`languages[${index}].fluency`, true)
                          }}
                        >
                          <option value=''></option>
                          {languageFluencyOptions.map((option, index) => {
                            return (
                              <option
                                value={option.value}
                                key={`${index}-${option.value}`}
                              >
                                {option.label}
                              </option>
                            )
                          })}
                        </Select>
                      </div>
                    </div>
                    <div className='row w-100 d-flex justify-content-end'>
                      <div className='col-12 d-flex justify-content-end p-0'>
                        <Button
                          className='action danger mt-3 button-square'
                          type='button'
                          onClick={() => {
                            const languages = [...values.languages]
                            languages.splice(index, 1)
                            setFieldValue('languages', languages)
                          }}
                        >
                          <TrashIcon />
                        </Button>
                        <Button
                          className='mt-3 action ml-2 button-square'
                          style={{
                            backgroundColor: '#000000',
                            color: '#fff',
                            display:
                              index + 1 === values.languages.length
                                ? 'flex'
                                : 'none'
                          }}
                          type='button'
                          onClick={() => {
                            const languages = [...values.languages]
                            languages.push({ language: '', fluency: '' })
                            setFieldValue('languages', languages)
                          }}
                        >
                          <AddIcon />
                        </Button>
                      </div>
                    </div>
                  </div>
                )
              })}
              <div className='row'>
                <div className='col d-flex justify-content-end'>
                  <Button
                    className='mt-3 ml-2 button-square'
                    style={{
                      backgroundColor: '#000000',
                      color: '#fff',
                      display: values.languages.length === 0 ? 'flex' : 'none'
                    }}
                    type='button'
                    onClick={() => {
                      const languages = [...values.languages]
                      languages.push({ language: '', fluency: '' })
                      setFieldValue('languages', languages)
                    }}
                  >
                    <AddIcon />
                  </Button>
                </div>
              </div>
            </WidgetBox>

            <WidgetBox className='mt-3'>
              <div className='row'>
                <div className='col-12'>
                  <p className='title'>{t('titleBox.education')}</p>
                </div>
              </div>
              {values.educational_experiences.map((_, index) => {
                return (
                  <div className='row mt-3' key={index}>
                    <div className='col-12 col-md-6 mt-3'>
                      <Input
                        type='text'
                        label={t('educationBox.course')}
                        required={true}
                        name='educational-course'
                        value={values.educational_experiences[index].course}
                        onChange={value => {
                          const educational_experiences = [
                            ...values.educational_experiences
                          ]
                          educational_experiences[index].course = value
                          setFieldValue(
                            'educational_experiences',
                            educational_experiences
                          )
                          setFormData({ ...formData, educational_experiences })
                        }}
                        error={handleErrorMessage(
                          'course',
                          {
                            name: 'educational_experiences',
                            index
                          },
                          errors,
                          values,
                          touched
                        )}
                        onBlur={() => {
                          setFieldTouched(
                            `educational_experiences[${index}].course`,
                            true
                          )
                        }}
                        small
                      />
                    </div>

                    <div className='col-12 col-md-6 mt-3'>
                      <Input
                        type='text'
                        label={t('educationBox.institution')}
                        name='educational-institution'
                        value={
                          values.educational_experiences[index].institution
                        }
                        onChange={value => {
                          const educational_experiences = [
                            ...values.educational_experiences
                          ]
                          educational_experiences[index].institution = value
                          setFieldValue(
                            'educational_experiences',
                            educational_experiences
                          )
                          setFormData({ ...formData, educational_experiences })
                        }}
                        small
                      />
                    </div>

                    <div className='col-12 col-md-3 mt-3'>
                      <DatePickerInput
                        label={t('educationBox.startDate')}
                        required={true}
                        showMonthYearPicker={true}
                        dateFormatType='month'
                        name='name'
                        value={
                          values.educational_experiences[index].start_date || ''
                        }
                        onChange={value => {
                          setFieldValue(
                            `educational_experiences[${index}].start_date`,
                            value
                          )
                          const educational_experiences = [
                            ...values.educational_experiences
                          ]
                          educational_experiences[index].start_date = value
                          setFormData({ ...formData, educational_experiences })
                        }}
                        error={
                          dateError.startDate ||
                          handleErrorMessage(
                            'start_date',
                            {
                              name: 'educational_experiences',
                              index
                            },
                            errors,
                            values,
                            touched
                          )
                        }
                        onBlur={() => {
                          setFieldTouched(
                            `educational_experiences[${index}].start_date`,
                            true
                          )
                        }}
                        small
                      />
                    </div>

                    <div className='col-12 col-md-3 mt-3'>
                      <DatePickerInput
                        label={t('educationBox.endDate')}
                        showMonthYearPicker={true}
                        dateFormatType='month'
                        required={true}
                        name='name'
                        value={
                          values.educational_experiences[index].end_date || ''
                        }
                        onChange={value => {
                          const dateStr = handleDateStr(value)
                          const educational_experiences = [
                            ...values.educational_experiences
                          ]
                          educational_experiences[index].end_date =
                            dateStr === undefined ? '' : dateStr

                          setFieldValue(
                            `educational_experiences[${index}].end_date`,
                            dateStr
                          )
                          setFormData({ ...formData, educational_experiences })
                        }}
                        error={
                          dateError.endDate ||
                          handleErrorMessage(
                            'end_date',
                            {
                              name: 'educational_experiences',
                              index
                            },
                            errors,
                            values,
                            touched
                          )
                        }
                        onBlur={() => {
                          setFieldTouched(
                            `educational_experiences[${index}].end_date`,
                            true
                          )
                        }}
                        small
                      />
                    </div>

                    <div className='col-12 col-md-6 mt-3'>
                      <Select
                        label={t('educationBox.level')}
                        required={true}
                        name='education-level'
                        value={values.educational_experiences[index].level}
                        onChange={event => {
                          const educational_experiences = [
                            ...values.educational_experiences
                          ]
                          educational_experiences[index].level =
                            event.target.value
                          setFieldValue(
                            'educational_experiences',
                            educational_experiences
                          )
                          setFormData({ ...formData, educational_experiences })
                        }}
                        error={handleErrorMessage(
                          'level',
                          {
                            name: 'educational_experiences',
                            index
                          },
                          errors,
                          values,
                          touched
                        )}
                        onBlur={() => {
                          setFieldTouched(
                            `educational_experiences[${index}].level`,
                            true
                          )
                        }}
                      >
                        <option value=''></option>
                        {educationLevelOptions.map((option, index) => {
                          return (
                            <option
                              value={option.value}
                              key={`${index}-${option.value}`}
                            >
                              {option.label}
                            </option>
                          )
                        })}
                      </Select>
                    </div>

                    <div className='col-12 mt-3'>
                      <TextArea
                        label={t('educationBox.about')}
                        name='educational-about'
                        value={
                          values.educational_experiences[index].description
                        }
                        onChange={value => {
                          const educational_experiences = [
                            ...values.educational_experiences
                          ]
                          educational_experiences[index].description = value
                          setFieldValue(
                            'educational_experiences',
                            educational_experiences
                          )
                          setFormData({ ...formData, educational_experiences })
                        }}
                        small
                      />
                    </div>

                    <div className='col-12 mt-3 d-flex justify-content-end'>
                      <Button
                        className='action danger button-square'
                        type='button'
                        onClick={() => {
                          const educational_experiences = [
                            ...values.educational_experiences
                          ]
                          educational_experiences.splice(index, 1)
                          setFieldValue(
                            'educational_experiences',
                            educational_experiences
                          )
                          setFormData({ ...formData, educational_experiences })
                        }}
                      >
                        <TrashIcon />
                      </Button>

                      <Button
                        className='ml-2 action button-square'
                        style={{
                          backgroundColor: '#000000',
                          color: '#fff',
                          display:
                            index + 1 === values.educational_experiences.length
                              ? 'flex'
                              : 'none'
                        }}
                        type='button'
                        onClick={() => {
                          const educational_experiences = [
                            ...values.educational_experiences
                          ]
                          educational_experiences.push({
                            level: '',
                            course: '',
                            institution: '',
                            description: '',
                            end_date: '',
                            start_date: ''
                          })
                          setFieldValue(
                            'educational_experiences',
                            educational_experiences
                          )
                          setFormData({ ...formData, educational_experiences })
                        }}
                      >
                        <AddIcon />
                      </Button>
                    </div>
                  </div>
                )
              })}

              <div className='row'>
                <div className='col d-flex justify-content-end'>
                  <Button
                    className='ml-2 action button-square'
                    style={{
                      backgroundColor: '#000000',
                      color: '#fff',
                      display:
                        values.educational_experiences.length === 0
                          ? 'flex'
                          : 'none'
                    }}
                    type='button'
                    onClick={() => {
                      const educational_experiences = [
                        ...values.educational_experiences
                      ]
                      educational_experiences.push({
                        level: '',
                        course: '',
                        institution: '',
                        description: '',
                        end_date: '',
                        start_date: ''
                      })
                      setFieldValue(
                        'educational_experiences',
                        educational_experiences
                      )
                      setFormData({ ...formData, educational_experiences })
                    }}
                  >
                    <AddIcon />
                  </Button>
                </div>
              </div>
            </WidgetBox>

            <WidgetBox className='mt-3'>
              <div className='row'>
                <div className='col-12'>
                  <p className='title'>
                    {t('titleBox.certificatesAndAchievements')}
                  </p>
                </div>
              </div>
              {values.certificates.map((el, index) => {
                return (
                  <div className='row mt-3' key={index}>
                    <div className='col-12 col-md-6'>
                      <Input
                        type='text'
                        label={t('certificateBox.name')}
                        required={true}
                        name='certificates-name'
                        value={values.certificates[index].name}
                        onChange={value => {
                          const certificates = [...values.certificates]
                          certificates[index].name = value
                          setFieldValue('certificates', certificates)
                          setFormData({ ...formData, certificates })
                        }}
                        error={handleErrorMessage(
                          'name',
                          {
                            name: 'certificates',
                            index
                          },
                          errors,
                          values,
                          touched
                        )}
                        onBlur={() => {
                          setFieldTouched(`certificates[${index}].name`, true)
                        }}
                        small
                      />
                    </div>

                    <div className='col-12 col-md-6 d-flex justify-content-between'>
                      <div className='w-100'>
                        <Input
                          type='number'
                          label={t('certificateBox.hours')}
                          name='working_hours'
                          required={true}
                          value={values.certificates[index].working_hours || ''}
                          onChange={value => {
                            const certificates = [...values.certificates]
                            certificates[index].working_hours = Number(value)
                            setFieldValue('certificates', certificates)
                            setFormData({ ...formData, certificates })
                          }}
                          error={handleErrorMessage(
                            'working_hours',
                            {
                              name: 'certificates',
                              index
                            },
                            errors,
                            values,
                            touched
                          )}
                          onBlur={() => {
                            setFieldTouched(
                              `certificates[${index}].working_hours`,
                              true
                            )
                          }}
                          small
                        />
                      </div>
                    </div>
                    <div className='row w-100 d-flex justify-content-end'>
                      <div className='col-12 d-flex justify-content-end p-0'>
                        <Button
                          className='mt-3 action danger ml-2 button-square'
                          type='button'
                          onClick={() => {
                            const certificates = [...values.certificates]
                            certificates.splice(index, 1)
                            setFieldValue('certificates', certificates)
                            setFormData({ ...formData, certificates })
                          }}
                        >
                          <TrashIcon />
                        </Button>
                        <Button
                          className='mt-3 action ml-2 button-square'
                          style={{
                            backgroundColor: '#000000',
                            color: '#fff',
                            display:
                              index + 1 === values.certificates.length
                                ? 'flex'
                                : 'none'
                          }}
                          type='button'
                          onClick={() => {
                            const certificates = [...values.certificates]
                            certificates.push({ name: '', working_hours: 0 })
                            setFieldValue('certificates', certificates)
                            setFormData({ ...formData, certificates })
                          }}
                        >
                          <AddIcon />
                        </Button>
                      </div>
                    </div>
                  </div>
                )
              })}

              <div className='row'>
                <div className='col d-flex justify-content-end'>
                  <Button
                    className='mt-3 action ml-2 button-square'
                    style={{
                      backgroundColor: '#000000',
                      color: '#fff',
                      display:
                        values.certificates.length === 0 ? 'flex' : 'none'
                    }}
                    type='button'
                    onClick={() => {
                      const certificates = [...values.certificates]
                      certificates.push({ name: '', working_hours: 0 })
                      setFieldValue('certificates', certificates)
                    }}
                  >
                    <AddIcon />
                  </Button>
                </div>
              </div>
            </WidgetBox>

            <WidgetBox className='mt-3'>
              <div className='row'>
                <div className='col-12'>
                  <p className='title'>{t('titleBox.experience')}</p>
                </div>
              </div>
              {values.professional_experiences.map(
                (professionalExperience, index) => {
                  return (
                    <div className='row mt-3' key={index}>
                      <div className='col-12 col-md-6 mt-3'>
                        <Input
                          type='text'
                          label={t('experienceBox.role')}
                          required={true}
                          name='experience-role'
                          value={values.professional_experiences[index].role}
                          onChange={value => {
                            const professional_experiences = [
                              ...values.professional_experiences
                            ]
                            professional_experiences[index].role = value
                            setFieldValue(
                              'professional_experiences',
                              professional_experiences
                            )
                            setFormData({
                              ...formData,
                              professional_experiences
                            })
                          }}
                          error={handleErrorMessage(
                            'role',
                            {
                              name: 'professional_experiences',
                              index
                            },
                            errors,
                            values,
                            touched
                          )}
                          onBlur={() => {
                            setFieldTouched(
                              `professional_experiences[${index}].role`,
                              true
                            )
                          }}
                          small
                        />
                      </div>

                      <div className='col-12 col-md-6 mt-3'>
                        <Input
                          type='text'
                          label={t('experienceBox.company')}
                          name='experience-company'
                          value={values.professional_experiences[index].company}
                          onChange={value => {
                            const professional_experiences = [
                              ...values.professional_experiences
                            ]
                            professional_experiences[index].company = value
                            setFieldValue(
                              'professional_experiences',
                              professional_experiences
                            )
                            setFormData({
                              ...formData,
                              professional_experiences
                            })
                          }}
                          error={handleErrorMessage(
                            'company',
                            {
                              name: 'professional_experiences',
                              index
                            },
                            errors,
                            values,
                            touched
                          )}
                          onBlur={() => {
                            setFieldTouched(
                              `professional_experiences[${index}].company`,
                              true
                            )
                          }}
                          small
                        />
                      </div>
                      <div className='col-12 col-md-3 mt-3'>
                        <DatePickerInput
                          label={t('experienceBox.startDate')}
                          required={true}
                          showMonthYearPicker={true}
                          dateFormatType='month'
                          name='start_date'
                          value={
                            values.professional_experiences[index].start_date ||
                            ''
                          }
                          onChange={value => {
                            const dateStr = handleDateStr(value)
                            const professional_experiences = [
                              ...values.professional_experiences
                            ]
                            professional_experiences[index].start_date =
                              dateStr === undefined ? '' : dateStr

                            setFieldValue(
                              `professional_experiences[${index}].start_date`,
                              dateStr
                            )
                            setFormData({
                              ...formData,
                              professional_experiences
                            })
                          }}
                          error={
                            dateError.startDate ||
                            handleErrorMessage(
                              'start_date',
                              {
                                name: 'professional_experiences',
                                index
                              },
                              errors,
                              values,
                              touched
                            )
                          }
                          onBlur={() => {
                            setFieldTouched(
                              `professional_experiences[${index}].start_date`,
                              true
                            )
                          }}
                          small
                        />
                      </div>
                      <div className='col-12 col-md-3 mt-3'>
                        {!professionalExperience.current_job ? (
                          <DatePickerInput
                            label={t('experienceBox.endDate')}
                            required={!professionalExperience.current_job}
                            showMonthYearPicker={true}
                            dateFormatType='month'
                            name='name'
                            value={
                              values.professional_experiences[index].end_date ||
                              ''
                            }
                            onChange={value => {
                              const dateStr = handleDateStr(value)
                              const professional_experiences = [
                                ...values.professional_experiences
                              ]
                              professional_experiences[index].end_date =
                                dateStr === undefined ? '' : dateStr

                              setFieldValue(
                                `professional_experiences[${index}].end_date`,
                                dateStr
                              )
                              setFormData({
                                ...formData,
                                professional_experiences
                              })
                            }}
                            error={handleErrorMessage(
                              'end_date',
                              {
                                name: 'professional_experiences',
                                index
                              },
                              errors,
                              values,
                              touched
                            )}
                            onBlur={() => {
                              setFieldTouched(
                                `professional_experiences[${index}].end_date`,
                                true
                              )
                            }}
                            small
                          />
                        ) : (
                          ''
                        )}
                      </div>

                      <div className='col-12 col-md-6 mt-3'>
                        <Checkbox
                          label={t('experienceBox.currentJob')}
                          name='experience-currentJob'
                          checked={professionalExperience.current_job}
                          onChange={value => {
                            const professional_experiences = [
                              ...values.professional_experiences
                            ]
                            professional_experiences[index].current_job = value
                            setFieldValue(
                              'professional_experiences',
                              professional_experiences
                            )
                            setFormData({
                              ...formData,
                              professional_experiences
                            })
                          }}
                        />
                      </div>

                      <div className='col-12 mt-3'>
                        <TextArea
                          label={t('experienceBox.description')}
                          name='experience-description'
                          value={
                            values.professional_experiences[index].description
                          }
                          onChange={value => {
                            const professional_experiences = [
                              ...values.professional_experiences
                            ]
                            professional_experiences[index].description = value
                            setFieldValue(
                              'professional_experiences',
                              professional_experiences
                            )
                            setFormData({
                              ...formData,
                              professional_experiences
                            })
                          }}
                          small
                        />
                      </div>
                      <div className='col-12 mt-3 d-flex justify-content-end'>
                        <Button
                          className='action danger button-square'
                          type='button'
                          onClick={() => {
                            const professional_experiences = [
                              ...values.professional_experiences
                            ]
                            professional_experiences.splice(index, 1)
                            setFieldValue(
                              'professional_experiences',
                              professional_experiences
                            )
                            setFormData({
                              ...formData,
                              professional_experiences
                            })
                          }}
                        >
                          <TrashIcon />
                        </Button>

                        <Button
                          className='ml-2 action button-square'
                          style={{
                            backgroundColor: '#000000',
                            color: '#fff',
                            display:
                              index + 1 ===
                              values.professional_experiences.length
                                ? 'flex'
                                : 'none'
                          }}
                          type='button'
                          onClick={() => {
                            const professional_experiences = [
                              ...values.professional_experiences
                            ]
                            professional_experiences.push({
                              role: '',
                              company: '',
                              start_date: '',
                              end_date: '',
                              current_job: false,
                              description: ''
                            })
                            setFieldValue(
                              'professional_experiences',
                              professional_experiences
                            )
                            setFormData({
                              ...formData,
                              professional_experiences
                            })
                          }}
                        >
                          <AddIcon />
                        </Button>
                      </div>
                    </div>
                  )
                }
              )}

              <div className='row'>
                <div className='col d-flex justify-content-end'>
                  <Button
                    className='mt-3 action ml-2 button-square'
                    style={{
                      backgroundColor: '#000000',
                      color: '#fff',
                      display:
                        values.professional_experiences.length === 0
                          ? 'flex'
                          : 'none'
                    }}
                    type='button'
                    onClick={() => {
                      const professional_experiences = [
                        ...values.professional_experiences
                      ]
                      professional_experiences.push({
                        role: '',
                        company: '',
                        start_date: '',
                        end_date: '',
                        current_job: true,
                        description: ''
                      })
                      setFieldValue(
                        'professional_experiences',
                        professional_experiences
                      )
                    }}
                  >
                    <AddIcon />
                  </Button>
                </div>
              </div>
            </WidgetBox>

            <WidgetBox className='mt-3'>
              <div className='row'>
                <div className='col-12'>
                  <p className='title'>{t('titleBox.otherInformation')}</p>
                </div>
              </div>

              <div className='row'>
                <div className='col-12'>
                  <TextArea
                    label={t('otherInformation')}
                    name='otherInformation'
                    value={values.other_info}
                    onChange={value => {
                      setFormData({
                        ...formData,
                        other_info: value
                      })
                      setFieldValue('other_info', value)
                    }}
                    onBlur={handleBlur}
                    error={touched.other_info ? errors.other_info : undefined}
                    small
                  />
                </div>
              </div>
            </WidgetBox>

            <WidgetBox className='mt-3'>
              <div className='row'>
                <div className='col-12'>
                  <p className='title'>{t('titleBox.attachments')}</p>
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <div className={styles.attachmentContainer}>
                    <div className={styles.attachmentSubtitleArea}>
                      <div className={styles.attachmentSubtitleItems}>
                        <h2>
                          {t('attachmentSubTitleDescription')}
                          {attachmentFilesError ? (
                            <p>
                              <span className={styles.attachmentFilesError}>
                                {t('attachmentError')}
                              </span>
                            </p>
                          ) : (
                            ''
                          )}
                        </h2>
                        <div className={styles.attachmentButton}>
                          <label
                            style={{ cursor: 'pointer' }}
                            htmlFor='attachments-upload'
                          >
                            <Button
                              style={{
                                backgroundColor: '#FFFFFF',
                                color: '#000000',
                                pointerEvents: 'none'
                              }}
                            >
                              {t('button.addAttachments')}
                            </Button>
                          </label>
                          <input
                            style={{ display: 'none' }}
                            id='attachments-upload'
                            type='file'
                            onChange={file => {
                              if (file) {
                                getAttachment(file)
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div className={styles.attachmentSubtitle}>
                        <h2>{t('attachmentSubTitleFileDescription')}</h2>
                        <div className={styles.attachmentUpload}>
                          {attachmentFiles.length ? (
                            <div>
                              {attachmentFiles.map((el, index) => {
                                return (
                                  <div key={index}>
                                    <p>
                                      <u style={{ color: '#0000FF' }}>
                                        {el.name}
                                      </u>
                                      <span
                                        style={{
                                          marginLeft: '10px',
                                          fontSize: '#fff',
                                          cursor: 'pointer'
                                        }}
                                        onClick={() => delAttachment(index)}
                                      >
                                        x
                                      </span>
                                    </p>
                                  </div>
                                )
                              })}
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </WidgetBox>

            <Button
              style={{
                backgroundColor: '#000000'
              }}
              className='mt-3 mb-3 secondary'
              disabled={isLoading || !isValid}
              type='submit'
            >
              {t('form.save')}
            </Button>
          </Form>
        )}
      </Formik>
    </>
  )
}
